import { create, defaults, get as axiosGet } from 'axios';
import { saveAs } from 'file-saver';
import {
  transformRequest,
  successInterceptor,
  errorInterceptor,
  buildQueryParams,
} from './utils';


const API = create({
  baseURL: '/api',
  timeout: 20000,
  transformRequest: [transformRequest, ...defaults.transformRequest],
});

API.interceptors.response.use(successInterceptor, errorInterceptor);

export const getUser = () => API.get('/auth');

export const postLogin = ({ login, password }) => API.post('/auth/login', { login, password });

export const getRoleList = () => API.get('/roles');

export const getUserList = (params) => API.get('/users', { params });

export const postUser = ({
  login,
  firstname,
  secondname,
  lastname,
  role,
}) => API.post('/users', {
  login,
  firstname,
  secondname,
  lastname,
  role,
});

export const putUser = (id, {
  login,
  firstname,
  secondname,
  lastname,
  role,
}) => API.put(`/users/${id}`, {
  login,
  firstname,
  secondname,
  lastname,
  role,
});

export const deactivateUser = (id) => API.post(`/users/${id}/deactivate`, {});

export const activateUser = (id) => API.post(`/users/${id}/activate`, {});

export const postLogout = () => API.post('/auth/logout');

export const getPlanogramTmplList = async (params) => API.get('planogram/templates', { params });

export const getPlanogramTmplTypeList = () => API.get('planogram/templates/types');

export const getPlanogramTmpl = (id) => API.get(`planogram/templates/${id}`);

export const postPlanogramTmpl = (data) => API.post('planogram/templates', data);

export const putPlanogramTmpl = (id, data) => API.put(`planogram/templates/${id}`, data);

export const delPlanogramTmpl = (id) => API.delete(`planogram/templates/${id}`);

export const copyPlanogramTmpl = (id) => API.post(`planogram/templates/${id}/copy`);

export const postTmplWsRef = (tmplId, wsId) => API.post(`planogram/templates/${tmplId}/warehouses`, { filter: { wsId } });

export const postTmplWsRefList = (tmplId, wsIds) => API.post(`planogram/templates/${tmplId}/warehouses`, { filter: { wsIdIn: wsIds } });

export const delTmplWsRef = (refId) => API.post('planogram/templates/warehouses/delete', { filter: { refId } });

export const delTmplWsRefList = (refIds) => API.post('planogram/templates/warehouses/delete', { filter: { refIdIn: refIds } });

export const activateTmplWsRef = (refId) => API.post('planogram/templates/warehouses/activate', { filter: { refId } });

export const activateTmplWsRefList = (refIds) => API.post('planogram/templates/warehouses/activate', { filter: { refIdIn: refIds } });

export const deactivateTmplWsRef = (refId) => API.post('planogram/templates/warehouses/deactivate', { filter: { refId } });

export const deactivateTmplWsRefList = (refIds) => API.post('planogram/templates/warehouses/deactivate', { filter: { refIdIn: refIds } });

export const getEquipmentList = (params) => API.get('equipments', { params });

export const getEquipment = (id) => API.get(`equipments/${id}`);

export const postEquipment = ({
  name,
  width,
  height,
  children,
}) => API.post('equipments', {
  name,
  width,
  height,
  children,
});

export const putEquipment = (id, {
  name,
  width,
  height,
  children,
}) => API.put(`equipments/${id}`, {
  name,
  width,
  height,
  children,
});

export const delEquipment = (id) => API.delete(`equipments/${id}`);

export const getLayoutTreeChildren = (id) => API.get(`layout-tree/${id}/children`);

export const getLayoutTree = (params) => API.get('layout-tree', { params });

export const putLayoutTree = (id, {
  name,
  parentId,
  color,
  calcOptions,
}) => API.put(`layout-tree/${id}`, {
  name,
  parentId,
  color,
  calcOptions,
});

export const prioritizeLayoutTree = (parentId, groupIds) => API.post('layout-tree/prioritize', {
  parentId,
  groupIds,
});

export const getFilialList = () => API.get('/filials');

export const getWarehouseList = (params) => API.get('warehouses', { params });

export const getWarehouse = (id) => API.get(`warehouses/${id}`);

export const tmplHasActiveWs = async (tmplId) => {
  const filters = {
    templateIdEq: tmplId,
    isActiveEq: true,
  };
  const pagination = {
    itemsPerPage: 1,
    currentPage: 1,
  };
  const params = buildQueryParams({ filters, pagination });
  const response = await API.get('planogram/templates/warehouses', { params });
  return !!response.rowcount;
};

export const getTemplatesWarehouseList = (params) => API.get('planogram/templates/warehouses', { params });

export const getPlanogramPreview = (tmplWsId) => axiosGet(`/api/planograms/${tmplWsId}/html/preview?t=${Date.now()}`);

export const getPlanogramActual = (wsId) => axiosGet(`/api/planograms/warehouses/${wsId}/html/actual?t=${Date.now()}`);

export const postCalcPlanogram = (wsId) => API.post(`planograms/warehouses/${wsId}/actual`, {});

export const downloadPlanogramXlsxPreview = async (tmplWsId) => {
  const { data, headers } = await axiosGet(`/api/planograms/${tmplWsId}/xlsx/preview`, {
    responseType: 'blob',
    headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : `planogram_${tmplWsId}.xlsx`
  );
  await saveAs(data, fileName);
};

export const downloadPlanogramXlsxActual = async (wsId) => {
  const { data, headers } = await axiosGet(`/api/planograms/warehouses/${wsId}/xlsx/actual`, {
    responseType: 'blob',
    headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : `planogram_${wsId}.xlsx`
  );
  await saveAs(data, fileName);
};

export const uploadWarehousesCodesFile = async (file) => {
  const data = new FormData();
  data.append('file', file);
  const response = await API.post('warehouses/parse/codesfile', data, { timeout: 20000 });
  return response;
};
