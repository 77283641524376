import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NonIdealState } from '@blueprintjs/core';

import getContextMenu from './ContextMenu';
import ActionBar from './ActionBar';
import Filters from './Filters';
import { getWarehouseList } from '../../api';
import { useTableRequest } from '../../api/useTableRequest';
import Page from '../../widgets/Page';
import SearchInput from '../../widgets/Filters/SearchInput';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';

const BREADCRUMB_ITEMS = [
  { text: 'Магазины' },
];

const FIELDS = [
  {
    title: 'Название',
    field: 'name',
    sortable: true,
    width: 150,
    checked: true,
  },
  {
    title: 'Код',
    field: 'code',
    sortable: true,
    width: 100,
    checked: true,
  },
  {
    title: 'Адрес',
    field: 'address',
    sortable: true,
    width: 290,
    checked: true,
  },
  {
    title: 'Филиал',
    field: 'filialName',
    sortable: true,
    width: 150,
    checked: true,
  },
  {
    title: 'Статус',
    field: 'status',
    sortable: true,
    width: 150,
    checked: true,
  },
  {
    title: 'Активный шаблон',
    field: 'activeTmplName',
    sortable: false,
    width: 150,
    checked: true,
  },
];

const toggleField = (fields, setFields) => (code, checked) => {
  if (code === 'ALL') {
    const newFields = fields.map((x) => ({ ...x, checked }));
    setFields(newFields);
    return newFields;
  }
  const newFields = fields.map((x) => {
    if (x.field === code) {
      return { ...x, checked };
    }
    return x;
  });
  setFields(newFields);
  return newFields;
};

const WarehouseListScreen = () => {
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination, setSort] = useTableRequest(getWarehouseList);
  useEffect(() => { fetch({ filters, pagination, sort }); }, [fetch, filters, pagination, sort]);
  const [filtersVisible, setFilterVisible] = useState(false);
  const history = useHistory();
  const hasFilters = Object.entries(filters).filter(([key, value]) => key !== 'search' && value).length > 0;
  const [fields, setFields] = useState(FIELDS);

  return (
    <Page>
      <BreadcrumbNav items={BREADCRUMB_ITEMS} />
      <SearchInput
        name="Поиск..."
        value={filters.search}
        onChange={(search) => setFilters({ ...filters, search })}
      />
      <ActionBar
        fields={fields}
        toggleField={toggleField(fields, setFields)}
        hasFilters={hasFilters}
        filtersVisible={filtersVisible}
        setFilterVisible={setFilterVisible}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
      />
      <Filters
        filters={filters}
        filtersVisible={filtersVisible}
        setFilters={setFilters}
        clearFilters={() => setFilters({ search: filters.search })}
      />
      {
        error
          ? <NonIdealState title="Ошибка" description={error.message} icon="error" />
          : (
            <DataTable
              columns={getColumns(fields.filter((x) => x.checked), sort, setSort)}
              data={data}
              loading={fetching}
              loadingRowCount={pagination.itemsPerPage}
              contextMenu={getContextMenu({ history })}
              currentPage={pagination.currentPage}
              itemsPerPage={pagination.itemsPerPage}
              onDoubleClick={({ item }) => history.push(`/warehouses/${item.id}`)}
            />
          )
      }
    </Page>
  );
};

export default WarehouseListScreen;
