import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Elevation,
  Intent,
  Collapse,
} from '@blueprintjs/core';

import STATUSES from '../../config/statuses';
import { getFilialList } from '../../api';
import { useRequest } from '../../api/useRequest';
import { getRespItems } from '../../widgets/SimpleSelect';
import ComboFilter from '../../widgets/Filters/ComboFilter';
import InputFilter from '../../widgets/Filters/InputFilter';
import styles from './Filters.module.scss';

const statusItems = STATUSES.map((x) => ({ title: x, key: x, value: x }));

const Filters = ({
  filters,
  filtersVisible,
  setFilters,
  clearFilters,
}) => {
  const [{ data: filialsResp }, fetchFilials] = useRequest(getFilialList);
  useEffect(() => { fetchFilials(); }, [fetchFilials]);
  const filials = getRespItems(filialsResp);
  return (
    <div className={styles.filtersGroup}>
      <Collapse isOpen={filtersVisible}>
        <Card elevation={Elevation.ONE} className={styles.filtersPanel}>
          <InputFilter
            name="Название"
            value={filters.nameLike}
            onChange={(val) => setFilters({
              ...filters,
              nameLike: val,
            })}
          />
          <InputFilter
            name="Код"
            value={filters.codeLike}
            onChange={(val) => setFilters({
              ...filters,
              codeLike: val,
            })}
          />
          <InputFilter
            name="Адрес"
            value={filters.addressLike}
            onChange={(val) => setFilters({
              ...filters,
              addressLike: val,
            })}
          />
          <Button
            onClick={clearFilters}
            minimal
            icon="filter-remove"
            text="Очистить"
            intent={Intent.PRIMARY}
            className={styles.clearFiltersButton}
          />
          <div className={styles.clearfix} />
          <ComboFilter
            filterable
            name="Филиал"
            value={filters.filialIdEq}
            onChange={(id) => setFilters({
              ...filters,
              filialIdEq: id,
            })}
            items={filials}
          />
          <ComboFilter
            filterable
            name="Статус"
            value={filters.statusEq}
            onChange={(status) => setFilters({
              ...filters,
              statusEq: status,
            })}
            items={statusItems}
          />
          <div className={styles.clearfix} />
        </Card>
      </Collapse>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    addressLike: PropTypes.string,
    codeLike: PropTypes.string,
    nameLike: PropTypes.string,
    filialIdEq: PropTypes.number,
    statusEq: PropTypes.string,
  }).isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

export default Filters;
