import React, { useEffect, useCallback } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';
import { selectCanEditTmpl } from '../../state/reducers/auth';
import { getEquipmentList } from '../../api';
import { useTableRequest } from '../../api/useTableRequest';
import ActionBar from './ActionBar';
import getContextMenu from './ContextMenu';

const BREADCRUMB_ITEMS = [
  { text: 'Оборудование' },
];

const FIELDS = [
  {
    title: 'Название',
    field: 'name',
    sortable: true,
    width: 390,
  },
  { title: 'Ширина', field: 'width', sortable: true },
  { title: 'Высота', field: 'height', sortable: true },
  {
    title: 'Привязано шаблонов',
    field: 'templatesCount',
    sortable: true,
    width: 200,
  },
  { title: 'Количество полок', path: ['children', 'length'], width: 160 },
];

const EquipmentListScreen = () => {
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, , setPagination, setSort] = useTableRequest(getEquipmentList, {
    initialState: {
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  });
  useEffect(() => { fetch({ filters, pagination, sort }); }, [fetch, filters, pagination, sort]);
  const history = useHistory();
  const canEdit = useSelector(selectCanEditTmpl);
  const contextMenu = useCallback(getContextMenu({ history, canEdit }), [history, canEdit]);
  const columns = useCallback(getColumns(FIELDS, sort, setSort), [FIELDS, sort, setSort]);
  const onDoubleClick = useCallback(({ item }) => history.push(`/equipments/${item.id}`), [history]);
  return (
    <Page>
      <BreadcrumbNav items={BREADCRUMB_ITEMS} />
      <ActionBar
        canEdit={canEdit}
        history={history}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
      />
      {
        error
          ? <NonIdealState title="Ошибка" description={error.message} icon="error" />
          : (
            <DataTable
              columns={columns}
              data={data}
              loading={fetching}
              loadingRowCount={pagination.itemsPerPage}
              contextMenu={contextMenu}
              onDoubleClick={onDoubleClick}
              currentPage={pagination.currentPage}
              itemsPerPage={pagination.itemsPerPage}
            />
          )
      }
    </Page>
  );
};

export default EquipmentListScreen;
