import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Icon,
  Card,
  Elevation,
  Intent,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { getEquipmentList } from '../../../api';
import { useTableRequest } from '../../../api/useTableRequest';
import SearchInput from '../../../widgets/Filters/SearchInput';
import PageSelect from '../../../widgets/PageSelect';
import styles from './EquipmentListCard.module.scss';
import ItemTypes from './ItemTypes';


const EquipmentRow = ({ equipment }) => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.EQUIP_ROW, equipment: { ...equipment, title: equipment.name } },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const className = (
    isDragging
      ? classNames(styles.equpmentItem, styles.dragging)
      : styles.equpmentItem
  );
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return (
    <div ref={drag} className={className}>
      <div className={styles.itemWrapper}>
        <Icon className={styles.icon} intent={Intent.PRIMARY} icon="cube" />
        {equipment.name}
      </div>
    </div>
  );
};

EquipmentRow.propTypes = {
  equipment: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};


const EquipmentListCard = () => {
  const [{
    fetching,
    data: equipmentList,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination] = useTableRequest(getEquipmentList, {
    initialState: {
      pagination: {
        itemsPerPage: 20,
        currentPage: 1,
      },
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  });
  useEffect(() => { fetch({ filters, pagination, sort }); }, [fetch, filters, pagination, sort]);

  return (
    <Card elevation={Elevation.TWO} className={styles.equipmentCard}>
      <div className={styles.searchWrapper}>
        <SearchInput
          name="Поиск..."
          value={filters.nameLike}
          onChange={(nameLike) => setFilters({ ...filters, nameLike })}
        />
      </div>
      <PageSelect
        total={totals.items}
        currentPage={pagination.currentPage}
        maxPage={totals.pages || pagination.currentPage}
        currentItemsPerPage={pagination.itemsPerPage}
        onPageSelect={
          (currentPage) => { setPagination({ ...pagination, currentPage }); }
        }
        onChangeItemsPerPage={
          (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
        }
      />
      <div className={styles.equpmentList}>
        { error ? <NonIdealState title="Ошибка" description={error.message} icon="error" /> : null }
        { !error && fetching ? <Spinner /> : null }
        { !error && !fetching ? (
          equipmentList.map((e) => <EquipmentRow key={e.id} equipment={e} />)
        ) : null }
        { !error && !fetching && !equipmentList.length ? (
          <div className={styles.notFound}>Оборудование не найдено</div>
        ) : null }
      </div>
    </Card>
  );
};

export default EquipmentListCard;
