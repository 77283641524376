import {
  Intent,
  Toaster,
  Position,
} from '@blueprintjs/core';

const toaster = Toaster.create({ position: Position.BOTTOM });

export const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

export const onActivateSuccess = (onSaved) => () => {
  const message = 'Пользователь разблокирован';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
  if (onSaved) {
    onSaved();
  }
};

export const onDeactivateSuccess = (onSaved) => () => {
  const message = 'Пользователь заблокирован';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
  if (onSaved) {
    onSaved();
  }
};
