import React from 'react';
import PropTypes from 'prop-types';
import LayoutCard from './LayoutCard';
import TreeCard from './TreeCard';
import styles from './index.module.scss';


const LayoutStep = ({ template, onSave, onFinish }) => (
  <div className={styles.container}>
    <LayoutCard template={template} onSave={onSave} onFinish={onFinish} />
    <TreeCard />
  </div>
);

LayoutStep.propTypes = {
  template: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default LayoutStep;
