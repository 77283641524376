import React, { useEffect, useState } from 'react';
import {
  NonIdealState,
  Checkbox,
  Toaster,
  Position,
  Intent,
} from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { getWarehouseList, getPlanogramTmpl, postTmplWsRefList } from '../../api';
import { useTableRequest } from '../../api/useTableRequest';
import { useRequest } from '../../api/useRequest';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import Page from '../../widgets/Page';
import SearchInput from '../../widgets/Filters/SearchInput';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';
import ActionBar from './ActionBar';
import AddRefsCard from './AddRefsCard';
import Filters from './Filters';
import styles from './index.module.scss';

const getBreadcrumbItems = (id) => [
  { text: 'Шаблоны планограмм', href: '/templates' },
  { text: 'Просмотр шаблона планограммы', href: `/templates/${id}` },
  { text: 'Привязка магазинов' },
];

const checkboxRenderer = (id, data, selected, setSelected) => {
  const selectedItem = _.find(selected, ['id', id]);
  const checked = !!selectedItem;
  return (
    <Checkbox
      checked={checked}
      onChange={() => {
        if (checked) {
          setSelected(_.without(selected, selectedItem));
        } else {
          setSelected([...selected, data]);
        }
      }}
    />
  );
};

const getFields = (
  fetching,
  selected,
  setSelected,
  pageIntermidiate,
  pageChecked,
  onTogglePageSelection,
) => [
  {
    title: fetching ? '' : (
      <Checkbox
        className={classNames(styles.selectAll, 'bp3-table-reorder-handle-target')}
        checked={pageChecked}
        indeterminate={pageIntermidiate}
        onChange={() => onTogglePageSelection(pageChecked, pageIntermidiate)}
      />
    ),
    field: 'id',
    width: 40,
    sortable: false,
    valueRenderer: (id, data) => checkboxRenderer(id, data, selected, setSelected),
  },
  {
    title: 'Название',
    field: 'name',
    sortable: true,
    width: 350,
  },
  {
    title: 'Код',
    field: 'code',
    sortable: true,
    width: 150,
  },
  {
    title: 'Адрес',
    field: 'address',
    sortable: true,
    width: 450,
  },
  {
    title: 'Филиал',
    field: 'filialName',
    sortable: true,
    width: 180,
  },
  {
    title: 'Статус',
    field: 'status',
    sortable: true,
    width: 180,
  },
];

const toaster = Toaster.create({ position: Position.BOTTOM });

const onSaveSuccess = (history, id) => () => {
  history.push(`/templates/${id}`);
  const message = 'Магазины привязаны к шаблону';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
};

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const AddTmplWsRefScreen = () => {
  const { id } = useParams();
  const history = useHistory();
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination, setSort] = useTableRequest(getWarehouseList);
  const [{
    fetching: tmplFetching,
    data: template,
    error: tmplError,
  }, fetchTemplate] = useRequest(getPlanogramTmpl);
  const [{ fetching: saving }, saveRefs] = useRequest(postTmplWsRefList, {
    onSuccess: onSaveSuccess(history, id),
    onError,
  });
  useEffect(() => { fetch({ filters, pagination, sort }); }, [fetch, filters, pagination, sort]);
  useEffect(() => {
    if (id) {
      fetchTemplate(id);
    }
  }, [fetchTemplate, id]);
  const [selected, setSelected] = useState([]);
  const [filtersVisible, setFilterVisible] = useState(false);
  const hasFilters = Object.entries(filters).filter(([key, value]) => key !== 'search' && value).length > 0;
  const selectedIds = selected.map((x) => x.id);
  const pageSelected = data ? data.filter((x) => selectedIds.indexOf(x.id) !== -1) : [];
  const pageIntermidiate = data && pageSelected.length && pageSelected.length < data.length;
  const pageChecked = data && pageSelected.length && pageSelected.length === data.length;
  const onTogglePageSelection = (checked, intermidiate) => {
    if (!checked && !intermidiate) {
      setSelected([...selected, ...data]);
    } else if (checked) {
      const filtered = selected.filter((x) => _.findIndex(data, ['id', x.id]) === -1);
      setSelected(filtered);
    } else if (intermidiate) {
      const toAdd = data.filter((x) => _.findIndex(selected, ['id', x.id]) === -1);
      setSelected([...selected, ...toAdd]);
    }
  };

  return (
    <Page wide>
      <BreadcrumbNav items={getBreadcrumbItems(id)} />
      <AddRefsCard
        templateName={template && template.name}
        fetching={tmplFetching || saving}
        selected={selected}
        setSelected={setSelected}
        onSaveRefs={() => {
          const tmplId = Number(id);
          saveRefs(tmplId, selectedIds);
        }}
      />
      <SearchInput
        name="Поиск..."
        value={filters.search}
        onChange={(search) => setFilters({ ...filters, search })}
      />
      <ActionBar
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
        setFilterVisible={setFilterVisible}
        filtersVisible={filtersVisible}
        hasFilters={hasFilters}
      />
      <Filters
        filters={filters}
        filtersVisible={filtersVisible}
        setFilters={setFilters}
        clearFilters={() => setFilters({ search: filters.search })}
      />
      {
        (error || tmplError)
          ? <NonIdealState title="Ошибка" description={(error || tmplError).message} icon="error" />
          : (
            <DataTable
              columns={getColumns(getFields(
                fetching,
                selected,
                setSelected,
                pageIntermidiate,
                pageChecked,
                onTogglePageSelection,
              ), sort, setSort)}
              data={data}
              loading={fetching || tmplFetching}
              loadingRowCount={pagination.itemsPerPage}
              currentPage={pagination.currentPage}
              itemsPerPage={pagination.itemsPerPage}
              onDoubleClick={({ item }) => {
                const selectedItem = _.find(selected, ['id', item.id]);
                if (selectedItem) {
                  setSelected(_.without(selected, selectedItem));
                } else {
                  setSelected([...selected, item]);
                }
              }}
            />
          )
      }
    </Page>
  );
};

export default AddTmplWsRefScreen;
