import PropTypes from 'prop-types';


export const userType = PropTypes.shape({
  login: PropTypes.string,
  fullname: PropTypes.string,
  rights: PropTypes.arrayOf(PropTypes.string),
});

export const warehouseType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  code: PropTypes.string,
  status: PropTypes.string,
  filialId: PropTypes.number,
  filialName: PropTypes.string,
});

export const locationType = PropTypes.shape({
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxfacex: PropTypes.number,
  priority: PropTypes.number,
});

export const equipmentType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.arrayOf(locationType),
  templatesCount: PropTypes.number,
  // planogram equipment
  title: PropTypes.string,
  priority: PropTypes.string,
  tmplEquipRefId: PropTypes.number,
});

export const locationGroupRefType = PropTypes.shape({
  id: PropTypes.number,
  locationId: PropTypes.number,
  tmplEquipRefId: PropTypes.number,
  groupId: PropTypes.number,
  groupColor: PropTypes.number,
  groupName: PropTypes.number,
  groupLevel: PropTypes.number,
  groupIsLeaf: PropTypes.number,
  groupParentId: PropTypes.number,
  groupType: PropTypes.oneOf(['product', 'dummy']),
  cells: PropTypes.arrayOf(PropTypes.number),
});

export const templateTypeType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  priority: PropTypes.number,
  description: PropTypes.string,
});

export const planogramTmplType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  lastEditor: PropTypes.string,
  createTime: PropTypes.instanceOf(Date),
  lastTime: PropTypes.instanceOf(Date),
  equipments: PropTypes.arrayOf(equipmentType),
  layouts: PropTypes.arrayOf(locationGroupRefType),
  equipsCount: PropTypes.number,
  wsCount: PropTypes.number,
});
