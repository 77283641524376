const STATUSES = [
  'Готов к блокированию',
  'Начато закрытие',
  'Закрыт',
  'Начато временное закрытие',
  'Закрыт временно',
  'Готов к завозу ПослеВремЗакр',
  'Готов к завозу',
  'Планируется открытие',
  'Открытие отложено',
  'Открытие отменено',
  'Открыт',
  'Открыт после ВремЗакр',
  'Проинвентаризировано',
];

export default STATUSES;
