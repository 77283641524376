import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Classes,
  Dialog,
  Intent,
} from '@blueprintjs/core';


const ConfirmModal = ({
  isOpen,
  onClose,
  onYes,
  onNo,
}) => (
  <Dialog
    icon="warning-sign"
    title="Внимание"
    isOpen={isOpen}
    onClose={onClose}
  >
    <div className={Classes.DIALOG_BODY}>
      <p>Есть несохраненные данные, сохранить изменения?</p>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button onClick={onClose}>Отмена</Button>
        <Button
          icon="cross"
          onClick={onNo}
          intent={Intent.DANGER}
        >
          Не сохранять
        </Button>
        <Button
          icon="tick"
          onClick={onYes}
          intent={Intent.SUCCESS}
        >
          Сохранить
        </Button>
      </div>
    </div>
  </Dialog>
);

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};


class RouteLeaveGuard extends React.Component {
  constructor() {
    super();
    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
    };
  }

  componentDidUpdate = () => {
    const { when } = this.props;
    const { modalVisible } = this.state;
    if (when && !modalVisible) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount = () => {
    window.onbeforeunload = undefined;
  }

  showModal = (location) => this.setState({
    modalVisible: true,
    lastLocation: location,
  });

  closeModal = (callback) => this.setState({
    modalVisible: false,
  }, callback);

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    const { when } = this.props;
    if (!confirmedNavigation && when) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  }

  handleYesClick = () => this.closeModal(() => {
    const { navigate, onSave } = this.props;
    const { lastLocation } = this.state;
    if (lastLocation) {
      this.setState({
        confirmedNavigation: true,
      }, () => {
        onSave().then(() => navigate(lastLocation.pathname));
      });
    }
  });

  handleNoClick = () => this.closeModal(() => {
    const { navigate } = this.props;
    const { lastLocation } = this.state;
    if (lastLocation) {
      this.setState({
        confirmedNavigation: true,
      }, () => {
        navigate(lastLocation.pathname);
      });
    }
  });

  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;
    return (
      <>
        <Prompt
          when={when}
          message={this.handleBlockedNavigation}
        />
        <ConfirmModal
          isOpen={modalVisible}
          onClose={() => this.closeModal(() => {})}
          onYes={this.handleYesClick}
          onNo={this.handleNoClick}
        />
      </>
    );
  }
}

RouteLeaveGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default RouteLeaveGuard;
