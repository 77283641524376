import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Intent,
  NumericInput,
} from '@blueprintjs/core';
import _ from 'lodash';
import { getHasPreviousMap, isShelfInvalid } from './utils';
import { intRange } from '../../utils/numbers';
import { equipmentType, locationType } from '../../proptyping';
import styles from './index.module.scss';


const Cell = (i) => <div key={i} className={styles.cell}>{i}</div>;

const ShelfEdit = ({
  location,
  onEditShelf,
  invalid,
  invalidLabel,
}) => (
  <div key={location.locationId} className={styles.shelf}>
    <div className={styles.priorityContainer}>
      <div
        className={styles.priorityEditWrapper}
        title={invalid ? invalidLabel : `Приоритет ${location.priority}`}
      >
        <NumericInput
          fill
          clampValueOnBlur
          value={location.priority}
          buttonPosition="none"
          className={styles.priorityEdit}
          intent={invalid ? Intent.DANGER : Intent.NONE}
          placeholder="0"
          min={0}
          max={99}
          onValueChange={(val) => {
            onEditShelf({ ...location, priority: val });
          }}
        />
      </div>
    </div>
    <div className={styles.cellWrapper}>
      {intRange(1, location.maxfacex).map(Cell)}
    </div>
    <div className={styles.shelfButtonWrapper}>
      <Button
        className={styles.shelfButton}
        small
        icon="plus"
        intent={Intent.PRIMARY}
        title="Добавить ячейку"
        disabled={location.maxfacex >= 20}
        onClick={() => { onEditShelf({ ...location, maxfacex: location.maxfacex + 1 }); }}
      />
      <Button
        className={styles.shelfButton}
        small
        icon="minus"
        intent={Intent.PRIMARY}
        title="Убрать ячейку"
        disabled={location.maxfacex <= 1}
        onClick={() => { onEditShelf({ ...location, maxfacex: location.maxfacex - 1 }); }}
      />
    </div>
  </div>
);

ShelfEdit.propTypes = {
  location: locationType.isRequired,
  onEditShelf: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  invalidLabel: PropTypes.string,
};

ShelfEdit.defaultProps = {
  invalidLabel: null,
};


const EquipmentEdit = ({ equipment, onEditShelf }) => {
  const hasPreviousMap = getHasPreviousMap(equipment);
  const counts = _.countBy(equipment.children, 'priority');

  return (
    <div className={styles.equipment}>
      <div key="title" className={styles.equipmentTitle}>{equipment.name}</div>
      {equipment.children.map((x) => {
        const invalid = isShelfInvalid(x, counts, hasPreviousMap);
        let invalidLabel = null;
        if (!x.priority) {
          invalidLabel = 'Обязательно для заполнения';
        } else if (counts[x.priority] > 1) {
          invalidLabel = 'Должно быть уникальным';
        } else if (!hasPreviousMap[x.priority]) {
          invalidLabel = `Пропущен приоритет ${x.priority - 1}`;
        }
        return (
          <ShelfEdit
            key={x.locationId}
            location={x}
            onEditShelf={onEditShelf}
            invalid={invalid}
            invalidLabel={invalidLabel}
          />
        );
      })}
    </div>
  );
};

EquipmentEdit.propTypes = {
  equipment: equipmentType.isRequired,
  onEditShelf: PropTypes.func.isRequired,
};

export default EquipmentEdit;
