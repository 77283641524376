import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Intent,
  Button,
  Classes,
  Dialog,
  FormGroup,
  Position,
  Toaster,
  Spinner,
} from '@blueprintjs/core';
import SimpleSelect, { getRespItems } from '../../../widgets/SimpleSelect';
import { useRequest } from '../../../api/useRequest';
import { getPlanogramTmplList, postTmplWsRef } from '../../../api';
import { buildQueryParams } from '../../../api/utils';
import styles from './index.module.scss';

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const AddTemplWsRefDialog = ({
  isOpen,
  onSaved,
  onClose,
  wsId,
  wsName,
  tmplId,
}) => {
  const [currentTmplId, setCurrentTmplId] = useState(tmplId);
  const [{ data: templates }, fetchTemplates] = useRequest(getPlanogramTmplList);
  const [{ fetching: saveFetching }, saveTemplWsRef] = useRequest(postTmplWsRef, {
    onSuccess: () => {
      onSaved();
      const message = 'Шаблон привязан к магазину';
      toaster.show({
        intent: Intent.SUCCESS,
        message,
        icon: 'tick',
      });
    },
    onError,
  });
  const invalid = !wsId || !currentTmplId;
  const initialWs = wsId ? {
    title: wsName,
    key: wsId,
    value: wsId,
  } : null;
  useEffect(() => {
    if (isOpen) {
      setCurrentTmplId(tmplId);
    }
  }, [isOpen, setCurrentTmplId, tmplId]);
  useEffect(() => {
    const params = buildQueryParams({ filters: { draftEq: false } });
    fetchTemplates(params);
  }, [fetchTemplates]);
  const bodyClassName = saveFetching ? classNames(
    Classes.DIALOG_BODY,
    styles.dialogSaving,
  ) : Classes.DIALOG_BODY;
  return (
    <Dialog
      icon="add"
      title="Привязка шаблона к магазину"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={bodyClassName}>
        <FormGroup
          inline
          label="Шаблон планограммы:"
          className={styles.detailsRow}
        >
          <SimpleSelect
            emptiable
            filterable
            disabled={!!tmplId}
            items={getRespItems(templates)}
            value={currentTmplId}
            onChange={(id) => setCurrentTmplId(id)}
            menuAlign={Position.BOTTOM_RIGHT}
          />
        </FormGroup>
        <FormGroup
          inline
          label="Магазин:"
          className={styles.detailsRow}
        >
          <SimpleSelect
            disabled
            items={[initialWs]}
            value={wsId}
            onChange={() => {}}
          />
        </FormGroup>
      </div>
      { saveFetching ? (
        <div className={Classes.DIALOG_BODY}>
          <Spinner />
        </div>
      ) : null }
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={onClose}>Отмена</Button>
          <Button
            disabled={invalid || saveFetching}
            icon="floppy-disk"
            onClick={() => {
              saveTemplWsRef(currentTmplId, wsId);
            }}
            intent={Intent.PRIMARY}
          >
            Создать
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

AddTemplWsRefDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSaved: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  wsId: PropTypes.number,
  wsName: PropTypes.string,
  tmplId: PropTypes.number,
};

AddTemplWsRefDialog.defaultProps = {
  wsId: undefined,
  wsName: undefined,
  tmplId: undefined,
};

export default AddTemplWsRefDialog;
