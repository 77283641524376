import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Intent,
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Position,
  Toaster,
  Spinner,
} from '@blueprintjs/core';
import SimpleSelect, { getRespItems } from '../../widgets/SimpleSelect';
import { useRequest } from '../../api/useRequest';
import { getRoleList, postUser, putUser } from '../../api';
import { userType } from '../../proptyping';
import styles from './UserEditDialog.module.scss';

const toaster = Toaster.create({ position: Position.BOTTOM });

const onSuccess = (onSaved) => () => {
  const message = 'Пользователь сохранен';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
  if (onSaved) {
    onSaved();
  }
};

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const UserEditDialog = ({
  user,
  setUser,
  isOpen,
  onClose,
  onSaved,
}) => {
  const title = user.id ? 'Редактирование пользователя' : 'Создание пользователя';
  const [{ data: rolesResp }, fetchRoles] = useRequest(getRoleList);
  useEffect(() => { fetchRoles(); }, [fetchRoles]);
  const roles = getRespItems(rolesResp);
  const [{
    fetching: saveFetching,
  }, save] = useRequest(
    (user.id) ? putUser : postUser,
    { onSuccess: onSuccess(onSaved), onError },
  );
  const invalid = (
    !user.lastname
    || !user.firstname
    || !user.secondname
    || !(user.role && user.role.id)
  );

  return (
    <Dialog
      icon="edit"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={classNames(Classes.DIALOG_BODY, styles.dialogBody)}>
        { saveFetching ? <Spinner /> : (
          <>
            <FormGroup
              inline
              label="Логин:"
              className={styles.detailsRow}
              helperText={!user.login ? 'Обязательно для заполнения' : null}
              intent={!user.login ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                disabled={!!user.id}
                fill
                intent={!user.login ? Intent.DANGER : Intent.NONE}
                value={user.login}
                onChange={(e) => setUser({ ...user, login: e.target.value })}
              />
            </FormGroup>
            <FormGroup
              inline
              label="Фамилия:"
              className={styles.detailsRow}
              helperText={!user.lastname ? 'Обязательно для заполнения' : null}
              intent={!user.lastname ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                fill
                intent={!user.lastname ? Intent.DANGER : Intent.NONE}
                value={user.lastname}
                onChange={(e) => setUser({ ...user, lastname: e.target.value })}
              />
            </FormGroup>
            <FormGroup
              inline
              label="Имя:"
              className={styles.detailsRow}
              helperText={!user.firstname ? 'Обязательно для заполнения' : null}
              intent={!user.firstname ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                fill
                intent={!user.firstname ? Intent.DANGER : Intent.NONE}
                value={user.firstname}
                onChange={(e) => setUser({ ...user, firstname: e.target.value })}
              />
            </FormGroup>
            <FormGroup
              inline
              label="Отчество:"
              className={styles.detailsRow}
              helperText={!user.secondname ? 'Обязательно для заполнения' : null}
              intent={!user.secondname ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                fill
                intent={!user.secondname ? Intent.DANGER : Intent.NONE}
                value={user.secondname}
                onChange={(e) => setUser({ ...user, secondname: e.target.value })}
              />
            </FormGroup>

            <FormGroup
              inline
              label="Роль:"
              className={styles.detailsRow}
              helperText={!(user.role && user.role.id) ? 'Обязательно для заполнения' : null}
              intent={!(user.role && user.role.id) ? Intent.DANGER : Intent.NONE}
            >
              <SimpleSelect
                emptiable
                items={roles}
                value={user.role && user.role.id}
                onChange={(id) => setUser({ ...user, role: { id } })}
                menuAlign={Position.BOTTOM_RIGHT}
              />
            </FormGroup>
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={onClose}>Отмена</Button>
          <Button
            disabled={invalid || saveFetching}
            icon="floppy-disk"
            onClick={() => {
              if (user.id) {
                save(user.id, user);
              } else {
                save(user);
              }
            }}
            intent={Intent.PRIMARY}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

UserEditDialog.propTypes = {
  user: userType.isRequired,
  setUser: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default UserEditDialog;
