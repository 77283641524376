import React from 'react';
import PropTypes from 'prop-types';
import {
  Intent,
  Alert,
} from '@blueprintjs/core';

const DeleteAlert = ({
  name,
  isOpen,
  onConfirm,
  onCancel,
}) => (
  <Alert
    cancelButtonText="Отмена"
    confirmButtonText="Удалить"
    icon="trash"
    intent={Intent.DANGER}
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <p>
      Удалить шаблон планограммы
      {' '}
      <b>{name}</b>
      ?
    </p>
  </Alert>
);

DeleteAlert.propTypes = {
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteAlert;
