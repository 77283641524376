/**
 * Хук для вызова АПИ
 * по мотивам https://github.com/react-rekindle/use-request
 *
 * @example
 * // Базовый пример
 * const getData = () => axios.get('/api/data');
 * const [{
 *   fetching,
 *   error,
 *   data
 * }, fetchData] = useRequest(getData);
 * fetchData();
 *
 * @example
 * // C начальным состоянием и callback-ом
 * const postData = (params) => axios.post('/api/data', params);
 * const [{ fetching }, doPostData] = useRequest(postData, {
 *   onSuccess: (data) => console.log('onSuccess', data),
 *   onError: (error) => console.log('onError', error),
 *   initialState: { fetching: true },
 * });
 * doPostData(params);
 */

import { useReducer, useCallback } from 'react';

const REQUEST_START = 'REQUEST_START';
const REQUEST_DONE = 'REQUEST_DONE';
const REQUEST_ERROR = 'REQUEST_ERROR';

const defaultInitialState = {
  fetching: false,
  data: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case REQUEST_START:
      return { ...defaultInitialState, fetching: true };
    case REQUEST_DONE:
      return {
        ...state,
        fetching: false,
        data: action.payload,
        error: null,
      };
    case REQUEST_ERROR:
      return { ...state, fetching: false, error: action.error };
    default:
      throw new Error('error');
  }
};

const request = async (
  instance,
  dispatch,
  onSuccess,
  onError,
) => {
  try {
    dispatch({ type: REQUEST_START });
    const result = await instance();
    dispatch({ type: REQUEST_DONE, payload: result });
    if (onSuccess) {
      onSuccess(result);
    }
    return result;
  } catch (error) {
    dispatch({ type: REQUEST_ERROR, error });
    if (onError) {
      onError(error);
    }
    return undefined;
  }
};

export const useRequest = (
  fetcher,
  {
    onSuccess,
    onError,
    initialState,
  } = {},
) => {
  const initialIState = {
    ...defaultInitialState,
    ...initialState,
  };

  const [state, dispatch] = useReducer(reducer, initialIState);
  const requestCallback = (...args) => request(
    () => fetcher(...args),
    dispatch,
    onSuccess,
    onError,
  );
  const memoizedRequestCallback = useCallback(requestCallback, [onSuccess, onError]);
  return [state, memoizedRequestCallback];
};

export default useRequest;
