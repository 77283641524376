import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Page.module.css';

const Page = ({
  children,
  fullScreen,
  wide,
  flexible,
}) => {
  function className() {
    if (fullScreen) {
      return styles.fullScreen;
    }
    if (wide) {
      return styles.wide;
    }
    if (flexible) {
      return styles.flexible;
    }
    return styles.page;
  }

  return (
    <div className={className()}>
      {children}
    </div>
  );
};

Page.propTypes = {
  fullScreen: PropTypes.bool,
  wide: PropTypes.bool,
  flexible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
};

Page.defaultProps = {
  fullScreen: false,
  wide: false,
  flexible: false,
};

export default Page;
