import React from 'react';
import { Provider } from 'react-redux';
import { FocusStyleManager } from '@blueprintjs/core';
import RootContainer from './navigation/RootContainer';
import store from './state';

FocusStyleManager.onlyShowFocusOnTabs();

const App = () => (
  <Provider store={store}>
    <RootContainer />
  </Provider>
);

export default App;
