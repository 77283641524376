import React from 'react';
import { Intent, Tag } from '@blueprintjs/core';
import { intRange } from '../../utils/numbers';
import { equipmentType } from '../../proptyping';
import styles from './index.module.scss';

const Cell = (i) => <div key={i} className={styles.cell}>{i}</div>;

const Shelf = (location) => (
  <div key={location.locationId} className={styles.shelf}>
    <div className={styles.priorityContainer}>
      <Tag
        className={styles.priorityTag}
        intent={Intent.PRIMARY}
        title={`Приоритет ${location.priority}`}
        minimal
      >
        {location.priority}
      </Tag>
    </div>
    {intRange(1, location.maxfacex).map(Cell)}
  </div>
);


const Equipment = ({ equipment }) => (
  <div className={styles.equipment}>
    <div key="title" className={styles.equipmentTitle}>{equipment.name}</div>
    {equipment.children.map(Shelf)}
  </div>
);

Equipment.propTypes = {
  equipment: equipmentType.isRequired,
};

export default Equipment;
