import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  H3,
  Button,
  Card,
  Elevation,
  Spinner,
  Intent,
  NonIdealState,
  Toaster,
  Position,
} from '@blueprintjs/core';
import { selectCanEditTmpl } from '../../state/reducers/auth';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import Equipment from './Equipment';
import WarehouseTable from './WarehouseTable';
import DeleteAlert from './DeleteAlert';
import { fetchTemplateAction } from '../../state/actionCreators/tmplWizard';
import { tmplHasActiveWs, delPlanogramTmpl } from '../../api';
import { useRequest } from '../../api/useRequest';
import styles from './index.module.scss';

const BREADCRUMB_ITEMS = [
  { text: 'Шаблоны планограмм', href: '/templates' },
  { text: 'Просмотр шаблона планограммы' },
];

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const getOnSuccess = (history) => () => {
  history.push('/templates');
  const message = 'Шаблон удален';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
};

const PlanogramTmplViewScreen = ({
  canEdit,
  template,
  fetching,
  error,
  fetchTemplate,
  user,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const canDelete = (
    !fetching
    && canEdit
    && (user.login === template.author || user.role.code === 'admin')
  );
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [{
    fetching: hasActiveWsFetching,
    data: hasActiveWs,
    error: hasActiveWsError,
  }, checkActiveWs] = useRequest(tmplHasActiveWs, { initialState: true });
  useEffect(() => {
    fetchTemplate(id);
  }, [id, fetchTemplate]);
  useEffect(() => {
    checkActiveWs(id);
  }, [id, checkActiveWs]);
  const [{
    fetching: deleting,
  }, deleteTmpl] = useRequest(delPlanogramTmpl, {
    onSuccess: getOnSuccess(history),
    onError,
  });
  const loading = fetching || deleting;
  return (
    <Page wide>
      <BreadcrumbNav items={BREADCRUMB_ITEMS} />
      <Card className={styles.layoutCard} elevation={Elevation.TWO}>
        <div className={styles.cardHead}>
          <H3>
            {fetching ? '' : template.name || ''}
            {!fetching && template && template.draft ? ' (черновик)' : null}
          </H3>
          <div className={styles.spacer} />
          { canDelete ? (
            <Button
              className={styles.button}
              loading={hasActiveWsFetching || deleting}
              title={hasActiveWs ? 'Есть активные привязки' : undefined}
              disabled={hasActiveWs}
              icon="trash"
              intent={Intent.DANGER}
              onClick={() => setDeleteAlertOpen(true)}
            >
              Удалить шаблон
            </Button>
          ) : null }
          { canEdit ? (
            <Button
              className={styles.button}
              loading={hasActiveWsFetching || deleting}
              title={hasActiveWs ? 'Есть активные привязки' : undefined}
              disabled={hasActiveWs}
              icon="edit"
              intent={Intent.PRIMARY}
              onClick={() => history.push(`/templates/${id}/edit`)}
            >
              Изменить шаблон
            </Button>
          ) : null }
        </div>

        { loading ? <Spinner /> : null }
        { error || hasActiveWsError ? (
          <NonIdealState title="Ошибка" description={(error || hasActiveWsError).message} icon="error" />
        ) : null}
        { !loading && !error && !hasActiveWsError ? (
          <div className={styles.layoutWrapper}>
            {template.equipments.length === 0 ? (
              <span className={styles.noEquipment}>Нет оборудования в шаблоне</span>
            ) : null}
            {template.equipments.map((e, index) => (
              /* eslint-disable react/no-array-index-key */
              <Equipment
                key={`${e.id}-${e.title}-${index}`}
                index={index}
                equipment={e}
                layouts={template.layouts.filter((l) => l.tmplEquipRefId === e.tmplEquipRefId)}
              />
              /* eslint-enable react/no-array-index-key */
            ))}
          </div>
        ) : null }
        <WarehouseTable checkActiveWs={checkActiveWs} />
        <DeleteAlert
          name={template.name}
          isOpen={deleteAlertOpen}
          onCancel={() => setDeleteAlertOpen(false)}
          onConfirm={() => {
            deleteTmpl(id);
            setDeleteAlertOpen(false);
          }}
        />
      </Card>
    </Page>
  );
};

PlanogramTmplViewScreen.propTypes = {
  template: PropTypes.shape({
    name: PropTypes.string,
    draft: PropTypes.bool,
    author: PropTypes.string,
    equipments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      tmplEquipRefId: PropTypes.number,
    })),
    layouts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      tmplEquipRefId: PropTypes.number,
    })),
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  fetchTemplate: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    login: PropTypes.string,
    role: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
};

PlanogramTmplViewScreen.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  template: state.tmplWizard.template.data,
  fetching: state.tmplWizard.template.fetching,
  error: state.tmplWizard.template.error,
  canEdit: selectCanEditTmpl(state),
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTemplate: (id) => dispatch(fetchTemplateAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramTmplViewScreen);
