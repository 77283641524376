import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Elevation,
  Intent,
  FormGroup,
  InputGroup,
  NumericInput,
} from '@blueprintjs/core';
import _ from 'lodash';
import { isValidEquipment } from './utils';
import { equipmentType } from '../../proptyping';
import styles from './index.module.scss';

const DetailsEditCard = ({
  equipment,
  onSave,
  onCancel,
  onEdit,
}) => (
  <Card className={styles.detailsCard} elevation={Elevation.TWO}>
    <h3 className={styles.detailsTitle}>{ equipment.id ? 'Редактировнаие оборудования' : 'Создание оборудования'}</h3>
    <div className={styles.detailsContent}>
      <FormGroup
        inline
        label="Название:"
        className={styles.detailsRow}
        helperText={!equipment.name ? 'Обязательно для заполнения' : null}
        intent={!equipment.name ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          fill
          intent={!equipment.name ? Intent.DANGER : Intent.NONE}
          value={equipment.name}
          onChange={(e) => onEdit({ ...equipment, name: e.target.value })}
        />
      </FormGroup>
      <FormGroup
        inline
        label="Ширина, мм:"
        className={styles.detailsRow}
        helperText={!equipment.width ? 'Обязательно для заполнения' : null}
        intent={!equipment.width ? Intent.DANGER : Intent.NONE}
      >
        <NumericInput
          fill
          clampValueOnBlur
          intent={!equipment.width ? Intent.DANGER : Intent.NONE}
          value={equipment.width || undefined}
          placeholder="0"
          min={0}
          max={10000}
          onValueChange={(val) => {
            onEdit({ ...equipment, width: val });
          }}
        />
      </FormGroup>
      <FormGroup
        inline
        label="Высота, мм:"
        className={styles.detailsRow}
        helperText={!equipment.height ? 'Обязательно для заполнения' : null}
        intent={!equipment.height ? Intent.DANGER : Intent.NONE}
      >
        <NumericInput
          fill
          clampValueOnBlur
          intent={!equipment.height ? Intent.DANGER : Intent.NONE}
          value={equipment.height || undefined}
          placeholder="0"
          min={0}
          max={10000}
          onValueChange={(val) => {
            onEdit({ ...equipment, height: val });
          }}
        />
      </FormGroup>
      <FormGroup
        inline
        label="Количество полок:"
        className={styles.detailsRow}
        helperText={!equipment.children.length ? 'Обязательно для заполнения' : null}
        intent={!equipment.children.length ? Intent.DANGER : Intent.NONE}
      >
        <NumericInput
          fill
          clampValueOnBlur
          intent={!equipment.children.length ? Intent.DANGER : Intent.NONE}
          value={equipment.children.length || undefined}
          placeholder="0"
          min={0}
          max={20}
          readOnly={!!equipment.templatesCount}
          onValueChange={(val) => {
            const children = _.clone(equipment.children);
            if (val > children.length && val <= 20) {
              while (val > children.length) {
                const maxPriority = (_.maxBy(children, 'priority') || { priority: 0 }).priority;
                children.push({
                  locationId: `new-${children.length + 1}`,
                  priority: maxPriority + 1,
                  maxfacex: 7,
                });
              }
            }
            if (val < children.length) {
              while (val < children.length) {
                children.pop();
              }
            }
            onEdit({ ...equipment, children });
          }}
        />
      </FormGroup>
    </div>
    <div className={styles.detailsBottom}>
      <Button
        className={styles.detailsButton}
        onClick={onCancel}
        text="Отмена"
        icon="cross"
        intent={Intent.DANGER}
        minimal
      />
      <Button
        className={styles.detailsButton}
        onClick={onSave}
        disabled={!isValidEquipment(equipment)}
        text="Сохранить"
        icon="floppy-disk"
        intent={Intent.SUCCESS}
      />
    </div>
  </Card>
);

DetailsEditCard.propTypes = {
  equipment: equipmentType.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default DetailsEditCard;
