import React from 'react';
import PropTypes from 'prop-types';
import { Intent, Alert } from '@blueprintjs/core';

const ToggleBlockedAlert = ({
  user,
  isOpen,
  onCancel,
  onConfirm,
}) => (
  <Alert
    cancelButtonText="Отмена"
    confirmButtonText={user && !user.blocked ? 'Заблокировать' : 'Разблокировать'}
    icon={user && !user.blocked ? 'lock' : 'unlock'}
    intent={Intent.DANGER}
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    {user && !user.blocked ? 'Заблокировать ' : 'Разблокировать '}
    {'пользователя '}
    {user.fullname}
    {' ?'}
  </Alert>
);

ToggleBlockedAlert.propTypes = {
  user: PropTypes.shape({
    fullname: PropTypes.string,
    blocked: PropTypes.bool,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ToggleBlockedAlert;
