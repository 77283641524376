import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import SelectFields from './SelectFields';
import PageSelect from '../../widgets/PageSelect';
import styles from './ActionBar.module.scss';


const ActionBar = ({
  totals,
  pagination,
  setPagination,
  hasFilters,
  filtersVisible,
  setFilterVisible,
  fields,
  toggleField,
}) => (
  <div className={styles.actionBar}>
    <SelectFields fields={fields} toggleField={toggleField} />
    <Button
      onClick={() => { setFilterVisible(!filtersVisible); }}
      minimal
      icon={hasFilters ? 'filter-keep' : 'filter'}
      rightIcon={filtersVisible ? 'chevron-up' : 'chevron-down'}
      text="Фильтры"
      intent={Intent.PRIMARY}
      className={styles.openFiltersButton}
    />
    <div className={styles.spacer} />
    <PageSelect
      showTotal
      showItemsPerPageChanger
      total={totals.items}
      currentPage={pagination.currentPage}
      maxPage={totals.pages || pagination.currentPage}
      currentItemsPerPage={pagination.itemsPerPage}
      onPageSelect={
        (currentPage) => { setPagination({ ...pagination, currentPage }); }
      }
      onChangeItemsPerPage={
        (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
      }
    />
  </div>
);

ActionBar.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  toggleField: PropTypes.func.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  setFilterVisible: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    items: PropTypes.number,
    pages: PropTypes.number,
  }).isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default ActionBar;
