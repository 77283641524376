import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tree, TreeNode } from '@blueprintjs/core';
import { useDrag, useDrop } from 'react-dnd';
import ItemTypes from './ItemTypes';
import styles from './ReorderableTree.module.scss';

const ReorderableTreeNode = (props) => {
  const {
    nodeData,
    moveNode,
    moveNodeComplete,
    index,
    depth,
    path,
  } = props;
  const elementRef = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.LAYOUT_GROUP,
    hover(item, monitor) {
      if (!elementRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      if (item.depth !== depth) {
        return;
      }

      if (!nodeData.num) {
        return;
      }

      if (nodeData.parentId !== item.nodeData.parentId) {
        return;
      }

      const hoverBoundingRect = elementRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveNode({
        id: item.nodeData.id,
        data: item.nodeData,
        path: item.path,
        oldIndex: dragIndex,
        newIndex: hoverIndex,
      });
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.LAYOUT_GROUP,
      nodeData,
      index,
      depth,
      path,
    },
    canDrag: () => !!nodeData.num,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item) {
      moveNodeComplete({
        id: item.nodeData.id,
        data: item.nodeData,
        path: item.path,
      });
    },
  });
  const className = isDragging ? styles.nodeDragging : styles.node;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <TreeNode
      {...props}
      className={className}
      contentRef={(node, element) => {
        drag(drop(element));
        elementRef.current = element;
        props.contentRef(node, element);
      }}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

ReorderableTreeNode.propTypes = {
  contentRef: PropTypes.func.isRequired,
  nodeData: PropTypes.shape({
    num: PropTypes.number,
    parentId: PropTypes.number,
  }).isRequired,
  moveNode: PropTypes.func.isRequired,
  moveNodeComplete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  depth: PropTypes.number.isRequired,
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
};

class ReorderableTree extends Tree {
  constructor() {
    super();

    this.renderNodes = (treeNodes, currentPath, className) => {
      if (treeNodes == null) {
        return null;
      }
      /* eslint-disable react/no-this-in-sfc */
      /* eslint-disable react/jsx-props-no-spreading */
      const nodeItems = treeNodes.map((node, i) => {
        const elementPath = currentPath.concat(i);
        return (
          <ReorderableTreeNode
            {...node}
            key={node.id}
            contentRef={this.handleContentRef}
            depth={elementPath.length - 1}
            onClick={this.handleNodeClick}
            onContextMenu={this.handleNodeContextMenu}
            onCollapse={this.handleNodeCollapse}
            onDoubleClick={this.handleNodeDoubleClick}
            onExpand={this.handleNodeExpand}
            onMouseEnter={this.handleNodeMouseEnter}
            onMouseLeave={this.handleNodeMouseLeave}
            path={elementPath}
            index={i}
            moveNode={this.handleMoveNode}
            moveNodeComplete={this.handleMoveNodeComplete}
          >
            {this.renderNodes(node.childNodes, elementPath)}
          </ReorderableTreeNode>
        );
      });
      /* eslint-enable react/no-this-in-sfc */
      /* eslint-enable react/jsx-props-no-spreading */
      return <ul className={classNames('bp3-tree-node-list', className)}>{nodeItems}</ul>;
    };
  }

  handleMoveNode = ({
    id,
    path,
    oldIndex,
    newIndex,
  }) => {
    const { onMoveNode } = this.props;
    onMoveNode(
      id,
      path,
      oldIndex,
      newIndex,
    );
  }

  handleMoveNodeComplete = ({
    id,
    path,
    data,
  }) => {
    const { onMoveNodeComplete } = this.props;
    onMoveNodeComplete(
      id,
      path,
      data,
    );
  }
}

export default ReorderableTree;
