import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from '@blueprintjs/core';
import useDebounce from '../../utils/debounce';
import styles from './Filters.module.css';

const InputFilter = ({
  icon,
  name,
  value,
  onChange,
  withDebounce,
}) => {
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);
  useEffect(
    () => {
      if (!withDebounce && value !== stateValue) {
        onChange(stateValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateValue],
  );
  useEffect(
    () => {
      if (withDebounce && value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );
  return (
    <div className={styles.filtersGroup}>
      <div className={styles.filtersLabel}>
        {name}
        {':'}
      </div>
      <InputGroup
        leftIcon={icon}
        placeholder={name}
        value={stateValue || ''}
        onChange={(e) => { setStateValue(e.target.value); }}
      />
    </div>
  );
};

InputFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  withDebounce: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

InputFilter.defaultProps = {
  value: undefined,
  withDebounce: true,
  icon: 'filter',
};

export default InputFilter;
