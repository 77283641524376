import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Button } from '@blueprintjs/core';
import useDebounce from '../../utils/debounce';

const SearchInput = ({
  icon,
  name,
  value,
  onChange,
  withDebounce,
}) => {
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);
  useEffect(
    () => {
      if (!withDebounce && value !== stateValue) {
        onChange(stateValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateValue],
  );
  useEffect(
    () => {
      if (withDebounce && value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );
  return (
    <InputGroup
      fill
      large
      round
      leftIcon={icon}
      placeholder={name}
      value={stateValue || ''}
      onChange={(e) => { setStateValue(e.target.value); }}
      rightElement={
        stateValue
          ? (
            <Button
              minimal
              rightIcon="cross"
              onClick={() => {
                onChange(undefined);
                setStateValue(undefined);
              }}
            />
          )
          : null
      }
    />
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  withDebounce: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string,
};

SearchInput.defaultProps = {
  value: undefined,
  withDebounce: true,
  icon: 'search',
  name: '',
};

export default SearchInput;
