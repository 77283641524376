import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Icon,
  Intent,
  Button,
  Callout,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Position,
  Toaster,
  Spinner,
  Switch,
  Tooltip,
} from '@blueprintjs/core';
import { useRequest } from '../../api/useRequest';
import { putLayoutTree } from '../../api';
import ColorPicker from '../../widgets/ColorPicker';
import styles from './GroupEditDialog.module.scss';

const LAYOUT_TYPES = {
  smartphones: 'Смартфоны',
  tablets: 'Планшеты',
  portables: 'Портативная техника',
};

const toaster = Toaster.create({ position: Position.BOTTOM });

const onSuccess = (onSaved) => (data) => {
  const message = 'Группа сохранена';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
  if (onSaved) {
    onSaved(data);
  }
};

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const SwitchOption = ({
  checked,
  code,
  label,
  title,
  icon,
  symbol,
  iconModifier,
  onChange,
}) => (
  <Tooltip content={title} className={styles.tooltip}>
    <div className={classNames('bp3-form-group', 'bp3-inline', styles.switchRow)}>
      <label
        className="bp3-label"
        htmlFor={code}
      >
        { icon && <Icon className={styles.switchIcon} icon={icon} /> }
        { symbol && <span className={styles.switchSymbol}>{ symbol }</span> }
        { iconModifier && <Icon className={styles.switchIconModifier} icon={iconModifier} /> }
        <span>{label}</span>
      </label>
      <div className="bp3-form-content">
        <Switch
          id={code}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  </Tooltip>
);

SwitchOption.propTypes = {
  checked: PropTypes.bool,
  code: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.element,
  icon: PropTypes.string,
  symbol: PropTypes.string,
  iconModifier: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SwitchOption.defaultProps = {
  checked: false,
  title: undefined,
  iconModifier: undefined,
  icon: null,
  symbol: null,
};

const GroupEditDialog = ({
  group,
  isOpen,
  onClose,
  onSaved,
}) => {
  const [stateGroup, setStateGroup] = useState({
    ...group,
    calcOptions: group.calcOptions || {},
  });
  useEffect(() => {
    if (isOpen) {
      setStateGroup({
        ...group,
        calcOptions: group.calcOptions || {},
      });
    }
  }, [isOpen, group]);
  const [{
    fetching: saveFetching,
  }, save] = useRequest(
    putLayoutTree,
    { onSuccess: onSuccess(onSaved), onError },
  );
  const invalid = (
    !!stateGroup.calcOptions.expandBindsDeny
    && !!stateGroup.calcOptions.placeAllModels
  );

  return (
    <Dialog
      icon="edit"
      title="Редактирование группы"
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={false}
    >
      <div className={classNames(Classes.DIALOG_BODY, styles.dialogBody)}>
        { saveFetching ? <Spinner /> : (
          <>
            <FormGroup
              inline
              label="Название:"
              className={styles.detailsRow}
            >
              <InputGroup
                disabled
                fill
                value={stateGroup.name}
              />
            </FormGroup>
            <FormGroup
              inline
              label="Цвет:"
              className={styles.detailsRow}
            >
              <ColorPicker
                width={300}
                value={stateGroup.color}
                onChange={(value) => setStateGroup({ ...stateGroup, color: value })}
              />
            </FormGroup>
            <FormGroup
              inline
              label="Тип выкладки:"
              className={styles.detailsRow}
            >
              <InputGroup
                disabled
                fill
                value={LAYOUT_TYPES[stateGroup.layoutType]}
              />
            </FormGroup>
            <Callout title="Настройки расчета" className={styles.options}>
              <SwitchOption
                code="can-overflow-field"
                label="Может перетекать на витрины:"
                title={(
                  <span>
                    Флаг, разрешающий группе
                    <br />
                    перетекать на другую витрину
                  </span>
                )}
                icon="flows"
                checked={!!stateGroup.calcOptions.canOverflow}
                onChange={() => setStateGroup({
                  ...stateGroup,
                  calcOptions: {
                    ...stateGroup.calcOptions,
                    canOverflow: !stateGroup.calcOptions.canOverflow,
                  },
                })}
              />
              <SwitchOption
                code="place-to-binds-only-field"
                label="Не выставлять непривязанной:"
                title={(
                  <span>
                    Флаг, запрещающий группе выставляться,
                    <br />
                    если она никуда не привязана
                  </span>
                )}
                icon="group-objects"
                checked={!!stateGroup.calcOptions.placeToBindsOnly}
                onChange={() => setStateGroup({
                  ...stateGroup,
                  calcOptions: {
                    ...stateGroup.calcOptions,
                    placeToBindsOnly: !stateGroup.calcOptions.placeToBindsOnly,
                  },
                })}
              />
              <SwitchOption
                code="expand-binds-deny-field"
                label="Не расширяться:"
                title={(
                  <span>
                    Флаг, запрещающий группе
                    <br />
                    расширяться за границы её привязок
                  </span>
                )}
                icon="flow-review"
                iconModifier="slash"
                checked={!!stateGroup.calcOptions.expandBindsDeny}
                onChange={() => setStateGroup({
                  ...stateGroup,
                  calcOptions: {
                    ...stateGroup.calcOptions,
                    expandBindsDeny: !stateGroup.calcOptions.expandBindsDeny,
                  },
                })}
              />
              <SwitchOption
                code="place-all-models-field"
                label="Весь модельный ряд:"
                title={(
                  <span>
                    Разрешать расширять выкладку бренда за его границы,
                    <br />
                    с целью выставить все модели бренда
                  </span>
                )}
                icon="grid-view"
                checked={!!stateGroup.calcOptions.placeAllModels}
                onChange={() => setStateGroup({
                  ...stateGroup,
                  calcOptions: {
                    ...stateGroup.calcOptions,
                    placeAllModels: !stateGroup.calcOptions.placeAllModels,
                  },
                })}
              />
              {stateGroup.layoutType === 'portables' && (
                <SwitchOption
                  code="place-by-price-instead-of-brand"
                  label="Выставлять по цене а не по бренду:"
                  title=""
                  symbol="₽"
                  checked={!!stateGroup.calcOptions.placeByPriceInsteadOfBrand}
                  onChange={() => setStateGroup({
                    ...stateGroup,
                    calcOptions: {
                      ...stateGroup.calcOptions,
                      // eslint-disable-next-line
                      placeByPriceInsteadOfBrand: !stateGroup.calcOptions.placeByPriceInsteadOfBrand,
                    },
                  })}
                />
              )}
              {invalid && (
                <div className={styles.invalidFlags}>
                  Невозможна одновременная работа опций “Весь модельный ряд” и “Не расширяться”
                </div>
              )}
            </Callout>
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={onClose}>Отмена</Button>
          <Button
            disabled={invalid || saveFetching}
            icon="floppy-disk"
            onClick={() => {
              save(stateGroup.id, stateGroup);
            }}
            intent={Intent.PRIMARY}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

GroupEditDialog.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    calcOptions: PropTypes.shape({
      canOverflow: PropTypes.bool,
      placeToBindsOnly: PropTypes.bool,
      expandBindsDeny: PropTypes.bool,
      placeAllModels: PropTypes.bool,
      placeByPriceInsteadOfBrand: PropTypes.bool,
    }),
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

GroupEditDialog.defaultProps = {
  group: {},
};

export default GroupEditDialog;
