import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H3,
  Button,
  FormGroup,
  InputGroup,
  Card,
  Elevation,
  Intent,
  Tooltip,
  Toaster,
  Position,
} from '@blueprintjs/core';

import Page from '../../widgets/Page';
import styles from './LoginScreen.module.css';
import { loginAction } from '../../state/actionCreators/auth';

const toaster = Toaster.create({ position: Position.BOTTOM });

const LoginScreen = ({
  doLogin,
  history,
  loggingIn,
  loginError,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const onSuccess = () => {
    history.push('/');
  };
  const onError = (error) => toaster.show({
    intent: Intent.DANGER,
    message: error.message,
    icon: 'error',
  });

  const showPasswordButton = (
    <Tooltip content={`${showPassword ? 'Скрыть' : 'Показать'} пароль`}>
      <Button
        className={styles.eyeButton}
        onClick={() => setShowPassword(!showPassword)}
        icon={showPassword ? 'eye-off' : 'eye-open'}
        intent={Intent.PRIMARY}
        minimal
      />
    </Tooltip>
  );

  return (
    <Page fullScreen>
      <Card className={styles.loginCard} elevation={Elevation.TWO}>
        <form
          className={styles.loginForm}
          onSubmit={(e) => {
            doLogin({ login, password }, { onSuccess, onError });
            e.preventDefault();
          }}
        >
          <div>
            <H3 className={styles.header}>Вход в систему</H3>
            <span className="bp3-text-muted">Авторизуйтесь, чтобы получить доступ к МФР merch</span>
          </div>

          <div>
            <FormGroup label="Логин">
              <InputGroup
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                large
              />
            </FormGroup>

            <FormGroup
              label="Пароль"
              helperText={loginError ? loginError.message : null}
              intent={loginError ? Intent.DANGER : null}
            >
              <InputGroup
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                rightElement={showPasswordButton}
                large
              />
            </FormGroup>
          </div>

          <div className={styles.buttonGroup}>
            <Button
              text="Войти"
              intent={Intent.PRIMARY}
              loading={loggingIn}
              large
              type="submit"
              className={styles.button}
            />
          </div>
        </form>
      </Card>
    </Page>
  );
};

LoginScreen.propTypes = {
  loggingIn: PropTypes.bool.isRequired,
  loginError: PropTypes.instanceOf(Error),
  doLogin: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func,
  }).isRequired,
};

LoginScreen.defaultProps = {
  loginError: null,
};

const mapStateToProps = (state) => ({
  loggingIn: state.auth.loggingIn,
  error: state.auth.loginError,
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: ({ login, password }, { onSuccess, onError }) => dispatch(loginAction(
    { login, password },
    { onSuccess, onError },
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
