import React from 'react';
import {
  ContextMenu,
  Menu,
  MenuItem,
} from '@blueprintjs/core';

const getContextMenu = (onEdit, onPin, onUnpin) => (nodeData, path, e) => {
  e.preventDefault();
  ContextMenu.show(
    <Menu>
      <MenuItem
        icon="edit"
        text="Редактировать"
        onClick={() => onEdit(nodeData, path)}
      />
      { nodeData.nodeData.num ? (
        <MenuItem
          icon="unpin"
          text="Открепить"
          onClick={() => onUnpin(nodeData.id, path, nodeData.nodeData)}
        />
      ) : (
        <MenuItem
          icon="pin"
          text="Закрепить"
          onClick={() => onPin(nodeData.id, path, nodeData.nodeData)}
        />
      ) }
    </Menu>,
    { left: e.clientX, top: e.clientY },
  );
};

export default getContextMenu;
