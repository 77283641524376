import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Intent,
  Position, Popover, Menu, MenuItem, MenuDivider, Checkbox,
} from '@blueprintjs/core';

import styles from './SelectFields.module.scss';

const SelectFields = ({ fields, toggleField }) => {
  const checkedCount = fields.filter((f) => f.checked);
  const allChecked = checkedCount.length === fields.length;
  const partAllChecked = !!checkedCount.length && !allChecked;

  return (
    <Popover
      content={(
        <Menu>
          <MenuItem
            key="all_0"
            shouldDismissPopover={false}
            className={styles.fieldMenuItem}
            text={(
              <Checkbox
                className={styles.fieldCheckbox}
                checked={allChecked}
                indeterminate={partAllChecked}
                onChange={() => toggleField('ALL', !allChecked)}
              >
                Все
              </Checkbox>
            )}
          />
          <MenuDivider />
          {fields.map((f) => (
            <MenuItem
              key={f.field}
              shouldDismissPopover={false}
              className={styles.fieldMenuItem}
              text={(
                <Checkbox
                  className={styles.fieldCheckbox}
                  checked={f.checked}
                  onChange={() => toggleField(f.field, !f.checked)}
                >
                  {f.title}
                </Checkbox>
              )}
            />
          ))}
        </Menu>
      )}
      position={Position.BOTTOM_LEFT}
    >
      <Button
        minimal
        icon="cog"
        intent={Intent.PRIMARY}
        className={styles.cogButton}
      />
    </Popover>
  );
};

SelectFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    field: PropTypes.string,
    checked: PropTypes.bool,
  })).isRequired,
  toggleField: PropTypes.func.isRequired,
};

export default SelectFields;
