import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

const getContextMenu = ({ history, canEdit, onCopy }) => ({ item }) => (
  <Menu>
    <MenuItem
      text="Просмотр"
      icon="eye-open"
      onClick={() => history.push(`/templates/${item.id}`)}
    />
    { canEdit ? (
      <MenuItem
        text="Копировать"
        icon="duplicate"
        onClick={() => onCopy(item.id)}
      />
    ) : null }
  </Menu>
);

export default getContextMenu;
