import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tag,
  Intent,
  Button,
  Classes,
  Dialog,
  Spinner,
} from '@blueprintjs/core';
import { onError, onActivateSuccess, onDeactivateSuccess } from './utils';
import { useRequest } from '../../api/useRequest';
import { activateUser, deactivateUser } from '../../api';
import { userType } from '../../proptyping';
import ToggleBlockedAlert from './ToggleBlockedAlert';
import styles from './UserViewDialog.module.scss';


const blockedRenderer = (x) => {
  const content = x ? <Tag icon="lock" minimal round intent={Intent.DANGER}>Заблокирован</Tag> : null;
  return content;
};

const UserViewDialog = ({
  user,
  setUser,
  isOpen,
  onClose,
  onEdit,
  onActiveToggled,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [{ fetching: activating }, doActivateUser] = useRequest(activateUser, {
    onError,
    onSuccess: onActivateSuccess(() => {
      setUser({ ...user, blocked: false });
      onActiveToggled();
    }),
  });
  const [{ fetching: deactivating }, doDeactivateUser] = useRequest(deactivateUser, {
    onError,
    onSuccess: onDeactivateSuccess(() => {
      setUser({ ...user, blocked: true });
      onActiveToggled();
    }),
  });
  const loading = activating || deactivating;
  return (
    <Dialog
      icon="eye-open"
      title="Просмотр пользователя"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        { loading ? <Spinner /> : (
          <>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>Логин:</div>
              <div className={styles.detailsValue}>{user.login}</div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>Фамилия:</div>
              <div className={styles.detailsValue}>{user.lastname}</div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>Имя:</div>
              <div className={styles.detailsValue}>{user.firstname}</div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>Отчество:</div>
              <div className={styles.detailsValue}>{user.secondname}</div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>Роль:</div>
              <div className={styles.detailsValue}>{user.role && user.role.name}</div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>Заблокирован:</div>
              <div className={styles.detailsValue}>{blockedRenderer(user.blocked)}</div>
            </div>
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon={user.blocked ? 'unlock' : 'lock'}
            onClick={() => setIsAlertOpen(true)}
            intent={Intent.DANGER}
            disabled={loading}
          >
            {user.blocked ? 'Разблокировать' : 'Заблокировать'}
          </Button>
          <div className={styles.spacer} />
          <Button icon="cross" onClick={onClose}>Закрыть</Button>
          <Button icon="edit" onClick={() => onEdit(user)} intent={Intent.PRIMARY} disabled={loading}>Изменить</Button>
        </div>
      </div>
      <ToggleBlockedAlert
        user={user}
        isOpen={isAlertOpen}
        onCancel={() => setIsAlertOpen(false)}
        onConfirm={() => {
          if (user) {
            if (user.blocked) {
              doActivateUser(user.id);
            } else {
              doDeactivateUser(user.id);
            }
          }
          setIsAlertOpen(false);
        }}
      />
    </Dialog>
  );
};

UserViewDialog.propTypes = {
  user: userType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  onActiveToggled: PropTypes.func.isRequired,
};

export default UserViewDialog;
