import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addEquipmentAction,
  moveEquipmentAction,
  editEquipmentAction,
  deleteEquipmentAction,
} from '../../../state/actionCreators/tmplWizard';

import DragLayer from './DragLayer';
import EquipmentListCard from './EquipmentListCard';
import TemplateCard from './TemplateCard';
import styles from './index.module.scss';


const EquipmentStep = ({
  onSave,
  onNext,
  template,
  addEquipment,
  moveEquipment,
  editEquipment,
  deleteEquipment,
}) => (
  <div className={styles.container}>
    <DragLayer />
    <TemplateCard
      onSave={onSave}
      onNext={onNext}
      template={template}
      addEquipment={addEquipment}
      moveEquipment={moveEquipment}
      editEquipment={editEquipment}
      deleteEquipment={deleteEquipment}
    />
    <EquipmentListCard />
  </div>
);

EquipmentStep.propTypes = {
  onSave: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  template: PropTypes.shape({
    equipments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })),
  }).isRequired,
  addEquipment: PropTypes.func.isRequired,
  moveEquipment: PropTypes.func.isRequired,
  editEquipment: PropTypes.func.isRequired,
  deleteEquipment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  template: state.tmplWizard.template.data,
});

const mapDispatchToProps = (dispatch) => ({
  addEquipment: (index, equipment) => dispatch(addEquipmentAction(index, equipment)),
  moveEquipment: (oldIndex, newIndex, equipment) => dispatch(
    moveEquipmentAction(oldIndex, newIndex, equipment),
  ),
  editEquipment: (index, update) => dispatch(editEquipmentAction(index, update)),
  deleteEquipment: (index) => dispatch(deleteEquipmentAction(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentStep);
