import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';

import PageSelect from '../../widgets/PageSelect';
import styles from './index.module.scss';

const ActionBar = ({
  totals,
  pagination,
  setPagination,
  onCreate,
}) => (
  <div className={styles.actionBar}>
    <Button
      onClick={onCreate}
      icon="add"
      text="Создать пользователя"
      intent={Intent.PRIMARY}
      className={styles.actionBarButton}
    />
    <div className={styles.spacer} />
    <PageSelect
      showTotal
      showItemsPerPageChanger
      total={totals.items}
      currentPage={pagination.currentPage}
      maxPage={totals.pages || pagination.currentPage}
      currentItemsPerPage={pagination.itemsPerPage}
      onPageSelect={
        (currentPage) => { setPagination({ ...pagination, currentPage }); }
      }
      onChangeItemsPerPage={
        (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
      }
    />
  </div>
);

ActionBar.propTypes = {
  onCreate: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    items: PropTypes.number,
    pages: PropTypes.number,
  }).isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default ActionBar;
