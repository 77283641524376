import _ from 'lodash';

export const getHasPreviousMap = (equipment) => {
  const priorities = equipment.children.map((x) => x.priority);
  const hasPreviousMap = priorities.reduce((accumulator, p) => {
    const result = { ...accumulator };
    if (p === 1) {
      result[p] = true;
    } else {
      result[p] = priorities.indexOf(p - 1) !== -1;
    }
    return result;
  }, {});
  return hasPreviousMap;
};

export const isShelfInvalid = (x, counts, hasPreviousMap) => (
  !x.priority
  || counts[x.priority] > 1
  || !hasPreviousMap[x.priority]
);

export const isValidEquipment = (equipment) => {
  const hasPreviousMap = getHasPreviousMap(equipment);
  const counts = _.countBy(equipment.children, 'priority');
  return (
    equipment
    && equipment.name
    && equipment.width
    && equipment.height
    && equipment.children.length
    && _.every(equipment.children, (x) => !isShelfInvalid(x, counts, hasPreviousMap))
  );
};
