import Immutable from 'seamless-immutable';
import * as ACTIONS from '../actions';

const INITIAL_STATE = Immutable({
  fetching: true,
  error: null,
  user: null,
  loggedIn: false,

  loggingIn: true,
  loginError: null,

  loggingOut: false,
  logoutError: null,
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.USER_FETCH_START:
      return state.merge({ fetching: true });
    case ACTIONS.USER_FETCH_DONE:
      return state.merge({
        fetching: false,
        error: null,
        user: action.payload,
        loggedIn: true,
        loggingIn: false,
      });
    case ACTIONS.USER_FETCH_ERROR:
      return state.merge({
        fetching: false,
        user: null,
        loggedIn: false,
        loggingIn: false,
        error: action.error,
      });

    case ACTIONS.LOGIN_START:
      return state.merge({ loggingIn: true, loginError: null });
    case ACTIONS.LOGIN_DONE:
      return state.merge({
        fetching: false,
        error: null,
        user: action.payload,
        loggedIn: true,
        loggingIn: false,
      });
    case ACTIONS.LOGIN_ERROR:
      return state.merge({ loggingIn: false, loginError: action.error });

    case ACTIONS.LOGOUT_START:
      return state.merge({ loggingOut: true, logoutError: null });
    case ACTIONS.LOGOUT_DONE:
      return state.merge({ ...INITIAL_STATE, loggingIn: false, fetching: false });
    case ACTIONS.LOGOUT_ERROR:
      return state.merge({ loggingOut: false, logoutError: action.error });
    default:
      return state;
  }
};

export const selectCanEditTmpl = (state) => !!(state.auth.user && state.auth.user.rights.indexOf('planogram_edit') !== -1);

export default reducer;
