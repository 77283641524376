import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  Button,
  Card,
  Elevation,
  Intent,
  NonIdealState,
  Position,
  Spinner,
  Toaster,
} from '@blueprintjs/core';
import {
  getWarehouse,
  getPlanogramPreview,
  getPlanogramActual,
  downloadPlanogramXlsxPreview,
  downloadPlanogramXlsxActual,
} from '../../api';
import { useRequest } from '../../api/useRequest';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import styles from './index.module.scss';

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => {
  const message = _.get(error, 'response.data.mes', error.message);
  return toaster.show({
    intent: Intent.DANGER,
    message,
    icon: 'error',
  });
};

const getBreadcrumbItems = (warehouse, isPreview) => [
  { text: 'Магазины', href: '/warehouses' },
  { text: warehouse && warehouse.name, href: `/warehouses/${warehouse && warehouse.id}` },
  { text: isPreview ? 'Предпросмотр планограммы' : 'Активная планограмма' },
];

const PlanogramScreen = () => {
  const [{
    fetching: wsFetching,
    data: warehouse,
    error: wsError,
  }, fetchWs] = useRequest(getWarehouse);
  const { tmplWsId, wsId } = useParams();
  const isPreview = !!tmplWsId;
  const [{
    fetching,
    error,
    data,
  }, fetch] = useRequest(isPreview ? getPlanogramPreview : getPlanogramActual);
  useEffect(() => { fetchWs(wsId); }, [fetchWs, wsId]);
  useEffect(() => {
    fetch(isPreview ? tmplWsId : wsId);
  }, [fetch, tmplWsId, wsId, isPreview]);
  const [{
    fetching: downloadingXlsx,
  }, downloadXlsx] = useRequest(
    isPreview ? downloadPlanogramXlsxPreview : downloadPlanogramXlsxActual, {
      onError,
    },
  );
  if ((!data && !error && !wsError) || fetching || wsFetching) {
    return <Spinner />;
  }

  if (error || wsError) {
    const message = _.get(error || wsError, 'response.data.mes', (error || wsError).message);
    return <NonIdealState title="Ошибка" description={message} icon="error" />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <BreadcrumbNav items={getBreadcrumbItems(warehouse, isPreview)} />
        <Card
          className={styles.card}
          elevation={Elevation.TWO}
        >
          <div className={styles.cardHead}>
            <Button
              loading={downloadingXlsx}
              icon="th"
              onClick={() => downloadXlsx(isPreview ? tmplWsId : wsId)}
              intent={Intent.PRIMARY}
            >
              Выгрузить в xlsx
            </Button>
          </div>
          <div
            className={styles.cardBody}
            dangerouslySetInnerHTML={{ __html: data.data }}
          />
        </Card>
      </div>
    </div>
  );
};

export default PlanogramScreen;
