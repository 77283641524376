export const USER_FETCH_START = 'USER_FETCH_START';
export const USER_FETCH_DONE = 'USER_FETCH_DONE';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_DONE = 'LOGOUT_DONE';


export const TEMPLATE_NEW = 'TEMPLATE_NEW';
export const TEMPLATE_FETCH_START = 'TEMPLATE_FETCH_START';
export const TEMPLATE_FETCH_DONE = 'TEMPLATE_FETCH_DONE';
export const TEMPLATE_FETCH_ERROR = 'TEMPLATE_FETCH_ERROR';
export const TEMPLATE_EDIT = 'TEMPLATE_EDIT';
export const TEMPLATE_ADD_EQUIPMENT = 'TEMPLATE_ADD_EQUIPMENT';
export const TEMPLATE_MOVE_EQUIPMENT = 'TEMPLATE_MOVE_EQUIPMENT';
export const TEMPLATE_EDIT_EQUIPMENT = 'TEMPLATE_EDIT_EQUIPMENT';
export const TEMPLATE_DELETE_EQUIPMENT = 'TEMPLATE_DELETE_EQUIPMENT';

export const TEMPLATE_SAVE_START = 'TEMPLATE_SAVE_START';
export const TEMPLATE_SAVE_DONE = 'TEMPLATE_SAVE_DONE';
export const TEMPLATE_SAVE_ERROR = 'TEMPLATE_SAVE_ERROR';

// привязываем дерево к ячейкам
// передаем список ячеек и дерево групп вверх
export const TEMPLATE_BIND_LAYOUT = 'TEMPLATE_BIND_LAYOUT';
// отвязываем от ячеек части дерева
export const TEMPLATE_UNBIND_LAYOUT = 'TEMPLATE_UNBIND_LAYOUT';
export const TEMPLATE_SELECT_AREA = 'TEMPLATE_SELECT_AREA';
export const TEMPLATE_CLEAR_SELECTION = 'TEMPLATE_CLEAR_SELECTION';

export const LAYOUT_TREE_FETCH_START = 'LAYOUT_TREE_FETCH_START';
export const LAYOUT_TREE_FETCH_DONE = 'LAYOUT_TREE_FETCH_DONE';
export const LAYOUT_TREE_FETCH_ERROR = 'LAYOUT_TREE_FETCH_ERROR';
export const LAYOUT_TREE_EXPAND = 'LAYOUT_TREE_EXPAND';
export const LAYOUT_TREE_COLLAPSE = 'LAYOUT_TREE_COLLAPSE';
export const LAYOUT_TREE_UPDATE_NODE = 'LAYOUT_TREE_UPDATE_NODE';
export const LAYOUT_TREE_MOVE_NODE = 'LAYOUT_TREE_MOVE_NODE';

export const LAYOUT_TREE_PRIORITIZE_START = 'LAYOUT_TREE_PRIORITIZE_START';
export const LAYOUT_TREE_PRIORITIZE_DONE = 'LAYOUT_TREE_PRIORITIZE_DONE';
export const LAYOUT_TREE_PRIORITIZE_ERROR = 'LAYOUT_TREE_PRIORITIZE_ERROR';
