import React from 'react';
import { Menu, MenuItem, Intent } from '@blueprintjs/core';

const getContextMenu = ({
  onView,
  onEdit,
  onToggleLock,
}) => ({ item: user }) => (
  <Menu>
    <MenuItem
      text="Просмотр"
      icon="eye-open"
      onClick={() => onView(user)}
    />
    <MenuItem
      text="Изменить"
      icon="edit"
      onClick={() => onEdit(user)}
    />
    <MenuItem
      intent={Intent.DANGER}
      text={user && !user.blocked ? 'Заблокировать' : 'Разблокировать'}
      icon={user && !user.blocked ? 'lock' : 'unlock'}
      onClick={() => onToggleLock(user)}
    />
  </Menu>
);

export default getContextMenu;
