import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  NonIdealState,
  Spinner,
  Toaster,
  Position,
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';
import { getEquipment, postEquipment, putEquipment } from '../../api';
import { useRequest } from '../../api/useRequest';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import { equipmentType } from '../../proptyping';
import DetailsEditCard from './DetailsEditCard';
import EquipmentEdit from './EquipmentEdit';
import EquipmentView from '../EquipmentViewScreen/Equipment';
import styles from './index.module.scss';

const getBreadcrumbItems = (equipment) => [
  { text: 'Оборудование', href: '/equipments' },
  { text: equipment && equipment.name },
];

const toaster = Toaster.create({ position: Position.BOTTOM });

const EquipmentEditContainer = ({ equipment, onEditShelf }) => (
  <div className={styles.equipmentContainer}>
    {
      equipment.templatesCount
        ? <EquipmentView equipment={equipment} />
        : <EquipmentEdit equipment={equipment} onEditShelf={onEditShelf} />
    }
  </div>
);

EquipmentEditContainer.propTypes = {
  equipment: equipmentType.isRequired,
  onEditShelf: PropTypes.func.isRequired,
};

const getOnEditShelf = (stateEquipment, setStateEquipment) => ({
  locationId,
  maxfacex,
  priority,
}) => {
  const newEquipment = _.cloneDeep(stateEquipment);
  stateEquipment.children.forEach((x, i) => {
    const newShelf = newEquipment.children[i];
    if (newShelf.locationId === locationId) {
      newShelf.maxfacex = maxfacex;
      newShelf.priority = priority;
    }
  });
  setStateEquipment(newEquipment);
};

const NEW_EQUIPMENT = {
  id: null,
  name: 'Новое оборудование',
  width: 1075,
  height: 2750,
  children: [
    { locationId: 'new-1', priority: 1, maxfacex: 7 },
    { locationId: 'new-2', priority: 2, maxfacex: 7 },
    { locationId: 'new-3', priority: 3, maxfacex: 7 },
    { locationId: 'new-4', priority: 4, maxfacex: 7 },
    { locationId: 'new-5', priority: 5, maxfacex: 7 },
  ],
  templatesCount: 0,
};

const onSaveSuccess = (history) => () => {
  history.push('/equipments');
  const message = 'Оборудование сохранено';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
};

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const EquipmentViewScreen = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const isNew = id === 'new';
  const isCopy = pathname.endsWith('copy');
  const history = useHistory();
  const [{
    fetching,
    data: equipment,
    error,
  }, fetch] = useRequest(getEquipment);
  const onSuccess = useCallback(onSaveSuccess(history));
  const [{
    fetching: saveFetching,
  }, save] = useRequest(
    (isNew || isCopy) ? postEquipment : putEquipment,
    { onSuccess, onError },
  );
  useEffect(() => {
    if (!isNew) {
      fetch(id);
    }
  }, [fetch, id, isNew]);
  const [stateEquipment, setStateEquipment] = useState(isNew ? NEW_EQUIPMENT : equipment && {
    ...equipment,
    id: isCopy ? null : equipment.id,
  });
  useEffect(() => {
    if (equipment) {
      setStateEquipment(equipment);
    }
  }, [setStateEquipment, equipment]);
  const onCancel = useCallback(() => history.goBack(), [history]);
  const onEdit = useCallback(
    (newEquipment) => setStateEquipment(newEquipment),
    [setStateEquipment],
  );
  const onEditShelf = useCallback(
    getOnEditShelf(stateEquipment, setStateEquipment),
    [stateEquipment, setStateEquipment],
  );

  if (fetching || saveFetching || (!stateEquipment && !error)) {
    return <Spinner />;
  }
  if (error) {
    return <NonIdealState title="Ошибка" description={error.message} icon="error" />;
  }
  return (
    <Page>
      <BreadcrumbNav items={getBreadcrumbItems(stateEquipment)} />
      <div className={styles.container}>
        <EquipmentEditContainer equipment={stateEquipment} onEditShelf={onEditShelf} />
        <DetailsEditCard
          equipment={stateEquipment}
          onSave={() => {
            if (isNew || isCopy) {
              save(stateEquipment);
            } else {
              save(stateEquipment.id, stateEquipment);
            }
          }}
          onCancel={onCancel}
          onEdit={onEdit}
        />
      </div>
    </Page>
  );
};

export default EquipmentViewScreen;
