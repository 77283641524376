import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

const getContextMenu = ({ history }) => ({ item }) => (
  <Menu>
    <MenuItem
      text="Просмотр"
      icon="eye-open"
      onClick={() => history.push(`/warehouses/${item.id}`)}
    />
  </Menu>
);

export default getContextMenu;
