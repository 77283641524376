import React from 'react';
import {
  Label,
  TagInput,
  Button,
  H3,
  Card,
  Intent,
  Spinner,
  Elevation,
  Toaster,
  Position,
  Icon,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { uploadWarehousesCodesFile } from '../../api';
import { useRequest } from '../../api/useRequest';
import styles from './AddRefsCard.module.scss';

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const UploadButton = ({ uploadFile, uploading }) => (
  <label
    className={(
      uploading
        ? 'bp3-button bp3-minimal bp3-disabled bp3-intent-primary'
        : 'bp3-button bp3-minimal bp3-intent-primary'
    )}
    htmlFor="file-input"
  >
    <input
      id="file-input"
      type="file"
      accept="text/plain"
      className={styles.fileInput}
      onChange={(e) => {
        const { files } = e.target;
        if (files.length === 0) {
          return;
        }
        const [file] = files;
        uploadFile(file);
      }}
      disabled={uploading}
    />
    { uploading ? <Spinner size={Spinner.SIZE_SMALL} intent={Intent.PRIMARY} /> : <Icon icon="document" /> }
    <span className="bp3-button-text">Выбрать из файла</span>
  </label>
);

UploadButton.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
};

const AddRefsCard = ({
  templateName,
  fetching,
  selected,
  setSelected,
  onSaveRefs,
}) => {
  const notSelected = selected.length === 0;
  const [{ fetching: uploading }, uploadFile] = useRequest(uploadWarehousesCodesFile, {
    onError,
    onSuccess: (resp) => {
      const { data } = resp;
      const toAdd = data.filter((x) => _.findIndex(selected, ['id', x.id]) === -1);
      setSelected([...selected, ...toAdd]);
    },
  });
  if (fetching) {
    return <Card className={styles.addCard} elevation={Elevation.ONE}><Spinner /></Card>;
  }
  return (
    <Card className={styles.addCard} elevation={Elevation.ONE}>
      <H3>
        {'Привязка магазинов к шаблону - '}
        {templateName}
      </H3>
      <Label className={styles.selectedLabel}>Выбранные магазины: </Label>
      <TagInput
        className={styles.selected}
        placeholder="Выбранные магазины"
        values={selected.map((x) => x.name)}
        leftIcon="shop"
        inputValue=""
        inputProps={{
          readOnly: true,
        }}
        onRemove={(val, index) => setSelected(_.without(selected, selected[index]))}
        rightElement={selected.length ? (
          <Button
            minimal
            icon="cross"
            onClick={() => setSelected([])}
          />
        ) : null}
      />
      <Button
        className={styles.button}
        icon="tick"
        intent={Intent.PRIMARY}
        disabled={notSelected}
        onClick={onSaveRefs}
      >
        Привязать выбранные
        { selected.length > 0 ? ` (${selected.length})` : '' }
      </Button>
      <UploadButton uploadFile={uploadFile} uploading={uploading} />
    </Card>
  );
};

AddRefsCard.propTypes = {
  templateName: PropTypes.string,
  fetching: PropTypes.bool.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  setSelected: PropTypes.func.isRequired,
  onSaveRefs: PropTypes.func.isRequired,
};

AddRefsCard.defaultProps = {
  templateName: '',
};

export default AddRefsCard;
