import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  NonIdealState,
  Toaster,
  Position,
  Intent,
} from '@blueprintjs/core';
import { getPlanogramTmplList, copyPlanogramTmpl } from '../../api';
import { useTableRequest } from '../../api/useTableRequest';
import { useRequest } from '../../api/useRequest';
import { selectCanEditTmpl } from '../../state/reducers/auth';
import ActionBar from './ActionBar';
import getContextMenu from './ContextMenu';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import SearchInput from '../../widgets/Filters/SearchInput';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';


const BREADCRUMB_ITEMS = [
  { text: 'Шаблоны планограмм' },
];

const FIELDS = [
  {
    title: 'Название',
    field: 'name',
    sortable: true,
    width: 200,
  },
  {
    title: 'Описание шаблона',
    field: 'description',
    sortable: true,
    width: 350,
  },
  {
    title: 'Количество витрин',
    field: 'equipCount',
    sortable: true,
    width: 180,
  },
  {
    title: 'Количество ОП',
    field: 'wsCount',
    sortable: true,
    width: 180,
  },
  {
    title: 'Автор',
    field: 'authorFullname',
    sortable: true,
    width: 220,
  },
  {
    title: 'Последнее изменение',
    field: 'lastTime',
    valueRenderer: (value) => (value ? moment(value).format('DD.MM.YYYY HH:mm') : null),
    sortable: true,
    width: 220,
  },
  {
    title: 'Изменивший пользователь ',
    field: 'lastEditorFullname',
    sortable: true,
    width: 220,
  },
  {
    title: 'Статус ',
    field: 'draft',
    valueRenderer: (value) => (value ? 'Черновик' : 'Готов'),
    sortable: true,
  },
];

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const PlanogramTmplListScreen = () => {
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination, setSort] = useTableRequest(getPlanogramTmplList, {
    initialState: {
      sort: {
        field: 'name',
        direction: 'asc',
      },
    },
  });
  useEffect(() => { fetch({ filters, pagination, sort }); }, [fetch, filters, pagination, sort]);
  const history = useHistory();
  const canEdit = useSelector(selectCanEditTmpl);
  const [{ fetching: copying }, onCopy] = useRequest(copyPlanogramTmpl, {
    onError,
    onSuccess: (copyId) => history.push(`/templates/${copyId}/edit`),
  });
  return (
    <Page wide>
      <BreadcrumbNav items={BREADCRUMB_ITEMS} />
      <SearchInput
        name="Поиск..."
        value={filters.search}
        onChange={(search) => setFilters({ ...filters, search })}
      />
      <ActionBar
        canEdit={canEdit}
        history={history}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
      />
      {
        error
          ? <NonIdealState title="Ошибка" description={error.message} icon="error" />
          : (
            <DataTable
              columns={getColumns(FIELDS, sort, setSort)}
              data={data}
              loading={fetching || copying}
              loadingRowCount={pagination.itemsPerPage}
              contextMenu={getContextMenu({ history, canEdit, onCopy })}
              currentPage={pagination.currentPage}
              itemsPerPage={pagination.itemsPerPage}
              onDoubleClick={({ item }) => {
                history.push(`/templates/${item.id}`);
              }}
            />
          )
      }
    </Page>
  );
};

export default PlanogramTmplListScreen;
