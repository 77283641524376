import { getUser, postLogin, postLogout } from '../../api';
import * as ACTIONS from '../actions';

export const fetchUserAction = () => async (dispatch) => {
  dispatch({ type: ACTIONS.USER_FETCH_START });
  try {
    const user = await getUser();
    dispatch({ type: ACTIONS.USER_FETCH_DONE, payload: user });
  } catch (error) {
    dispatch({ type: ACTIONS.USER_FETCH_ERROR, error });
  }
};

export const loginAction = ({ login, password }, { onSuccess, onError }) => async (dispatch) => {
  dispatch({ type: ACTIONS.LOGIN_START });
  try {
    const user = await postLogin({ login, password });
    dispatch({ type: ACTIONS.LOGIN_DONE, payload: user });
    onSuccess();
  } catch (error) {
    dispatch({ type: ACTIONS.LOGIN_ERROR, error });
    onError(error);
  }
};

export const logoutAction = () => async (dispatch) => {
  dispatch({ type: ACTIONS.LOGOUT_START });
  try {
    await postLogout();
    dispatch({ type: ACTIONS.LOGOUT_DONE });
  } catch (error) {
    dispatch({ type: ACTIONS.LOGOUT_ERROR, error });
  }
};
