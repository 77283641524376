import _ from 'lodash';

const RX_DATETIME = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/;
const RX_FILTER = /_(from|to|eq|like|in)$/;

const formatValue = (value) => {
  if (Array.isArray(value)) {
    return value.join();
  }
  return value;
};

const filterSnakeCase = (val) => _.snakeCase(val).replace(RX_FILTER, '__$1');

export const buildQueryParams = ({ filters, pagination, sort }) => {
  const params = Object.entries(filters || {})
    .reduce((dst, [key, value]) => ({
      ...dst,
      [`filter[${filterSnakeCase(key)}]`]: formatValue(value),
    }), { });
  if (pagination) {
    params['page[size]'] = pagination.itemsPerPage;
    params['page[number]'] = pagination.currentPage && pagination.currentPage - 1;
  }
  if (sort) {
    const direction = sort.direction === 'desc' ? '-' : '';
    params.sort = `${direction}${_.snakeCase(sort.field)}`;
  }
  return params;
};

export const transformRequest = (data) => {
  if (Array.isArray(data)) {
    return data.map(transformRequest);
  }
  if (_.isPlainObject(data)) {
    return _.mapValues(_.mapKeys(data, (value, key) => filterSnakeCase(key)), transformRequest);
  }
  return data;
};

const transformData = (data) => {
  if (_.isString(data) && data.match(RX_DATETIME)) {
    return new Date(data);
  }
  if (Array.isArray(data)) {
    return data.map(transformData);
  }
  if (_.isPlainObject(data)) {
    return _.mapValues(_.mapKeys(data, (value, key) => _.camelCase(key)), transformData);
  }
  return data;
};

const extractError = (response) => {
  const { data } = response;
  if (data.mes) {
    const error = new Error(data.mes);
    error.data = transformData(data);
    return error;
  }
  return null;
};

export const successInterceptor = (response) => transformData(response.data);

export const errorInterceptor = (error) => {
  if (error.response) {
    const serverError = extractError(error.response);
    return Promise.reject(serverError || error);
  }
  return Promise.reject(error);
};

export const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
