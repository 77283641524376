import {
  getPlanogramTmpl,
  putPlanogramTmpl,
  postPlanogramTmpl,
} from '../../api';
import * as ACTIONS from '../actions';

export const fetchTemplateAction = (id) => async (dispatch) => {
  dispatch({ type: ACTIONS.TEMPLATE_FETCH_START });
  try {
    const tmpl = await getPlanogramTmpl(id);
    dispatch({ type: ACTIONS.TEMPLATE_FETCH_DONE, payload: tmpl });
  } catch (error) {
    dispatch({ type: ACTIONS.TEMPLATE_FETCH_ERROR, error });
  }
};

export const saveTemplateAction = (tmpl, onSuccess, onError) => async (dispatch) => {
  const {
    typeId,
    name,
    description,
    draft,
    draftStep,
    layouts,
  } = tmpl;
  const equipments = tmpl.equipments.map((e) => ({
    equipmentId: e.id,
    title: e.title,
    tmplEquipRefId: e.tmplEquipRefId,
  }));
  const templateData = {
    typeId,
    name,
    description,
    draft,
    draftStep,
    equipments,
    layouts,
  };
  dispatch({ type: ACTIONS.TEMPLATE_SAVE_START });
  try {
    const newTmpl = (
      tmpl.id
        ? await putPlanogramTmpl(tmpl.id, templateData)
        : await postPlanogramTmpl(templateData)
    );
    dispatch({ type: ACTIONS.TEMPLATE_SAVE_DONE, payload: newTmpl });
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    dispatch({ type: ACTIONS.TEMPLATE_SAVE_ERROR, error });
    if (onError) {
      onError(error);
    }
  }
};

export const newTemplateAction = () => ({ type: ACTIONS.TEMPLATE_NEW });

export const updateTemplateAction = (update) => ({ type: ACTIONS.TEMPLATE_EDIT, payload: update });

export const addEquipmentAction = (index, equipment) => (
  { type: ACTIONS.TEMPLATE_ADD_EQUIPMENT, payload: { index, equipment } }
);

export const moveEquipmentAction = (oldIndex, newIndex, equipment) => (
  { type: ACTIONS.TEMPLATE_MOVE_EQUIPMENT, payload: { oldIndex, newIndex, equipment } }
);

export const editEquipmentAction = (index, update) => (
  { type: ACTIONS.TEMPLATE_EDIT_EQUIPMENT, payload: { index, update } }
);

export const deleteEquipmentAction = (index) => (
  { type: ACTIONS.TEMPLATE_DELETE_EQUIPMENT, payload: { index } }
);

export const bindLayoutAction = ({
  tmplEquipRefId,
  locationId,
  cells,
  group,
  parents,
}) => ({
  type: ACTIONS.TEMPLATE_BIND_LAYOUT,
  payload: {
    tmplEquipRefId,
    locationId,
    cells,
    group,
    parents,
  },
});

export const unbindLayoutAction = (layout) => ({
  type: ACTIONS.TEMPLATE_UNBIND_LAYOUT,
  payload: layout,
});

export const selectAreaAction = (area) => ({
  type: ACTIONS.TEMPLATE_SELECT_AREA,
  payload: area,
});

export const clearSelectionAction = () => ({
  type: ACTIONS.TEMPLATE_CLEAR_SELECTION,
});
