import React, { PureComponent } from 'react';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import SimpleSelect from '../SimpleSelect';
import styles from './PageSelect.module.css';

function createIntRange(minInclusive, maxInclusive) {
  const array = [];
  for (let i = minInclusive; i <= maxInclusive; i += 1) {
    array.push(i);
  }
  return array;
}

const transformItemsPerPage = (perPage) => ({
  key: perPage,
  value: perPage,
  title: `${perPage}`,
});

export default class PageSelect extends PureComponent {
  onPageClick(pageNumber) {
    const { onPageSelect } = this.props;
    onPageSelect(pageNumber);
  }

  renderPageButton(page) {
    const { currentPage } = this.props;
    const isActive = page === currentPage;
    return (
      <Button
        key={page}
        onClick={() => this.onPageClick(page)}
        intent={isActive ? Intent.PRIMARY : null}
      >
        {page.toString()}
      </Button>
    );
  }

  renderItemsPerPageChanger() {
    const {
      onChangeItemsPerPage,
      itemsPerPageItems,
      currentItemsPerPage,
    } = this.props;
    return (
      <>
        <div className={styles.itemsPerPageLabel}>
          Показывать на странице:
        </div>
        <SimpleSelect
          items={itemsPerPageItems.map(transformItemsPerPage)}
          value={currentItemsPerPage}
          onChange={onChangeItemsPerPage}
        />
        <div className={styles.spacer} />
      </>
    );
  }

  renderTotal() {
    const { totalLabel, total } = this.props;
    return (
      <div className={styles.total}>
        {totalLabel}
        {' '}
        {total}
      </div>
    );
  }

  render() {
    const {
      minPage,
      maxPage,
      currentPage,
      numPagesAround,
      showItemsPerPageChanger,
      showTotal,
    } = this.props;
    const isFirstPage = currentPage === minPage;
    const isLastPage = currentPage === maxPage;

    const minPageAtLeftFromCurrent = Math.max(minPage + 1, currentPage - numPagesAround);
    const maxPageRightFromCurrent = Math.min(maxPage - 1, currentPage + numPagesAround);
    const pagesRange = createIntRange(minPageAtLeftFromCurrent, maxPageRightFromCurrent);

    const showEllipsisLeft = minPageAtLeftFromCurrent > minPage + 1;
    const showEllipsisRight = maxPageRightFromCurrent < maxPage - 1;

    return (
      <div className={styles.wrapper}>
        {showTotal && this.renderTotal()}
        {showItemsPerPageChanger && this.renderItemsPerPageChanger()}
        <ButtonGroup>
          <Button onClick={() => this.onPageClick(currentPage - 1)} icon="arrow-left" disabled={isFirstPage} />
          {this.renderPageButton(minPage)}
          {showEllipsisLeft && <Button disabled>...</Button>}
          {pagesRange.map((page) => this.renderPageButton(page))}
          {showEllipsisRight && <Button disabled>...</Button>}
          {minPage !== maxPage && this.renderPageButton(maxPage)}
          <Button onClick={() => this.onPageClick(currentPage + 1)} icon="arrow-right" disabled={isLastPage} />
        </ButtonGroup>
      </div>
    );
  }
}

PageSelect.propTypes = {
  minPage: PropTypes.number,
  maxPage: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  numPagesAround: PropTypes.number,
  onPageSelect: PropTypes.func,
  onChangeItemsPerPage: PropTypes.func,
  currentItemsPerPage: PropTypes.number,
  itemsPerPageItems: PropTypes.arrayOf(PropTypes.number),
  showItemsPerPageChanger: PropTypes.bool,
  total: PropTypes.number,
  showTotal: PropTypes.bool,
  totalLabel: PropTypes.string,
};

PageSelect.defaultProps = {
  minPage: 1,
  numPagesAround: 1,
  maxPage: 1,
  onPageSelect: () => {},
  onChangeItemsPerPage: () => {},
  currentItemsPerPage: 20,
  showItemsPerPageChanger: false,
  itemsPerPageItems: [20, 50, 100],
  total: 1,
  showTotal: false,
  totalLabel: 'Всего:',
};
