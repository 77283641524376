import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import routes, { canVisit } from './routes';
import NavBar from './NavBar/NavBar';
import { fetchUserAction } from '../state/actionCreators/auth';
import { userType } from '../proptyping';
import styles from './RootContainer.module.scss';

const Footer = () => (
  <div className={styles.footer}>
    МФРmerch 2019
  </div>
);

const RootContainer = ({
  getUser,
  loggedIn,
  loggingIn,
  user,
  fetching,
}) => {
  const showNavigation = loggedIn && !loggingIn;
  useEffect(() => { getUser(); }, [getUser]);

  return (
    <Router>
      <NavBar routes={routes} showNavigation={showNavigation} user={user} />
      <div className={styles.content}>
        {
          fetching
            ? <Spinner />
            : routes.map((route) => {
              if (!loggedIn && !loggingIn && route.path !== '/login') {
                return <Redirect key={route.path} to="/login" />;
              }
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => {
                    if (route.redirect) {
                      return <Redirect to={route.redirect} />;
                    }
                    if (!canVisit(user, route)) {
                      return <Redirect to="/" />;
                    }
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    return <route.component {...props} />;
                  }}
                />
              );
            })
        }
      </div>
      <Footer />
    </Router>
  );
};

RootContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  user: userType,
  getUser: PropTypes.func.isRequired,
};

RootContainer.defaultProps = {
  user: null,
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(fetchUserAction()),
});

const mapStateToProps = (state) => ({
  fetching: state.auth.fetching,
  loggedIn: state.auth.loggedIn,
  loggingIn: state.auth.loggingIn,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
