import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  H3,
  Button,
  Card,
  Elevation,
  Intent,
} from '@blueprintjs/core';
import {
  selectAreaAction,
  clearSelectionAction,
  bindLayoutAction,
} from '../../../state/actionCreators/tmplWizard';
import { isComplete } from '../../../state/reducers/tmplWizard/utils';
import Selection from '../../../widgets/ReactSelection';
import Equipment from './Equipment';
import { afterSelect, SelectionArea } from './SelectionArea';
import styles from './LayoutCard.module.scss';


const LayoutCard = ({
  template,
  onSave,
  onFinish,
  area,
  selectArea,
  clearSelection,
  bindLayout,
}) => {
  const invalid = !isComplete(template);
  const history = useHistory();
  const onClose = () => history.push('/templates');
  return (
    <Card className={styles.layoutCard} elevation={Elevation.TWO}>
      <H3>
        {template.name}
      </H3>
      <Selection
        className={styles.layoutWrapper}
        target=".selection-target"
        selectedClass="selection-target-selected"
        beforeSelect={clearSelection}
        afterSelect={(selectedTargets, boxRect, scrollOffset) => afterSelect(
          selectedTargets,
          boxRect,
          scrollOffset,
          selectArea,
        )}
      >
        {template.equipments.map((e, index) => (
          /* eslint-disable react/no-array-index-key */
          <Equipment
            key={`${e.id}-${e.title}-${index}`}
            index={index}
            equipment={e}
            layouts={template.layouts.filter((l) => l.tmplEquipRefId === e.tmplEquipRefId)}
            selectedCells={area.cells.filter((c) => c.tmplEquipRefId === e.tmplEquipRefId)}
          />
          /* eslint-enable react/no-array-index-key */
        ))}
        { area.cells.length ? (
          <SelectionArea
            area={area}
            clearSelection={clearSelection}
            bindLayout={bindLayout}
          />
        ) : null}
      </Selection>
      <div className={styles.buttonRow}>
        <Button icon="caret-left" onClick={onClose}>К списку</Button>
        <Button
          icon="floppy-disk"
          onClick={onSave}
        >
          Сохранить
        </Button>
        <Button
          disabled={invalid}
          icon="tick"
          onClick={onFinish}
          intent={Intent.SUCCESS}
        >
          Готово
        </Button>
      </div>
    </Card>
  );
};

LayoutCard.propTypes = {
  template: PropTypes.shape({
    equipments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      tmplEquipRefId: PropTypes.number,
    })),
    layouts: PropTypes.arrayOf(PropTypes.shape({
      tmplEquipRefId: PropTypes.number,
    })),
    name: PropTypes.string,
  }).isRequired,
  area: PropTypes.shape({
    cells: PropTypes.arrayOf(PropTypes.shape({
      cell: PropTypes.number,
      tmplEquipRefId: PropTypes.number,
      locationId: PropTypes.number,
    })),
    bounds: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  selectArea: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
  bindLayout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  area: state.tmplWizard.selection.area,
});

const mapDispatchToProps = (dispatch) => ({
  selectArea: (area) => dispatch(selectAreaAction(area)),
  clearSelection: () => dispatch(clearSelectionAction()),
  bindLayout: ({
    tmplEquipRefId,
    locationId,
    cells,
    group,
    parents,
  }) => dispatch(bindLayoutAction({
    tmplEquipRefId,
    locationId,
    cells,
    group,
    parents,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutCard);
