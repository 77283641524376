import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  NonIdealState,
  Spinner,
  Intent,
  Toaster,
  Position,
} from '@blueprintjs/core';
import { getEquipment, delEquipment } from '../../api';
import { useRequest } from '../../api/useRequest';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import { equipmentType } from '../../proptyping';
import DetailsCard from './DetailsCard';
import Equipment from './Equipment';
import TemplateTable from './TemplateTable';
import styles from './index.module.scss';

const getBreadcrumbItems = (equipment) => [
  { text: 'Оборудование', href: '/equipments' },
  { text: equipment && equipment.name },
];

const EquipmentContainer = ({ equipment }) => (
  <div className={styles.equipmentContainer}>
    <Equipment equipment={equipment} />
  </div>
);

EquipmentContainer.propTypes = {
  equipment: equipmentType.isRequired,
};

const toaster = Toaster.create({ position: Position.BOTTOM });

const onDeleteSuccess = (history) => () => {
  history.push('/equipments');
  const message = 'Оборудование удалено';
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
};

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const EquipmentViewScreen = () => {
  const { id } = useParams();
  const history = useHistory();
  const onEdit = useCallback(() => history.push(`/equipments/${id}/edit`), [history, id]);
  const [{
    fetching,
    data: equipment,
    error,
  }, fetch] = useRequest(getEquipment);
  const [{ fetching: deleteFetching }, onDelete] = useRequest(
    delEquipment, {
      onSuccess: onDeleteSuccess(history),
      onError,
    },
  );
  useEffect(() => { fetch(id); }, [fetch, id]);
  if (fetching || deleteFetching || (!equipment && !error)) {
    return <Spinner />;
  }
  if (error) {
    return <NonIdealState title="Ошибка" description={error.message} icon="error" />;
  }
  return (
    <Page>
      <BreadcrumbNav items={getBreadcrumbItems(equipment)} />
      <div className={styles.container}>
        <EquipmentContainer equipment={equipment} />
        <DetailsCard equipment={equipment} onEdit={onEdit} onDelete={onDelete} />
      </div>
      <TemplateTable />
    </Page>
  );
};

export default EquipmentViewScreen;
