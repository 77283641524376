import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';

import PageSelect from '../../widgets/PageSelect';
import styles from './index.module.css';

const ActionBar = ({
  canEdit,
  totals,
  pagination,
  setPagination,
}) => {
  const history = useHistory();
  return (
    <div className={styles.actionBar}>
      { canEdit ? (
        <Button
          onClick={() => history.push('/equipments/new/edit')}
          icon="add"
          text="Создать оборудование"
          intent={Intent.PRIMARY}
          className={styles.actionBarButton}
        />
      ) : null }
      <div className={styles.spacer} />
      <PageSelect
        showTotal
        showItemsPerPageChanger
        total={totals.items}
        currentPage={pagination.currentPage}
        maxPage={totals.pages || pagination.currentPage}
        currentItemsPerPage={pagination.itemsPerPage}
        onPageSelect={
          (currentPage) => { setPagination({ ...pagination, currentPage }); }
        }
        onChangeItemsPerPage={
          (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
        }
      />
    </div>
  );
};

ActionBar.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  totals: PropTypes.shape({
    items: PropTypes.number,
    pages: PropTypes.number,
  }).isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default ActionBar;
