import Immutable from 'seamless-immutable';
import * as ACTIONS from '../../actions';

const INITIAL_STATE = Immutable({
  area: {
    bounds: {
      left: undefined,
      top: undefined,
      width: undefined,
      height: undefined,
    },
    cells: [],
  },
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.TEMPLATE_SELECT_AREA:
      return INITIAL_STATE.merge({ area: action.payload });
    case ACTIONS.TEMPLATE_CLEAR_SELECTION:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
