import { combineReducers } from 'redux';
import auth from './auth';
import tmplWizard from './tmplWizard';
import layoutTree from './layoutTree';


export default combineReducers({
  auth,
  tmplWizard,
  layoutTree,
});
