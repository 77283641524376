import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';
import styles from './index.module.scss';

const Step = ({
  title,
  subtitle,
  active,
  done,
  disabled,
  onClick,
}) => {
  let className = classNames(styles.step);
  let icon = null;
  if (active) {
    icon = <Icon className={styles.icon} icon="selection" />;
    className = classNames(styles.step, styles.active);
  }
  if (done) {
    icon = <Icon className={styles.icon} icon="tick-circle" />;
    className = classNames(styles.step, styles.done);
  }
  if (disabled) {
    className = classNames(styles.step, styles.disabled);
  }
  return (
    <button type="button" className={className} onClick={onClick} disabled={disabled}>
      { icon }
      <span className={styles.stepTitleWrapper}>
        <div className={styles.stepTitle}>{title}</div>
        <div className={styles.stepSubTitle}>{subtitle}</div>
      </span>
    </button>
  );
};

Step.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  active: PropTypes.bool,
  done: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Step.defaultProps = {
  title: '',
  subtitle: '',
  active: false,
  done: false,
  disabled: false,
};

const Steps = ({ steps, activeIndex, onStepClick }) => (
  <div className={classNames(styles.stepGroup, 'bp3-elevation-2')}>
    <div className={styles.wrapper}>
      { steps.map((x, i) => (
        <Step
          /* eslint-disable-next-line react/no-array-index-key */
          key={`${x.title}-${i}`}
          title={x.title}
          subtitle={x.subtitle}
          done={i < activeIndex}
          active={i === activeIndex}
          disabled={x.disabled}
          onClick={() => onStepClick && onStepClick(x, i)}
        />
      ))}
    </div>
  </div>
);

Steps.propTypes = {
  activeIndex: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  onStepClick: PropTypes.func.isRequired,
};

Steps.defaultProps = {
  activeIndex: 0,
};

export default Steps;
