import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {
  Button,
  H3,
  NonIdealState,
  Intent,
  Spinner,
  Toaster,
  Position,
  Checkbox,
} from '@blueprintjs/core';
import SearchInput from '../../widgets/Filters/SearchInput';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';
import PageSelect from '../../widgets/PageSelect';
import {
  getTemplatesWarehouseList,
  delTmplWsRef,
  activateTmplWsRef,
  deactivateTmplWsRef,
  delTmplWsRefList,
  activateTmplWsRefList,
  deactivateTmplWsRefList,
} from '../../api';
import { useTableRequest } from '../../api/useTableRequest';
import { useRequest } from '../../api/useRequest';
import { selectCanEditTmpl } from '../../state/reducers/auth';
import DeleteRefsAlert from './DeleteRefsAlert';
import ActivateConflictDialog from './ActivateConflictDialog';
import styles from './WarehouseTable.module.scss';

const valueRenderer = (x) => <div className={styles.wsCell}>{x}</div>;

const isActiveRenderer = (x) => <div className={styles.wsCell}>{x ? 'Активно' : 'Неактивно'}</div>;

const calcRenderer = (
  history,
  onDelete,
  onActivateRef,
  onDeactivateRef,
  id,
  data,
) => (
  <div className={styles.buttonCell}>
    <Button
      className={styles.buttonCellButton}
      small
      intent={Intent.DANGER}
      icon="trash"
      onClick={() => onDelete(id)}
    >
      Удалить
    </Button>
    {data.isActive ? (
      <Button
        className={styles.togleActiveBullon}
        small
        minimal
        intent={Intent.DANGER}
        icon="pause"
        onClick={() => onDeactivateRef(id)}
      >
        Деактивировать
      </Button>
    ) : (
      <Button
        className={styles.togleActiveBullon}
        small
        minimal
        intent={Intent.SUCCESS}
        icon="play"
        onClick={() => onActivateRef(id)}
      >
        Активировать
      </Button>
    )}
    <Button
      className={styles.buttonCellButton}
      small
      intent={Intent.PRIMARY}
      icon="play"
      onClick={() => history.push(`/warehouses/${data.wsId}/planogram/${id}/preview`)}
    >
      Рассчитать
    </Button>
  </div>
);

const checkboxRenderer = (id, data, selected, setSelected) => {
  const selectedItem = _.find(selected, ['id', id]);
  const checked = !!selectedItem;
  return (
    <div className={styles.wsCell}>
      <Checkbox
        checked={checked}
        onChange={() => {
          if (checked) {
            setSelected(_.without(selected, selectedItem));
          } else {
            setSelected([...selected, data]);
          }
        }}
      />
    </div>
  );
};

const getFields = (
  history,
  onDelete,
  onActivateRef,
  onDeactivateRef,
  canEdit,
  fetching,
  pageChecked,
  pageIntermidiate,
  onTogglePageSelection,
  selected,
  setSelected,
) => {
  const fields = [
    {
      title: 'Название',
      field: 'wsName',
      sortable: true,
      width: 250,
      valueRenderer,
    },
    {
      title: 'Код',
      field: 'wsCode',
      sortable: true,
      width: 100,
      valueRenderer,
    },
    {
      title: 'Адрес',
      field: 'wsAddress',
      sortable: true,
      width: 340,
      valueRenderer,
    },
    {
      title: 'Статус',
      field: 'wsStatus',
      sortable: true,
      width: 150,
      valueRenderer,
    },
    {
      title: 'Активность привязки',
      field: 'isActive',
      sortable: true,
      width: 200,
      valueRenderer: isActiveRenderer,
    },
  ];
  if (canEdit) {
    fields.unshift({
      title: fetching ? '' : (
        <Checkbox
          className={classNames(styles.selectAll, 'bp3-table-reorder-handle-target')}
          checked={pageChecked}
          indeterminate={pageIntermidiate}
          onChange={() => onTogglePageSelection(pageChecked, pageIntermidiate)}
        />
      ),
      field: 'id',
      width: 40,
      sortable: false,
      valueRenderer: (id, data) => checkboxRenderer(id, data, selected, setSelected),
    });
    fields.push({
      title: '',
      field: 'id',
      sortable: false,
      width: 385,
      valueRenderer: (id, data) => calcRenderer(
        history,
        onDelete,
        onActivateRef,
        onDeactivateRef,
        id,
        data,
      ),
    });
  }
  return fields;
};

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const getOnSuccess = (reload, message) => () => {
  reload();
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
};

const onTogglePageSelection = (data, selected, setSelected) => (checked, intermidiate) => {
  if (!checked && !intermidiate) {
    setSelected([...selected, ...data]);
  } else if (checked) {
    const filtered = selected.filter((x) => _.findIndex(data, ['id', x.id]) === -1);
    setSelected(filtered);
  } else if (intermidiate) {
    const toAdd = data.filter((x) => _.findIndex(selected, ['id', x.id]) === -1);
    setSelected([...selected, ...toAdd]);
  }
};

const getOnOverwrite = (
  deactivateRefList,
  activateConflictList,
  activateRefList,
  selected,
  setActivateConflictOpen,
) => async () => {
  setActivateConflictOpen(false);
  await deactivateRefList(activateConflictList.map((x) => x.id));
  activateRefList(selected.map((x) => x.id));
};

const WarehouseTable = ({ checkActiveWs }) => {
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination, setSort] = useTableRequest(getTemplatesWarehouseList);
  const { id } = useParams();
  const history = useHistory();
  const canEdit = useSelector(selectCanEditTmpl);
  const reload = () => {
    checkActiveWs(id);
    fetch({ filters, pagination, sort });
  };
  const [selected, setSelected] = useState([]);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [activateConflictOpen, setActivateConflictOpen] = useState(false);
  const [activateConflictList, setActivateConflictList] = useState([]);
  useEffect(() => {
    if (data) {
      setSelected([]);
    }
  }, [data]);

  const [{
    fetching: deleting,
  }, deleteRef] = useRequest(delTmplWsRef, {
    onSuccess: getOnSuccess(reload, 'Привязка удалена'),
    onError,
  });
  const [{
    fetching: activating,
  }, activateRef] = useRequest(activateTmplWsRef, {
    onSuccess: getOnSuccess(reload, 'Привязка активирована'),
    onError,
  });
  const [{
    fetching: deactivating,
  }, deactivateRef] = useRequest(deactivateTmplWsRef, {
    onSuccess: getOnSuccess(reload, 'Привязка деактивирована'),
    onError,
  });

  const [{
    fetching: deletingList,
  }, deleteRefList] = useRequest(delTmplWsRefList, {
    onSuccess: () => {
      if (pagination.currentPage === 1) {
        (getOnSuccess(reload, 'Привязкы удалены'))();
      } else {
        setPagination({ ...pagination, currentPage: 1 });
        checkActiveWs(id);
        (getOnSuccess(() => {}, 'Привязкы удалены'))();
      }
      setSelected([]);
    },
    onError,
  });
  const [{
    fetching: activatingList,
  }, activateRefList] = useRequest(activateTmplWsRefList, {
    onSuccess: getOnSuccess(reload, 'Привязки активированы'),
    onError: (err) => {
      if (_.get(err, 'data.detail.length')) {
        setActivateConflictOpen(true);
        setActivateConflictList(err.data.detail);
      } else {
        onError(err);
      }
    },
  });
  const [{
    fetching: deactivatingList,
  }, deactivateRefList] = useRequest(deactivateTmplWsRefList, {
    onSuccess: getOnSuccess(reload, 'Привязки деактивированы'),
    onError,
  });

  const loading = (
    fetching
    || deleting
    || activating
    || deactivating
    || deletingList
    || activatingList
    || deactivatingList
  );

  const selectedIds = selected.map((x) => x.id);
  const pageSelected = data ? data.filter((x) => selectedIds.indexOf(x.id) !== -1) : [];
  const pageIntermidiate = data && pageSelected.length && pageSelected.length < data.length;
  const pageChecked = data && pageSelected.length && pageSelected.length === data.length;
  const allActive = _.every(selected, (x) => x.isActive);
  const allInactive = _.every(selected, (x) => !x.isActive);

  useEffect(() => {
    if (!filters.templateIdEq) {
      setFilters({ templateIdEq: id });
    } else {
      fetch({ filters, pagination, sort });
    }
  }, [fetch, setFilters, id, filters, pagination, sort]);
  const columns = getColumns(getFields(
    history,
    deleteRef,
    activateRef,
    deactivateRef,
    canEdit,
    fetching,
    pageChecked,
    pageIntermidiate,
    onTogglePageSelection(data, selected, setSelected),
    selected,
    setSelected,
  ), sort, setSort);

  return (
    <div className={styles.container}>
      <H3>Привязанные магазины</H3>
      <SearchInput
        name="Поиск..."
        value={filters.search}
        onChange={(search) => setFilters({ ...filters, search })}
      />
      <div className={styles.actionBar}>
        { canEdit ? (
          <Button
            className={styles.button}
            icon="add"
            intent={Intent.PRIMARY}
            onClick={() => history.push(`/templates/${id}/refs/add`)}
          >
            Привязать магазины
          </Button>
        ) : null }
        { canEdit ? (
          <Button
            className={styles.button}
            icon="trash"
            intent={Intent.DANGER}
            onClick={() => setDeleteAlertOpen(true)}
            disabled={selected.length === 0 || loading}
          >
            Удалить выбранные
            { selected.length === 0 ? '' : ` (${selected.length})`}
          </Button>
        ) : null }
        { canEdit ? (
          <Button
            minimal
            className={styles.button}
            icon="play"
            intent={Intent.SUCCESS}
            onClick={() => activateRefList(selected.map((x) => x.id))}
            disabled={selected.length === 0 || !allInactive || loading}
          >
            Активировать выбранные
            { selected.length === 0 || !allInactive ? '' : ` (${selected.length})`}
          </Button>
        ) : null }
        { canEdit ? (
          <Button
            minimal
            className={styles.button}
            icon="pause"
            intent={Intent.DANGER}
            onClick={() => deactivateRefList(selected.map((x) => x.id))}
            disabled={selected.length === 0 || !allActive || loading}
          >
            Деактивировать выбранные
            { selected.length === 0 || !allActive ? '' : ` (${selected.length})`}
          </Button>
        ) : null }
        <div className={styles.spacer} />
        <PageSelect
          showTotal
          showItemsPerPageChanger
          total={totals.items}
          currentPage={pagination.currentPage}
          maxPage={totals.pages || pagination.currentPage}
          currentItemsPerPage={pagination.itemsPerPage}
          onPageSelect={
            (currentPage) => { setPagination({ ...pagination, currentPage }); }
          }
          onChangeItemsPerPage={
            (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
          }
        />
      </div>
      { loading ? <Spinner /> : null }
      { error && !loading ? <NonIdealState title="Ошибка" description={error.message} icon="error" /> : null }
      { !error && !loading ? (
        <DataTable
          className={styles.table}
          defaultRowHeight={34}
          columns={columns}
          data={data}
          loadingRowCount={pagination.itemsPerPage}
          currentPage={pagination.currentPage}
          itemsPerPage={pagination.itemsPerPage}
          noDataText="Привязанных магазинов не найдено"
          onDoubleClick={({ item, path }) => {
            if (path[0] === 'id') {
              return;
            }
            history.push(`/warehouses/${item.wsId}`);
          }}
        />
      ) : null }
      <DeleteRefsAlert
        count={selected.length}
        isOpen={deleteAlertOpen}
        onConfirm={() => {
          deleteRefList(selected.map((x) => x.id));
          setDeleteAlertOpen(false);
        }}
        onCancel={() => setDeleteAlertOpen(false)}
      />
      <ActivateConflictDialog
        isOpen={activateConflictOpen}
        conflictList={activateConflictList}
        onClose={() => setActivateConflictOpen(false)}
        onOverwrite={getOnOverwrite(
          deactivateRefList,
          activateConflictList,
          activateRefList,
          selected,
          setActivateConflictOpen,
        )}
        onSkipConflicts={() => {
          const filtered = selected.filter((x) => _.findIndex(activateConflictList, ['wsId', x.wsId]) === -1);
          activateRefList(filtered.map((x) => x.id));
          setActivateConflictOpen(false);
        }}
      />
    </div>
  );
};

WarehouseTable.propTypes = {
  checkActiveWs: PropTypes.func.isRequired,
};

export default WarehouseTable;
