import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  H3,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';
import PageSelect from '../../widgets/PageSelect';
import { getPlanogramTmplList } from '../../api';
import { useTableRequest } from '../../api/useTableRequest';
import styles from './TemplateTable.module.scss';


const FIELDS = [
  {
    title: 'Название',
    field: 'name',
    sortable: true,
    width: 200,
  },
  {
    title: 'Описание шаблона',
    field: 'description',
    sortable: true,
    width: 310,
  },
  {
    title: 'Количество витрин',
    field: 'equipCount',
    sortable: true,
    width: 180,
  },
  {
    title: 'Количество ОП',
    field: 'wsCount',
    sortable: true,
    width: 180,
  },
  {
    title: 'Статус ',
    field: 'draft',
    valueRenderer: (value) => (value ? 'Черновик' : 'Готов'),
    sortable: true,
  },
];

const TemplateTable = () => {
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination, setSort] = useTableRequest(getPlanogramTmplList);
  const { id } = useParams();
  const history = useHistory();

  const loading = fetching;
  useEffect(() => {
    if (!filters.equipId) {
      setFilters({ equipId: id });
    } else {
      fetch({ filters, pagination, sort });
    }
  }, [fetch, setFilters, id, filters, pagination, sort]);
  const columns = getColumns(FIELDS, sort, setSort);
  return (
    <div className={styles.container}>
      <div className={styles.actionBar}>
        <H3>Используется в шаблонах</H3>
        <div className={styles.spacer} />
        <PageSelect
          showTotal
          showItemsPerPageChanger
          total={totals.items}
          currentPage={pagination.currentPage}
          maxPage={totals.pages || pagination.currentPage}
          currentItemsPerPage={pagination.itemsPerPage}
          onPageSelect={
            (currentPage) => { setPagination({ ...pagination, currentPage }); }
          }
          onChangeItemsPerPage={
            (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
          }
        />
      </div>
      { loading ? <Spinner /> : null }
      { error && !loading ? <NonIdealState title="Ошибка" description={error.message} icon="error" /> : null }
      { !error && !loading ? (
        <DataTable
          className={styles.table}
          columns={columns}
          data={data}
          loadingRowCount={pagination.itemsPerPage}
          currentPage={pagination.currentPage}
          itemsPerPage={pagination.itemsPerPage}
          noDataText="Не используется в шаблонах"
          onDoubleClick={({ item, path }) => {
            if (path[0] === 'id') {
              return;
            }
            history.push(`/templates/${item.id}`);
          }}
        />
      ) : null }
    </div>
  );
};

export default TemplateTable;
