import Immutable from 'seamless-immutable';
import * as ACTIONS from '../../actions';
import {
  buildLayout,
  addLayout,
  removeLayout,
} from './utils';

const NEW_TEMPLATE = Immutable({
  id: undefined,
  draft: true,
  draftStep: 0,
  // шаг 1
  name: '',
  description: '',
  typeId: undefined,
  typeName: undefined,
  typePriority: undefined,
  // шаг 2
  equipments: [],
  // шаг 3
  layouts: [],
});

const INITIAL_STATE = Immutable({
  data: NEW_TEMPLATE,
  backup: JSON.stringify(NEW_TEMPLATE),
  fetching: false,
  error: null,
  saving: false,
  saveError: null,
});


const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.TEMPLATE_FETCH_START:
      return state.merge({ fetching: true });
    case ACTIONS.TEMPLATE_FETCH_DONE:
      return state.merge({
        fetching: false,
        data: action.payload,
        backup: JSON.stringify(action.payload),
        error: null,
      });
    case ACTIONS.TEMPLATE_FETCH_ERROR:
      return state.merge({ fetching: false, error: action.error });
    case ACTIONS.TEMPLATE_SAVE_START:
      return state.merge({ saving: true });
    case ACTIONS.TEMPLATE_SAVE_DONE:
      return state.merge({
        saving: false,
        data: action.payload,
        backup: JSON.stringify(action.payload),
        saveError: null,
      });
    case ACTIONS.TEMPLATE_SAVE_ERROR:
      return state.merge({ saving: false, saveError: action.error });
    case ACTIONS.TEMPLATE_NEW:
      return state.merge({
        fetching: false,
        data: NEW_TEMPLATE,
        backup: JSON.stringify(NEW_TEMPLATE),
        error: null,
      });
    case ACTIONS.TEMPLATE_EDIT:
      return state.merge({
        data: {
          ...state.data,
          ...action.payload,
        },
      });
    case ACTIONS.TEMPLATE_ADD_EQUIPMENT: {
      const equipments = state.data.equipments.asMutable();
      const { index, equipment } = action.payload;
      if (index || index === 0) {
        equipments.splice(index, 0, equipment);
      } else {
        equipments.push(equipment);
      }
      return state.merge({
        data: {
          ...state.data,
          equipments,
        },
      });
    }
    case ACTIONS.TEMPLATE_MOVE_EQUIPMENT: {
      const { oldIndex, newIndex, equipment } = action.payload;
      const isForwardDirection = newIndex > oldIndex;
      const equipments = state.data.equipments.asMutable();
      if (isForwardDirection) {
        equipments.splice(newIndex, 0, equipment);
        equipments.splice(oldIndex, 1);
      } else {
        equipments.splice(oldIndex, 1);
        equipments.splice(newIndex, 0, equipment);
      }
      return state.merge({
        data: {
          ...state.data,
          equipments,
        },
      });
    }
    case ACTIONS.TEMPLATE_EDIT_EQUIPMENT: {
      const { index, update } = action.payload;
      let nextState = state;
      Object.keys(update).forEach((k) => {
        nextState = nextState.setIn(['data', 'equipments', index, k], update[k]);
      });
      return nextState;
    }
    case ACTIONS.TEMPLATE_DELETE_EQUIPMENT: {
      const { index } = action.payload;
      const equipments = state.data.equipments.asMutable();
      const { tmplEquipRefId } = equipments[index];
      equipments.splice(index, 1);
      const layouts = state.data.layouts.filter((l) => l.tmplEquipRefId !== tmplEquipRefId);
      return state.merge({
        data: {
          ...state.data,
          equipments,
          layouts,
        },
      });
    }
    case ACTIONS.TEMPLATE_BIND_LAYOUT: {
      const {
        tmplEquipRefId,
        locationId,
        cells,
        group,
        parents,
      } = action.payload;
      const toAdd = buildLayout(group, tmplEquipRefId, locationId, cells);
      const layouts = addLayout(
        state.data.layouts.asMutable({ deep: true }),
        state.data.equipments,
        toAdd,
        parents,
      );
      return state.merge({
        data: {
          ...state.data,
          layouts,
        },
      });
    }
    case ACTIONS.TEMPLATE_UNBIND_LAYOUT: {
      const layout = action.payload;
      const layouts = removeLayout(
        state.data.layouts.asMutable({ deep: true }),
        state.data.equipments,
        layout,
      );
      return state.merge({
        data: {
          ...state.data,
          layouts,
        },
      });
    }
    default:
      return state;
  }
};

export const selectHasUnsavedChanges = (state) => (
  !state.tmplWizard.template.fetching
  && !state.tmplWizard.template.saving
  && !state.tmplWizard.template.error
  && !state.tmplWizard.template.saveError
  && JSON.stringify(state.tmplWizard.template.data) !== state.tmplWizard.template.backup
);

export default reducer;
