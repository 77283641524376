import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

const getContextMenu = ({ history, canEdit }) => ({ item }) => (
  <Menu>
    <MenuItem
      text="Просмотр"
      icon="eye-open"
      onClick={() => history.push(`/equipments/${item.id}`)}
    />
    { canEdit ? (
      <MenuItem
        text="Изменить"
        icon="edit"
        disabled={!!item.templatesCount}
        onClick={() => history.push(`/equipments/${item.id}/edit`)}
      />
    ) : null }
    { canEdit ? (
      <MenuItem
        text="Копировать"
        icon="duplicate"
        onClick={() => history.push(`/equipments/${item.id}/copy`)}
      />
    ) : null }
  </Menu>
);

export default getContextMenu;
