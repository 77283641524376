import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { intRange } from '../../../utils/numbers';
import styles from './DragPreview.module.scss';

const Cell = (i) => <div key={i} className={styles.cell}>{i}</div>;

const Shelf = (location) => (
  <div key={location.locationId} className={styles.shelf}>
    {intRange(1, location.maxfacex).map(Cell)}
  </div>
);

const Equipment = ({ equipment }) => (
  <div
    className={styles.equipment}
    style={{
      width: `${equipment.width / 10}px`,
      minWidth: `${equipment.width / 10}px`,
    }}
  >
    <div key="title" className={styles.equipmentTitle}>{equipment.name}</div>
    {equipment.children.map(Shelf)}
  </div>
);

Equipment.propTypes = {
  equipment: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    name: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      locationId: PropTypes.number,
      maxfacex: PropTypes.number,
    })),
  }).isRequired,
};

const DragPreview = memo(Equipment);

export default DragPreview;
