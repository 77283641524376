import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Button,
  Alignment,
  NavbarHeading,
  NavbarDivider,
  Popover,
  Menu,
  MenuItem,
  Position,
  Intent,
} from '@blueprintjs/core';
import { __RouterContext as RouterContext, matchPath, Link } from 'react-router-dom';
import styles from './NavBar.module.scss';
import { userType } from '../../proptyping';
import { canVisit } from '../routes';


const RoutePropType = PropTypes.shape({
  path: PropTypes.string.isRequired,
  icon: PropTypes.string,
  name: PropTypes.string,
});


const NavBarLink = ({ children, to, onClick }) => (
  <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }} onClick={onClick}>
    {children}
  </Link>
);
NavBarLink.propTypes = {
  children: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

NavBarLink.defaultProps = {
  onClick: () => {},
};

const NavBarButton = ({ route, user }) => {
  const hasRights = canVisit(user, route);
  if (!hasRights) {
    return null;
  }
  return (
    <RouterContext.Consumer>
      {(context) => {
        const active = matchPath(context.location.pathname, route);

        return (
          <NavBarLink to={route.path}>
            <Button
              minimal
              active={active}
              icon={route.icon}
              text={route.name}
              className={styles.button}
              intent={Intent.SUCCESS}
            />
          </NavBarLink>
        );
      }}
    </RouterContext.Consumer>
  );
};

NavBarButton.propTypes = {
  route: RoutePropType.isRequired,
  user: userType,
};

NavBarButton.defaultProps = {
  user: null,
};


const NavBarMenuItem = ({
  route,
  params,
  user,
  popoverRef,
}) => {
  const hasRights = canVisit(user, route);
  if (!hasRights) {
    return null;
  }

  return (
    <RouterContext.Consumer>
      {(context) => {
        const active = matchPath(context.location.pathname, route);
        let { path } = route;
        if (params) {
          Object.keys(params).forEach((k) => {
            path = path.replace(`:${k}`, params[k]);
          });
        }

        return (
          <NavBarLink
            to={route.path}
            onClick={() => {
              if (popoverRef && popoverRef.current && popoverRef.current.setOpenState) {
                popoverRef.current.setOpenState(false);
              }
            }}
          >
            <MenuItem
              tagName="div"
              active={active}
              icon={route.icon}
              text={route.name}
              intent={Intent.SUCCESS}
            />
          </NavBarLink>
        );
      }}
    </RouterContext.Consumer>
  );
};

NavBarMenuItem.propTypes = {
  route: RoutePropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  user: userType,
  popoverRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

NavBarMenuItem.defaultProps = {
  params: null,
  user: null,
};


const findRoute = (routes, path) => routes.find((x) => x.path === path);


const PersonMenu = ({ routes, popoverRef }) => (
  <Menu>
    <NavBarMenuItem route={findRoute(routes, '/logout')} popoverRef={popoverRef} />
  </Menu>
);

PersonMenu.propTypes = {
  routes: PropTypes.arrayOf(RoutePropType).isRequired,
  popoverRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

const RefsMenu = ({ routes, user, popoverRef }) => (
  <Menu>
    <NavBarMenuItem route={findRoute(routes, '/equipments')} user={user} popoverRef={popoverRef} />
    <NavBarMenuItem route={findRoute(routes, '/warehouses')} user={user} popoverRef={popoverRef} />
    <NavBarMenuItem route={findRoute(routes, '/layout-tree')} user={user} popoverRef={popoverRef} />
    <NavBarMenuItem route={findRoute(routes, '/users')} user={user} popoverRef={popoverRef} />
  </Menu>
);

RefsMenu.propTypes = {
  routes: PropTypes.arrayOf(RoutePropType).isRequired,
  user: userType.isRequired,
  popoverRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

const NavigationMenu = ({ routes, user }) => {
  const refsPopoverRef = useRef(null);
  return (
    <>
      <NavBarButton route={findRoute(routes, '/templates')} />
      <Popover
        ref={refsPopoverRef}
        content={
          <RefsMenu routes={routes} user={user} popoverRef={refsPopoverRef} />
        }
        position={Position.BOTTOM_LEFT}
      >
        <Button
          minimal
          icon="list"
          text="Справочники"
          rightIcon="chevron-down"
          className={styles.button}
          intent={Intent.SUCCESS}
        />
      </Popover>
    </>
  );
};

NavigationMenu.propTypes = {
  routes: PropTypes.arrayOf(RoutePropType).isRequired,
  user: userType.isRequired,
};


const NavBar = ({ showNavigation, user, routes }) => {
  const personPopoverRef = useRef(null);
  return (
    <Navbar className={styles.navBar} fixedToTop>
      <div className={styles.content}>

        <Navbar.Group align={Alignment.LEFT}>
          <NavbarHeading>
            <span className={styles.titleLeft}>МФР</span>
            <span className={styles.titleRight}>merch</span>
          </NavbarHeading>
          <NavbarDivider />
          {showNavigation && user && (
            <NavigationMenu
              user={user}
              routes={routes}
            />
          )}
        </Navbar.Group>

        {
          showNavigation
          && user
          && (
            <Navbar.Group align={Alignment.RIGHT}>
              <NavbarDivider />
              <Popover
                ref={personPopoverRef}
                content={<PersonMenu routes={routes} popoverRef={personPopoverRef} />}
                position={Position.BOTTOM_RIGHT}
              >
                <Button
                  minimal
                  icon="person"
                  title={user.fullname}
                  text={user.fullname}
                  rightIcon="chevron-down"
                  className={styles.userButton}
                  intent={Intent.SUCCESS}
                />
              </Popover>
            </Navbar.Group>
          )
        }
      </div>
    </Navbar>
  );
};

NavBar.propTypes = {
  showNavigation: PropTypes.bool,
  user: userType,
  routes: PropTypes.arrayOf(RoutePropType).isRequired,
};

NavBar.defaultProps = {
  showNavigation: null,
  user: null,
};

export default NavBar;
