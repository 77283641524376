import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tree, TreeNode } from '@blueprintjs/core';
import { useDrag } from 'react-dnd';
import ItemTypes from './ItemTypes';

const DraggableTreeNode = (props) => {
  const { nodeData } = props;
  const [, drag] = useDrag({
    item: { type: ItemTypes.TREE_ITEM, nodeData },
  });
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <TreeNode
      {...props}
      contentRef={(node, element) => {
        drag(element);
        props.contentRef(node, element);
      }}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

DraggableTreeNode.propTypes = {
  contentRef: PropTypes.func.isRequired,
  nodeData: PropTypes.shape({}).isRequired,
};

class DraggableTree extends Tree {
  constructor() {
    super();

    this.renderNodes = (treeNodes, currentPath, className) => {
      if (treeNodes == null) {
        return null;
      }
      /* eslint-disable react/no-this-in-sfc */
      /* eslint-disable react/jsx-props-no-spreading */
      const nodeItems = treeNodes.map((node, i) => {
        const elementPath = currentPath.concat(i);
        return (
          <DraggableTreeNode
            {...node}
            key={node.id}
            contentRef={this.handleContentRef}
            depth={elementPath.length - 1}
            onClick={this.handleNodeClick}
            onContextMenu={this.handleNodeContextMenu}
            onCollapse={this.handleNodeCollapse}
            onDoubleClick={this.handleNodeDoubleClick}
            onExpand={this.handleNodeExpand}
            onMouseEnter={this.handleNodeMouseEnter}
            onMouseLeave={this.handleNodeMouseLeave}
            path={elementPath}
          >
            {this.renderNodes(node.childNodes, elementPath)}
          </DraggableTreeNode>
        );
      });
      /* eslint-enable react/no-this-in-sfc */
      /* eslint-enable react/jsx-props-no-spreading */
      return <ul className={classNames('bp3-tree-node-list', className)}>{nodeItems}</ul>;
    };
  }
}

export default DraggableTree;
