/**
 * По мотивам https://github.com/rwu823/react-selection
 */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import toggleClass from './toggle-class';
import LimitRange from './limit-range';

const topLeftLimitRange = new LimitRange('top-left');
const topRightLimitRange = new LimitRange('top-right');
const downRightLimitRange = new LimitRange('down-right');
const downLeftLimitRange = new LimitRange('down-left');


class Selection extends React.Component {
  constructor() {
    super();
    this.boxRef = React.createRef();
    this.state = {
      rectangleStyle: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        opacity: 0,
      },
    };
  }

  mousedown = (ev) => {
    const {
      target: targetSelect,
      selectedClass,
      beforeSelect,
    } = this.props;
    beforeSelect();
    this.targets = Array.from(this.boxRef.current.querySelectorAll(targetSelect));

    this.targets.forEach((target) => {
      target.classList.remove(selectedClass);
    });

    this.clickY = ev.pageY - ev.currentTarget.offsetTop + this.boxRef.current.scrollTop;
    this.clickX = ev.pageX - ev.currentTarget.offsetLeft + this.boxRef.current.scrollLeft;

    document.addEventListener('mousemove', this.mousemove, false);
    document.addEventListener('mouseup', this.mouseup, false);
  }

  afterSelect = () => {
    const { afterSelect, selectedClass } = this.props;
    const { scrollTop, scrollLeft } = this.boxRef.current;
    const scrollOffset = { scrollTop, scrollLeft };
    const boxRect = this.boxRef.current.getBoundingClientRect();
    afterSelect(
      this.targets.filter((t) => t.classList.contains(selectedClass)),
      boxRect,
      scrollOffset,
    );
    this.targets.forEach((target) => {
      target.classList.remove(selectedClass);
    });
  }

  keyup = () => {
    this.afterSelect();
    window.removeEventListener('keyup', this.keyup);
  }

  mouseup = () => {
    const { isLimit } = this.props;
    const { rectangleStyle } = this.state;
    this.setState({
      rectangleStyle: {
        ...rectangleStyle,
        opacity: 0,
      },
    });

    document.removeEventListener('mousemove', this.mousemove);
    document.removeEventListener('mouseup', this.mouseup);

    this.afterSelect();

    if (isLimit) {
      topLeftLimitRange.reset();
      topRightLimitRange.reset();
      downRightLimitRange.reset();
      downLeftLimitRange.reset();
    }
  }

  mousemove = (ev) => {
    const moveX = (
      (ev.pageX - this.boxRef.current.offsetLeft)
      - this.clickX
      + this.boxRef.current.scrollLeft
    );
    const moveY = (
      (ev.pageY - this.boxRef.current.offsetTop)
      - this.clickY
      + this.boxRef.current.scrollTop
    );
    const { isLimit } = this.props;

    let rectangleSize = {};

    if (moveX < 0 && moveY < 0) { // top-left
      rectangleSize = {
        left: this.clickX + moveX,
        top: this.clickY + moveY,
        width: moveX * -1,
        height: moveY * -1,
      };

      if (isLimit) {
        rectangleSize = topLeftLimitRange.getNewSize({
          rectangle: rectangleSize,
          container: this.boxRef.current,
        });
      }
    } else if (moveX > 0 && moveY > 0) { // down-right
      rectangleSize = {
        left: this.clickX,
        top: this.clickY,
        width: moveX,
        height: moveY,
      };

      if (isLimit) {
        rectangleSize = downRightLimitRange.getNewSize({
          rectangle: rectangleSize,
          container: this.boxRef.current,
        });
      }
    } else if (moveX > 0 && moveY < 0) { // top-right
      rectangleSize = {
        left: this.clickX,
        top: this.clickY + moveY,
        width: moveX,
        height: moveY * -1,
      };

      if (isLimit) {
        rectangleSize = topRightLimitRange.getNewSize({
          rectangle: rectangleSize,
          container: this.boxRef.current,
        });
      }
    } else if (moveX < 0 && moveY > 0) { // down-left
      rectangleSize = {
        left: this.clickX + moveX,
        top: this.clickY,
        width: moveX * -1,
        height: moveY,
      };

      if (isLimit) {
        rectangleSize = downLeftLimitRange.getNewSize({
          rectangle: rectangleSize,
          container: this.boxRef.current,
        });
      }
    }

    this.setState({
      rectangleStyle: {
        ...rectangleSize,
        opacity: 1,
      },
    });

    this.targets.forEach((target) => {
      const { selectedClass } = this.props;
      const tar = {
        x: target.offsetLeft,
        y: target.offsetTop,
        xx: target.offsetLeft + target.offsetWidth,
        yy: target.offsetTop + target.offsetHeight,
      };

      const square = {
        x: rectangleSize.left,
        y: rectangleSize.top,
        xx: rectangleSize.left + rectangleSize.width,
        yy: rectangleSize.top + rectangleSize.height,
      };

      const isDouble = (
        Math.max(tar.x, square.x) <= Math.min(tar.xx, square.xx)
        && Math.max(tar.y, square.y) <= Math.min(tar.yy, square.yy)
      );

      toggleClass(target, isDouble, selectedClass);
    });
  }

  render() {
    const {
      children,
      target,
      selectedClass,
      afterSelect,
      beforeSelect,
      isLimit,
      className,
      ...props
    } = this.props;
    const { rectangleStyle } = this.state;
    return (
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      /* eslint-disable react/jsx-props-no-spreading */
      <div
        {...props}
        ref={this.boxRef}
        className={classNames(className, 'react-selection')}
        onMouseDown={this.mousedown}
      >
        {children}
        <div className="react-selection-rectangle" style={rectangleStyle} />
      </div>
      /* eslint-enable jsx-a11y/no-static-element-interactions */
      /* eslint-enable react/jsx-props-no-spreading */
    );
  }
}

Selection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  target: PropTypes.string,
  selectedClass: PropTypes.string,
  afterSelect: PropTypes.func,
  beforeSelect: PropTypes.func,
  isLimit: PropTypes.bool,
};

Selection.defaultProps = {
  className: '',
  target: '.react-selection-target',
  selectedClass: 'react-selection-selected',
  isLimit: false,
  afterSelect: () => {},
  beforeSelect: () => {},
};

export default Selection;
