import React from 'react';
import PropTypes from 'prop-types';
import {
  Intent,
  Alert,
} from '@blueprintjs/core';

const DeleteRefsAlert = ({
  count,
  isOpen,
  onConfirm,
  onCancel,
}) => (
  <Alert
    cancelButtonText="Отмена"
    confirmButtonText="Удалить"
    icon="trash"
    intent={Intent.DANGER}
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <p>
      {`Удалить привязки шаблона к выбранным магазинам: ${count} шт?`}
    </p>
  </Alert>
);

DeleteRefsAlert.propTypes = {
  count: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteRefsAlert;
