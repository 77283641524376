import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Elevation,
  Intent,
} from '@blueprintjs/core';
import { selectCanEditTmpl } from '../../state/reducers/auth';
import { equipmentType } from '../../proptyping';
import styles from './index.module.scss';
import DeleteAlert from './DeleteAlert';


const DetailsCard = ({ equipment, onEdit, onDelete }) => {
  const [isAlertOpened, setAlertOpened] = useState(false);
  const canEdit = useSelector(selectCanEditTmpl);
  return (
    <Card className={styles.detailsCard} elevation={Elevation.TWO}>
      <h3 className={styles.detailsTitle}>Просмотр оборудования</h3>
      <div className={styles.detailsContent}>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Название:</div>
          <div className={styles.detailsValue}>{equipment.name}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Ширина, мм:</div>
          <div className={styles.detailsValue}>{equipment.width}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Высота, мм:</div>
          <div className={styles.detailsValue}>{equipment.height}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Количество полок:</div>
          <div className={styles.detailsValue}>{equipment.children.length}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Привязано шаблонов:</div>
          <div className={styles.detailsValue}>{equipment.templatesCount}</div>
        </div>
      </div>
      { canEdit ? (
        <div className={styles.detailsBottom}>
          <Button
            className={styles.detailsButton}
            onClick={() => setAlertOpened(true)}
            text="Удалить"
            icon="trash"
            intent={Intent.DANGER}
            disabled={!!equipment.templatesCount}
          />
          <Button
            className={styles.detailsButton}
            onClick={onEdit}
            text="Изменить"
            icon="edit"
            intent={Intent.PRIMARY}
          />
        </div>
      ) : null }
      <DeleteAlert
        name={equipment.name}
        isOpen={isAlertOpened}
        onCancel={() => setAlertOpened(false)}
        onConfirm={() => {
          setAlertOpened(false);
          onDelete(equipment.id);
        }}
      />
    </Card>
  );
};

DetailsCard.propTypes = {
  equipment: equipmentType.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DetailsCard;
