import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Intent,
  Tag,
} from '@blueprintjs/core';

import _ from 'lodash';


import { intRange } from '../../utils/numbers';
import { getLayoutTree, getNoLayouts } from '../../state/reducers/tmplWizard/utils';
import styles from './Equipment.module.scss';


const Cell = ({
  index,
  locationId,
  equipment,
  groupType,
}) => (
  <div
    className={groupType === 'dummy' ? classNames(styles.cell, styles.dummy) : styles.cell}
    data-location-id={locationId}
    data-tmpl-equip-ref-id={equipment.tmplEquipRefId}
    data-cell={index}
  >
    {index}
  </div>
);

Cell.propTypes = {
  index: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
  }).isRequired,
  groupType: PropTypes.oneOf(['product', 'dummy']).isRequired,
};

const NoLayout = ({
  layout,
  equipment,
}) => (
  <div
    className={styles.noLayout}
    style={{
      flexGrow: layout.cells.length,
      flexShrink: layout.cells.length,
      flexBasis: `${20 * layout.cells.length}px`,
    }}
  >
    {layout.cells.map((i) => (
      <Cell
        key={i}
        index={i}
        locationId={layout.locationId}
        equipment={equipment}
        groupType={layout.groupType}
      />
    ))}
  </div>
);
NoLayout.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    groupType: PropTypes.oneOf(['product', 'dummy']),
    cells: PropTypes.arrayOf(PropTypes.number),
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
  }).isRequired,
};

const Layout = ({
  layout,
  equipment,
}) => {
  const layoutTree = layout.children || [];
  const emptyLayouts = getNoLayouts(
    layout.locationId,
    layoutTree,
    layout.cells,
    layout.groupType,
  );
  const childItems = _.sortBy([...layoutTree, ...emptyLayouts], (i) => i.cells[0]);

  return (
    <div
      className={styles.layout}
      style={{
        flexGrow: layout.cells.length,
        flexShrink: layout.cells.length,
        flexBasis: `${20 * layout.cells.length}px`,
        backgroundColor: layout.groupColor || undefined,
      }}
    >
      <div className={styles.layoutTitle}>
        <div title={layout.groupName} className={styles.titleWrapper}>
          {layout.groupName}
        </div>
      </div>
      <div title={layout.groupName} className={styles.layoutContent}>
        {childItems.map((item, index) => {
          /* eslint-disable react/no-array-index-key */
          if (item.groupId) {
            return (
              <Layout
                key={`${equipment.tmplEquipRefId}-${item.locationId}-${index}`}
                layout={item}
                equipment={equipment}
              />
            );
          }
          return (
            <NoLayout
              key={`no-${equipment.tmplEquipRefId}-${item.locationId}-${index}`}
              layout={item}
              equipment={equipment}
            />
          );
          /* eslint-enable react/no-array-index-key */
        })}
      </div>
    </div>
  );
};
Layout.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    groupType: PropTypes.oneOf(['product', 'dummy']),
    cells: PropTypes.arrayOf(PropTypes.number),
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
  }).isRequired,
};

// считаем высоту полки по пикселям flex в ie11
const getShelfHeight = (maxDepth) => {
  const minHeight = 42 + 22 * (maxDepth + 1);
  return minHeight;
};

const Shelf = ({
  location,
  equipment,
  layouts,
}) => {
  const { layoutTree, maxDepth } = useMemo(() => getLayoutTree(layouts), [layouts]);
  const emptyLayouts = getNoLayouts(
    location.locationId,
    layoutTree,
    intRange(1, location.maxfacex),
    'product',
  );
  const childItems = _.sortBy([...layoutTree, ...emptyLayouts], (i) => i.cells[0]);
  const height = getShelfHeight(maxDepth);

  return (
    <div
      className={styles.shelf}
      style={{
        height: `${height}px`,
        minHeight: `${height}px`,
      }}
    >
      <div className={styles.priorityContainer}>
        <Tag
          className={styles.priorityTag}
          intent={Intent.PRIMARY}
          title={`Приоритет ${location.priority}`}
          minimal
        >
          {location.priority}
        </Tag>
      </div>
      { childItems.map((item, index) => {
        /* eslint-disable react/no-array-index-key */
        if (item.groupId) {
          return (
            <Layout
              key={`${item.tmplEquipRefId}-${item.locationId}-${index}`}
              layout={item}
              equipment={equipment}
            />
          );
        }
        return (
          <NoLayout
            key={`no-${equipment.tmplEquipRefId}-${item.locationId}-${index}`}
            layout={item}
            equipment={equipment}
          />
        );
        /* eslint-enable react/no-array-index-key */
      }) }
    </div>
  );
};

Shelf.propTypes = {
  location: PropTypes.shape({
    locationId: PropTypes.number,
    maxfacex: PropTypes.number,
    priority: PropTypes.number,
  }).isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    height: PropTypes.number,
    shelfCount: PropTypes.number,
  }).isRequired,
  layouts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    cells: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
};


const Equipment = ({
  index,
  equipment,
  layouts,
}) => (
  <div className={styles.equipmentWrapper}>
    <Tag
      round
      minimal
      intent={Intent.PRIMARY}
      className={styles.priority}
    >
      Приоритет
      {' '}
      {index + 1}
    </Tag>
    <div
      className={styles.equipment}
      style={{
        minWidth: `${0.3 * equipment.width}px`,
        width: `${0.3 * equipment.width}px`,
      }}
    >
      <div key="title" className={styles.equipmentTitle}>
        <div title={equipment.title} className={styles.titleWrapper}>
          {equipment.title}
        </div>
      </div>
      <div className={styles.equipmentBody}>
        {equipment.children.map((l) => (
          <Shelf
            key={l.locationId}
            location={l}
            equipment={{
              id: equipment.id,
              tmplEquipRefId: equipment.tmplEquipRefId,
              height: equipment.height,
              shelfCount: equipment.children.length,
            }}
            layouts={layouts.filter((layout) => layout.locationId === l.locationId)}
          />
        ))}
      </div>
    </div>
  </div>
);

Equipment.propTypes = {
  index: PropTypes.number.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    cells: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      locationId: PropTypes.number,
      maxfacex: PropTypes.number,
    })),
  }).isRequired,
};

export default Equipment;
