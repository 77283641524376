import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  H3,
  Button,
  Card,
  Elevation,
  Icon,
  Intent,
  EditableText,
  Tag,
} from '@blueprintjs/core';
import { useDrop, useDrag } from 'react-dnd';
import { intRange } from '../../../utils/numbers';
import ItemTypes from './ItemTypes';
import styles from './TemplateCard.module.scss';

const Cell = (i) => <div key={i} className={styles.cell}>{i}</div>;

const Shelf = (location) => (
  <div key={location.locationId} className={styles.shelf}>
    <div className={styles.priorityContainer}>
      <Tag
        className={styles.priorityTag}
        intent={Intent.PRIMARY}
        title={`Приоритет ${location.priority}`}
        minimal
      >
        {location.priority}
      </Tag>
    </div>
    {intRange(1, location.maxfacex).map(Cell)}
  </div>
);


const Equipment = ({
  index,
  equipment,
  onDelete,
  onEdit,
}) => {
  const [title, setTitle] = useState(equipment.title);
  const [canDrag, setCanDrag] = useState(true);
  useEffect(() => { setTitle(equipment.title); }, [equipment.title]);

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.EQUIP_ITEM, equipment, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const className = (
    isDragging
      ? classNames(styles.equipment, styles.dragging)
      : styles.equipment
  );

  return (
    <div className={styles.equipmentWrapper}>
      <Tag
        round
        minimal
        intent={Intent.PRIMARY}
        className={styles.priority}
      >
        Приоритет
        {' '}
        {index + 1}
      </Tag>
      <div
        ref={canDrag ? drag : () => {}}
        className={className}
        style={{
          minWidth: `${0.3 * equipment.width}px`,
          width: `${0.3 * equipment.width}px`,
        }}
      >
        <div key="title" className={styles.equipmentTitle}>
          <div className={styles.dragHandle}>
            <Icon icon="drag-handle-horizontal" />
          </div>
          <div
            className={styles.titleWrapper}
            onMouseEnter={() => setCanDrag(false)}
            onMouseLeave={() => setCanDrag(true)}
          >
            <EditableText
              className={styles.titleInput}
              value={title}
              placeholder="Нажмите для изменения"
              onChange={(value) => setTitle(value)}
              onConfirm={() => onEdit(index, { title })}
            />
          </div>
          <Button title="Удалить" small onClick={onDelete} icon="cross" intent={Intent.DANGER} />
        </div>
        <div className={styles.equipmentBody}>
          {equipment.children.map(Shelf)}
        </div>
      </div>
    </div>
  );
};

Equipment.propTypes = {
  index: PropTypes.number.isRequired,
  equipment: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      locationId: PropTypes.number,
      maxfacex: PropTypes.number,
    })),
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};


const DropZoneBetween = ({ index, addEquipment, moveEquipment }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.EQUIP_ROW, ItemTypes.EQUIP_ITEM],
    drop: (item, monitor) => {
      const itemType = monitor.getItemType();
      if (itemType === ItemTypes.EQUIP_ITEM) {
        moveEquipment(item.index, index, item.equipment);
      } else {
        addEquipment(index, item.equipment);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  return (
    <div
      ref={drop}
      className={isOver ? classNames(styles.dropzone, styles.over) : styles.dropzone}
    />
  );
};

DropZoneBetween.propTypes = {
  index: PropTypes.number.isRequired,
  addEquipment: PropTypes.func.isRequired,
  moveEquipment: PropTypes.func.isRequired,
};

const EmptyDropZone = ({ addEquipment }) => {
  const [{ isOver, item }, drop] = useDrop({
    accept: ItemTypes.EQUIP_ROW,
    drop: () => addEquipment(undefined, item.equipment),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
  });
  return (
    <div
      ref={drop}
      className={isOver ? classNames(styles.empty, styles.over) : styles.empty}
    >
      Перетащите оборудование из списка справа
    </div>
  );
};

EmptyDropZone.propTypes = {
  addEquipment: PropTypes.func.isRequired,
};

const TemplateCard = ({
  onSave,
  onNext,
  template,
  addEquipment,
  moveEquipment,
  editEquipment,
  deleteEquipment,
}) => {
  const history = useHistory();
  const onClose = () => history.push('/templates');
  const invalid = (
    template.equipments.length === 0
    || template.equipments.filter((x) => !x.title).length > 0
  );
  return (
    <Card elevation={Elevation.TWO} className={styles.template}>
      <H3>
        {template.name}
      </H3>
      { /* eslint-disable react/no-array-index-key */ }
      { template && !template.equipments.length ? (
        <EmptyDropZone addEquipment={addEquipment} />
      ) : (
        <div className={styles.container}>
          {template.equipments.map((e, index) => (
            <Fragment key={`fragment-${e.id}-${e.title}-${index}`}>
              <DropZoneBetween
                addEquipment={addEquipment}
                moveEquipment={moveEquipment}
                key={`dropzone-${index}`}
                index={index}
              />
              <Equipment
                key={`${e.id}-${e.title}-${index}`}
                index={index}
                equipment={e}
                onDelete={() => deleteEquipment(index)}
                onEdit={editEquipment}
              />
            </Fragment>
          ))}
          <DropZoneBetween
            addEquipment={addEquipment}
            moveEquipment={moveEquipment}
            key={`dropzone-${template.equipments.length}`}
            index={template.equipments.length}
          />
        </div>
      )}
      <div className={styles.spacer} />
      <div className={styles.buttonRow}>
        <Button icon="caret-left" onClick={onClose}>К списку</Button>
        <Button
          rightIcon="floppy-disk"
          onClick={onSave}
        >
          Сохранить
        </Button>
        <Button
          disabled={invalid}
          rightIcon="caret-right"
          onClick={onNext}
          intent={Intent.PRIMARY}
        >
          Далее
        </Button>
      </div>
    </Card>
  );
};

TemplateCard.propTypes = {
  onSave: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  template: PropTypes.shape({
    name: PropTypes.string,
    equipments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })),
  }).isRequired,
  addEquipment: PropTypes.func.isRequired,
  moveEquipment: PropTypes.func.isRequired,
  editEquipment: PropTypes.func.isRequired,
  deleteEquipment: PropTypes.func.isRequired,
};

export default TemplateCard;
