import _ from 'lodash';
import { intRange } from '../../../utils/numbers';

const getLocationMaxfacex = (tmplEquipRefId, locationId, equipments) => {
  /* eslint-disable no-restricted-syntax */
  for (const e of equipments) {
    if (e.tmplEquipRefId === tmplEquipRefId) {
      for (const l of e.children) {
        if (l.locationId === locationId) {
          return l.maxfacex;
        }
      }
    }
  }
  /* eslint-enable no-restricted-syntax */
  return 0;
};

const mergeLayouts = (layouts) => {
  // объедияем соседние раскладки с cовпадающими группами
  let workingLayouts = _.sortBy(
    layouts,
    ['tmplEquipRefId', 'locationId', 'groupLevel', (l) => l.cells.length && l.cells[0]],
  );

  workingLayouts = workingLayouts.reduce((result, l) => {
    if (!result.length) {
      return [l];
    }
    const last = result[result.length - 1];
    if (
      last.tmplEquipRefId === l.tmplEquipRefId
      && last.locationId === l.locationId
      && last.groupId === l.groupId
      && last.cells.length
      && l.cells.length
      && last.cells[last.cells.length - 1] + 1 === l.cells[0]
    ) {
      last.cells = [
        ...last.cells,
        ...l.cells,
      ];
    } else {
      result.push(l);
    }
    return result;
  }, []);

  return workingLayouts;
};

export const intersects = (l1, l2) => {
  const sameShelf = (
    l1.locationId === l2.locationId
    && l1.tmplEquipRefId === l2.tmplEquipRefId
  );
  const wasFullShelf = l1.cells.length === 0;
  const isFullShelf = l2.cells.length === 0;
  const hasIntersection = sameShelf && (
    wasFullShelf
    || isFullShelf
    || !!_.intersection(l1.cells, l2.cells).length
  );
  return hasIntersection;
};

const clearSpace = (layouts, equipments, space) => {
  let workingLayouts = [...layouts];

  const spaceFullShelf = space.cells.length === 0;
  if (spaceFullShelf) {
    // удаляем все с этой полки
    workingLayouts = _.filter(
      workingLayouts,
      (l) => !(
        l.locationId === space.locationId
        && l.tmplEquipRefId === space.tmplEquipRefId
      ),
    );
  } else {
    const spaceMinCell = space.cells[0];
    const spaceMaxCell = space.cells[space.cells.length - 1];
    layouts.forEach((l) => {
      const wasFullShelf = l.cells.length === 0;
      const hasIntersection = intersects(l, space);
      if (hasIntersection) {
        let { cells } = l;
        if (wasFullShelf) {
          const maxfacex = getLocationMaxfacex(space.tmplEquipRefId, space.locationId, equipments);
          cells = intRange(1, maxfacex);
        }
        const minCell = cells[0];
        const maxCell = cells[cells.length - 1];
        const spaceContains = minCell >= spaceMinCell && maxCell <= spaceMaxCell;
        const spaceIsBetween = spaceMinCell > minCell && spaceMaxCell < maxCell;
        const spaceOverlapsStart = spaceMaxCell >= minCell && spaceMinCell <= minCell;
        const spaceOverlapsEnd = spaceMinCell <= maxCell && spaceMaxCell >= maxCell;
        // убираем старую раскладки
        workingLayouts = _.without(workingLayouts, l);
        if (spaceContains) {
          // старая раскладка содержатся в очишаемом месте
          // достаточно просто убрать
        } else if (spaceIsBetween) {
          // очишаемое место внутри старой раскладки
          // разбиваем старую на 3 части
          workingLayouts.push({ ...l, cells: intRange(minCell, spaceMinCell - 1) });
          workingLayouts.push({ ...l, cells: intRange(spaceMaxCell + 1, maxCell) });
        } else if (spaceOverlapsStart) {
          // подрезаем старую сначала
          workingLayouts.push({ ...l, cells: intRange(spaceMaxCell + 1, maxCell) });
        } else if (spaceOverlapsEnd) {
          // подрезаем старую сконца
          workingLayouts.push({ ...l, cells: intRange(minCell, spaceMinCell - 1) });
        } else {
          console.warning('clearSpace warning!');
        }
      }
    });
  }

  return workingLayouts;
};

export const buildLayout = (g, tmplEquipRefId, locationId, cells) => ({
  groupId: g.id,
  groupName: g.name,
  groupColor: g.color,
  groupIsLeaf: g.isLeaf,
  groupLevel: g.level,
  groupParentId: g.parentId,
  groupType: g.type,
  cells: [...cells],
  tmplEquipRefId,
  locationId,
});

export const removeLayout = (layouts, equipments, toRemove) => {
  const workingLayouts = clearSpace(layouts, equipments, toRemove);
  return workingLayouts;
};

export const addLayout = (layouts, equipments, toAdd, parentGroups) => {
  // освободим место куда добавляем
  const workingLayouts = clearSpace(layouts, equipments, toAdd);
  // доавляем новые раскладки
  workingLayouts.push(toAdd);
  parentGroups.forEach((g) => workingLayouts.push(
    buildLayout(g, toAdd.tmplEquipRefId, toAdd.locationId, toAdd.cells),
  ));
  // объедияем соседние раскладки с cовпадающими группами
  return mergeLayouts(workingLayouts);
};


export const getLayoutTree = (layouts) => {
  let nextLevel = [];
  let prevLevel = [];
  const grouped = _.groupBy(_.cloneDeep(layouts), 'groupLevel');
  const keys = _.sortBy(Object.keys(grouped), (x) => Number(x));
  const firstLvl = keys.shift();
  let maxDepth = Number(firstLvl) || 0;
  const layoutTree = grouped[firstLvl] || [];
  nextLevel = layoutTree;
  keys.forEach((lvl) => {
    maxDepth = Number(lvl);
    prevLevel = nextLevel;
    nextLevel = grouped[lvl];
    /* eslint-disable no-loop-func */
    /* eslint-disable no-param-reassign */
    nextLevel.forEach((child) => {
      prevLevel.forEach((parent) => {
        if (child.groupParentId === parent.groupId && intersects(child, parent)) {
          parent.children = parent.children ? [...parent.children, child] : [child];
        }
      });
    });
    /* eslint-enable no-loop-func */
    /* eslint-enable no-param-reassign */
  });
  return { layoutTree, maxDepth };
};

export const getNoLayouts = (locationId, layoutTree, cells, groupType) => {
  let emptyCells = cells;
  layoutTree.forEach((l) => {
    emptyCells = _.without(emptyCells, ...l.cells);
  });
  const emptyGroups = [];
  let lastGroup = [];
  let lastCell;
  emptyCells.forEach((i) => {
    if (!lastCell || i - lastCell === 1) {
      lastGroup.push(i);
    } else {
      if (lastGroup.length) {
        emptyGroups.push(lastGroup);
      }
      lastGroup = [i];
    }
    lastCell = i;
  });
  if (lastGroup.length) {
    emptyGroups.push(lastGroup);
  }
  const emptyLayouts = emptyGroups.map((g) => ({
    cells: g,
    groupId: null,
    locationId,
    groupType,
  }));
  return emptyLayouts;
};

export const isComplete = (template) => {
  let complete = true;
  _.each(template.equipments, (e) => {
    _.each(e.children, (sh) => {
      const layouts = template.layouts.filter((l) => (
        l.tmplEquipRefId === e.tmplEquipRefId
        && l.locationId === sh.locationId
      ));
      const cells = _.uniq(_.flatMap(layouts, (l) => l.cells));
      if (cells.length < sh.maxfacex) {
        complete = false;
        return false;
      }
      return undefined;
    });
    if (!complete) {
      return false;
    }
    return undefined;
  });
  return complete;
};
