import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Classes,
  Dialog,
  Intent,
} from '@blueprintjs/core';

const ActivateConflictDialog = ({
  isOpen,
  onOverwrite,
  onSkipConflicts,
  onClose,
  conflictList,
}) => (
  <Dialog
    style={{ width: '800px' }}
    icon="warning-sign"
    title="Внимание"
    isOpen={isOpen}
    onClose={onClose}
  >
    <div className={Classes.DIALOG_BODY}>
      <p>У указанных магазинов есть активные привязки:</p>
      <ul>
        {conflictList.map((wsRef) => <li key={wsRef.id}>{wsRef.wsName}</li>)}
      </ul>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button minimal icon="cross" onClick={onClose}>Отмена</Button>
        <Button
          minimal
          icon="double-chevron-right"
          onClick={onSkipConflicts}
          intent={Intent.DANGER}
        >
          Пропустить перечисленные
        </Button>
        <Button
          icon="swap-horizontal"
          onClick={onOverwrite}
          intent={Intent.DANGER}
        >
          Заменить активные
        </Button>
      </div>
    </div>
  </Dialog>
);

ActivateConflictDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOverwrite: PropTypes.func.isRequired,
  onSkipConflicts: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  conflictList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    wsName: PropTypes.string,
  })).isRequired,
};

export default ActivateConflictDialog;
