import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  H3,
  NonIdealState,
  Intent,
  Spinner,
  Toaster,
  Position,
} from '@blueprintjs/core';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';
import PageSelect from '../../widgets/PageSelect';
import {
  getTemplatesWarehouseList,
  delTmplWsRef,
  activateTmplWsRef,
  deactivateTmplWsRef,
} from '../../api';
import { useTableRequest } from '../../api/useTableRequest';
import { useRequest } from '../../api/useRequest';
import { selectCanEditTmpl } from '../../state/reducers/auth';
import AddTemplWsRefDialog from './AddTemplWsRefDialog';
import styles from './TemplateTable.module.scss';

const valueRenderer = (x) => <div className={styles.tmplCell}>{x}</div>;

const isActiveRenderer = (x) => <div className={styles.tmplCell}>{x ? 'Активно' : 'Неактивно'}</div>;

const calcRenderer = (
  history,
  onDelete,
  onActivateRef,
  onDeactivateRef,
  id,
  data,
) => (
  <div className={styles.buttonCell}>
    <Button
      className={styles.buttonCellButton}
      small
      intent={Intent.DANGER}
      icon="trash"
      onClick={() => onDelete(id)}
    >
      Удалить
    </Button>
    {data.isActive ? (
      <Button
        className={styles.togleActiveBullon}
        small
        minimal
        intent={Intent.DANGER}
        icon="pause"
        onClick={() => onDeactivateRef(id)}
      >
        Деактивировать
      </Button>
    ) : (
      <Button
        className={styles.togleActiveBullon}
        small
        minimal
        intent={Intent.SUCCESS}
        icon="play"
        onClick={() => onActivateRef(id)}
      >
        Активировать
      </Button>
    )}
    <Button
      className={styles.buttonCellButton}
      small
      intent={Intent.PRIMARY}
      icon="play"
      onClick={() => history.push(`/warehouses/${data.wsId}/planogram/${id}/preview`)}
    >
      Рассчитать
    </Button>
  </div>
);

const getFields = (
  history,
  onDelete,
  onActivateRef,
  onDeactivateRef,
  canEdit,
) => {
  const fields = [
    {
      title: 'Название',
      field: 'templateName',
      sortable: true,
      width: 150,
      valueRenderer,
    },
    {
      title: 'Описание',
      field: 'templateDescription',
      sortable: true,
      width: 230,
      valueRenderer,
    },
    {
      title: 'Активность привязки',
      field: 'isActive',
      sortable: true,
      width: 190,
      valueRenderer: isActiveRenderer,
    },
  ];
  if (canEdit) {
    fields.push({
      title: '',
      field: 'id',
      sortable: false,
      width: 385,
      valueRenderer: (id, data) => calcRenderer(
        history,
        onDelete,
        onActivateRef,
        onDeactivateRef,
        id,
        data,
      ),
    });
  }
  return fields;
};

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const getOnSuccess = (reload, message) => () => {
  reload();
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    icon: 'tick',
  });
};

const TemplateTable = ({ name }) => {
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination, setSort] = useTableRequest(getTemplatesWarehouseList);
  const { id } = useParams();
  const history = useHistory();
  const canEdit = useSelector(selectCanEditTmpl);
  const reload = () => fetch({ filters, pagination, sort });

  const [{
    fetching: deleting,
  }, deleteRef] = useRequest(delTmplWsRef, {
    onSuccess: getOnSuccess(reload, 'Привязка удалена'),
    onError,
  });
  const [{
    fetching: activating,
  }, activateRef] = useRequest(activateTmplWsRef, {
    onSuccess: getOnSuccess(reload, 'Привязка активирована'),
    onError,
  });
  const [{
    fetching: deactivating,
  }, deactivateRef] = useRequest(deactivateTmplWsRef, {
    onSuccess: getOnSuccess(reload, 'Привязка деактивирована'),
    onError,
  });

  const loading = fetching || deleting || activating || deactivating;

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  useEffect(() => {
    if (!filters.wsIdEq) {
      setFilters({ wsIdEq: id });
    } else {
      fetch({ filters, pagination, sort });
    }
  }, [fetch, setFilters, id, filters, pagination, sort]);
  const columns = useCallback(
    getColumns(getFields(
      history,
      deleteRef,
      activateRef,
      deactivateRef,
      canEdit,
    ), sort, setSort),
    [getFields, history, sort, setSort, filters, pagination],
  );
  return (
    <div className={styles.container}>
      <H3>Привязанные шаблоны</H3>
      <div className={styles.actionBar}>
        { canEdit ? (
          <Button
            icon="add"
            intent={Intent.PRIMARY}
            onClick={() => setAddDialogOpen(true)}
          >
            Создать привязку
          </Button>
        ) : null }
        <div className={styles.spacer} />
        <PageSelect
          showTotal
          showItemsPerPageChanger
          total={totals.items}
          currentPage={pagination.currentPage}
          maxPage={totals.pages || pagination.currentPage}
          currentItemsPerPage={pagination.itemsPerPage}
          onPageSelect={
            (currentPage) => { setPagination({ ...pagination, currentPage }); }
          }
          onChangeItemsPerPage={
            (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
          }
        />
      </div>
      { loading ? <Spinner /> : null }
      { error && !loading ? <NonIdealState title="Ошибка" description={error.message} icon="error" /> : null }
      { !error && !loading ? (
        <DataTable
          className={styles.table}
          defaultRowHeight={34}
          columns={columns}
          data={data}
          loadingRowCount={pagination.itemsPerPage}
          currentPage={pagination.currentPage}
          itemsPerPage={pagination.itemsPerPage}
          noDataText="Нет привязанных шаблонов"
          onDoubleClick={({ item, path }) => {
            if (path[0] === 'id') {
              return;
            }
            history.push(`/templates/${item.templateId}`);
          }}
        />
      ) : null }
      <AddTemplWsRefDialog
        isOpen={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSaved={() => {
          setAddDialogOpen(false);
          setPagination({ ...pagination, currentPage: 1 });
        }}
        wsId={Number(id)}
        wsName={name}
      />
    </div>
  );
};

TemplateTable.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TemplateTable;
