import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import {
  Card,
  Elevation,
  Icon,
  Spinner,
  Tooltip,
  NonIdealState,
} from '@blueprintjs/core';
import DraggableTree from './DraggableTree';
import {
  fetchLayoutTreeAction,
  searchLayoutTreeAction,
  expandAction,
  collapseAction,
} from '../../../state/actionCreators/layoutTree';
import SearchInput from '../../../widgets/Filters/SearchInput';
import useDebounce from '../../../utils/debounce';
import styles from './TreeCard.module.scss';

const getTreeContent = ({ nodes, lvl = 0 }) => nodes.map((x, index) => ({
  ...x,
  label: (
    <>
      <span className={styles.color} style={{ backgroundColor: x.nodeData.color }} />
      <span className={styles.labelText}>{x.label}</span>
      { x.nodeData.num ? (
        <Tooltip
          content={`Приоритет ${index + 1}`}
          className={styles.priorityBlock}
        >
          <div className={styles.priority}>
            {index + 1}
          </div>
        </Tooltip>
      ) : null }
      {_.get(x, 'nodeData.calcOptions.canOverflow') ? (
        <Tooltip
          content={(
            <span>
              Флаг, разрешающий группе
              <br />
              перетекать на другую витрину
            </span>
          )}
          className={styles.optIconBlock}
        >
          <Icon className={styles.optIcon} icon="flows" />
        </Tooltip>
      ) : <Icon className={styles.optIconBlockEmpty} icon="flows" />}
      {_.get(x, 'nodeData.calcOptions.placeToBindsOnly') ? (
        <Tooltip
          content={(
            <span>
              Флаг, запрещающий группе выставляться,
              <br />
              если она никуда не привязана
            </span>
          )}
          className={styles.optIconBlock}
        >
          <Icon className={styles.optIcon} icon="group-objects" />
        </Tooltip>
      ) : <Icon className={styles.optIconBlockEmpty} icon="group-objects" />}
      {_.get(x, 'nodeData.calcOptions.expandBindsDeny') ? (
        <Tooltip
          wrapperTagName="div"
          content={(
            <span>
              Флаг, запрещающий группе
              <br />
              расширяться за границы её привязок
            </span>
          )}
          className={styles.optIconBlock}
        >
          <span className={styles.optIconModifierWrapper}>
            <Icon icon="flow-review" />
            <Icon className={styles.optIconModifier} icon="slash" />
          </span>
        </Tooltip>
      ) : (
        <span className={classNames(styles.optIconModifierWrapper, styles.optIconBlockEmpty)}>
          <Icon icon="flow-review" />
          <Icon className={styles.optIconModifier} icon="slash" />
        </span>
      )}
      {_.get(x, 'nodeData.calcOptions.placeAllModels') ? (
        <Tooltip
          content={(
            <span>
              Разрешать расширять выкладку бренда за его границы,
              <br />
              с целью выставить все модели бренда
            </span>
          )}
          className={styles.optIconBlock}
        >
          <Icon className={styles.optIcon} icon="grid-view" />
        </Tooltip>
      ) : <Icon className={styles.optIconBlockEmpty} icon="grid-view" />}
      {_.get(x, 'nodeData.calcOptions.placeByPriceInsteadOfBrand') ? (
        <Tooltip
          content="Выставлять по цене а не по бренду"
          className={styles.optIconBlock}
        >
          <span className={styles.optIcon}>₽</span>
        </Tooltip>
      ) : <span className={styles.optIconBlockEmpty}>₽</span>}
    </>
  ),
  childNodes: x.childNodes ? getTreeContent({ nodes: x.childNodes, lvl: lvl + 1 }) : undefined,
}));

const NodeSpinner = (
  <Spinner
    size={Spinner.SIZE_SMALL}
    className={styles.nodeSpinner}
  />
);

const TreeCard = ({
  nodes,
  rootFetching,
  error,
  searchLayoutTree,
  fetchLayoutTree,
  expand,
  collapse,
}) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (!debouncedSearch) {
      fetchLayoutTree(0, []);
    } else if (debouncedSearch.length >= 3) {
      searchLayoutTree(debouncedSearch);
    }
  }, [debouncedSearch, fetchLayoutTree, searchLayoutTree]);

  useEffect(() => {
    fetchLayoutTree(0, []);
  }, [fetchLayoutTree]);
  const onNodeExpand = (node, nodePath) => {
    if (node.childNodes) {
      expand(node.id, nodePath);
    } else {
      fetchLayoutTree(
        node.id,
        nodePath,
        NodeSpinner,
      );
    }
  };
  const onNodeCollapse = (node, nodePath) => collapse(node.id, nodePath);
  const onNodeDoubleClick = (node, nodePath) => (
    node.isExpanded ? onNodeCollapse(node, nodePath) : onNodeExpand(node, nodePath)
  );

  return (
    <Card className={styles.treeCard} elevation={Elevation.TWO}>
      <div className={styles.searchWrapper}>
        <SearchInput
          name="Поиск..."
          value={search}
          onChange={(val) => setSearch(val)}
        />

      </div>
      <div className={styles.treeWrapper}>
        { !rootFetching && error ? (
          <NonIdealState
            title="Ошибка"
            description={error.message}
            icon="error"
          />
        ) : null }
        { rootFetching ? <Spinner className={styles.treeSpinner} /> : (
          <DraggableTree
            contents={getTreeContent({ nodes })}
            onNodeExpand={onNodeExpand}
            onNodeDoubleClick={onNodeDoubleClick}
            onNodeCollapse={onNodeCollapse}
          />
        )}
        { !error && !rootFetching && !nodes.length ? (
          <div className={styles.notFound}>Групп выкладки не найдено</div>
        ) : null }
      </div>
    </Card>
  );
};

TreeCard.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    isExpanded: PropTypes.bool,
    childNodes: PropTypes.array,
  })).isRequired,
  rootFetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  searchLayoutTree: PropTypes.func.isRequired,
  fetchLayoutTree: PropTypes.func.isRequired,
  expand: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired,
};

TreeCard.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  nodes: state.layoutTree.nodes,
  rootFetching: state.layoutTree.rootFetching,
  error: state.layoutTree.error,
});

const mapDispatchToProps = (dispatch) => ({
  searchLayoutTree: (search) => dispatch(searchLayoutTreeAction(search)),
  fetchLayoutTree: (id, path, spinner) => dispatch(fetchLayoutTreeAction(
    id,
    path,
    spinner,
  )),
  expand: (id, path) => dispatch(expandAction(id, path)),
  collapse: (id, path) => dispatch(collapseAction(id, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TreeCard);
