import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  Icon,
  Intent,
  Toaster,
  Tooltip,
  Position,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core';
import Page from '../../widgets/Page';
import {
  fetchLayoutTreeAction,
  searchLayoutTreeAction,
  expandAction,
  collapseAction,
  updateNodeAction,
  moveNodeAction,
  moveNodeCompleteAction,
  pinAction,
  unpinAction,
} from '../../state/actionCreators/layoutTree';
import SearchInput from '../../widgets/Filters/SearchInput';
import useDebounce from '../../utils/debounce';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import getContextMenu from './ContextMenu';
import GroupEditDialog from './GroupEditDialog';
import ReorderableTree from './ReorderableTree';
import styles from './index.module.scss';


const BREADCRUMB_ITEMS = [
  { text: 'Товарные группы' },
];

const NodeSpinner = (
  <Spinner
    size={Spinner.SIZE_SMALL}
    className={styles.nodeSpinner}
  />
);

const getTreeContent = ({ nodes, lvl = 0 }) => {
  const minWidth = `${300 - 23 * lvl}px`;
  return nodes.map((x, index) => ({
    ...x,
    label: (
      <>
        <span className={styles.color} style={{ backgroundColor: x.nodeData.color }} />
        <span className={styles.labelText} style={{ minWidth }}>{x.label}</span>
        { x.nodeData.num ? (
          <Tooltip
            content={`Приоритет ${index + 1}`}
            className={styles.priorityBlock}
          >
            <div className={styles.priority}>
              {index + 1}
            </div>
          </Tooltip>
        ) : <div className={styles.noPriority} /> }
        {_.get(x, 'nodeData.calcOptions.canOverflow') ? (
          <Tooltip
            content={(
              <span>
                Флаг, разрешающий группе
                <br />
                перетекать на другую витрину
              </span>
            )}
            className={styles.optIconBlock}
          >
            <Icon className={styles.optIcon} icon="flows" />
          </Tooltip>
        ) : <Icon className={styles.optIconBlockEmpty} icon="flows" />}
        {_.get(x, 'nodeData.calcOptions.placeToBindsOnly') ? (
          <Tooltip
            content={(
              <span>
                Флаг, запрещающий группе выставляться,
                <br />
                если она никуда не привязана
              </span>
            )}
            className={styles.optIconBlock}
          >
            <Icon className={styles.optIcon} icon="group-objects" />
          </Tooltip>
        ) : <Icon className={styles.optIconBlockEmpty} icon="group-objects" />}
        {_.get(x, 'nodeData.calcOptions.expandBindsDeny') ? (
          <Tooltip
            wrapperTagName="div"
            content={(
              <span>
                Флаг, запрещающий группе
                <br />
                расширяться за границы её привязок
              </span>
            )}
            className={styles.optIconBlock}
          >
            <span className={styles.optIconModifierWrapper}>
              <Icon icon="flow-review" />
              <Icon className={styles.optIconModifier} icon="slash" />
            </span>
          </Tooltip>
        ) : (
          <span className={classNames(styles.optIconModifierWrapper, styles.optIconBlockEmpty)}>
            <Icon icon="flow-review" />
            <Icon className={styles.optIconModifier} icon="slash" />
          </span>
        )}
        {_.get(x, 'nodeData.calcOptions.placeAllModels') ? (
          <Tooltip
            content={(
              <span>
                Разрешать расширять выкладку бренда за его границы,
                <br />
                с целью выставить все модели бренда
              </span>
            )}
            className={styles.optIconBlock}
          >
            <Icon className={styles.optIcon} icon="grid-view" />
          </Tooltip>
        ) : <Icon className={styles.optIconBlockEmpty} icon="grid-view" />}
        {_.get(x, 'nodeData.calcOptions.placeByPriceInsteadOfBrand') ? (
          <Tooltip
            content="Выставлять по цене а не по бренду"
            className={styles.optIconBlock}
          >
            <span className={styles.optIcon}>₽</span>
          </Tooltip>
        ) : <span className={styles.optIconBlockEmpty}>₽</span>}
        <span className={styles.spacer} />
        { x.nodeData.num ? <Icon className={styles.pinIcon} icon="pin" /> : null }
      </>
    ),
    childNodes: x.childNodes ? getTreeContent({ nodes: x.childNodes, lvl: lvl + 1 }) : undefined,
  }));
};

const toaster = Toaster.create({ position: Position.BOTTOM });

const onError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const LayoutTreeScreen = ({
  nodes,
  rootFetching,
  error,
  searchLayoutTree,
  fetchLayoutTree,
  expand,
  collapse,
  updateNode,
  moveNode,
  moveNodeComplete,
  pin,
  unpin,
}) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (!debouncedSearch) {
      fetchLayoutTree(0, []);
    } else if (debouncedSearch.length >= 3) {
      searchLayoutTree(debouncedSearch);
    }
  }, [debouncedSearch, fetchLayoutTree, searchLayoutTree]);

  useEffect(() => {
    fetchLayoutTree(0, []);
  }, [fetchLayoutTree]);
  const onNodeExpand = (node, nodePath) => {
    if (node.childNodes) {
      expand(node.id, nodePath);
    } else {
      fetchLayoutTree(
        node.id,
        nodePath,
      );
    }
  };
  const onNodeCollapse = (node, nodePath) => collapse(node.id, nodePath);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [group, setGroup] = useState({});
  const [currentPath, setCurrentPath] = useState(null);
  const onEdit = (node, nodePath) => {
    setCurrentPath(nodePath);
    setGroup(node.nodeData);
    setDialogOpen(true);
  };

  return (
    <Page>
      <BreadcrumbNav items={BREADCRUMB_ITEMS} />
      <div className={styles.searchWrapper}>
        <SearchInput
          name="Поиск..."
          value={search}
          onChange={(val) => setSearch(val)}
        />

      </div>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.treeWrapper}>
          { !rootFetching && error ? (
            <NonIdealState
              title="Ошибка"
              description={error.message}
              icon="error"
            />
          ) : null }
          { rootFetching ? <Spinner className={styles.treeSpinner} /> : (
            <ReorderableTree
              onNodeContextMenu={getContextMenu(onEdit, pin, unpin)}
              contents={getTreeContent({ nodes })}
              onNodeExpand={onNodeExpand}
              onNodeDoubleClick={onEdit}
              onNodeCollapse={onNodeCollapse}
              onMoveNode={moveNode}
              onMoveNodeComplete={(id, path, data) => moveNodeComplete(id, path, data, NodeSpinner)}
            />
          )}
          { !error && !rootFetching && !nodes.length ? (
            <div className={styles.notFound}>Групп выкладки не найдено</div>
          ) : null }
        </div>
      </DndProvider>
      <GroupEditDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onSaved={(data) => {
          updateNode(data.id, currentPath, data);
          setDialogOpen(false);
        }}
        group={group}
      />
    </Page>
  );
};

LayoutTreeScreen.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    isExpanded: PropTypes.bool,
    childNodes: PropTypes.array,
  })).isRequired,
  rootFetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  searchLayoutTree: PropTypes.func.isRequired,
  fetchLayoutTree: PropTypes.func.isRequired,
  expand: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
  moveNode: PropTypes.func.isRequired,
  moveNodeComplete: PropTypes.func.isRequired,
  pin: PropTypes.func.isRequired,
  unpin: PropTypes.func.isRequired,
};

LayoutTreeScreen.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  nodes: state.layoutTree.nodes,
  rootFetching: state.layoutTree.rootFetching,
  error: state.layoutTree.error,
});

const mapDispatchToProps = (dispatch) => ({
  searchLayoutTree: (search) => dispatch(searchLayoutTreeAction(search)),
  fetchLayoutTree: (id, path) => dispatch(fetchLayoutTreeAction(
    id,
    path,
    NodeSpinner,
  )),
  expand: (id, path) => dispatch(expandAction(id, path)),
  collapse: (id, path) => dispatch(collapseAction(id, path)),
  updateNode: (id, path, data) => dispatch(updateNodeAction(id, path, data)),
  moveNode: (id, path, oldIndex, newIndex) => dispatch(moveNodeAction(
    id,
    path,
    oldIndex,
    newIndex,
  )),
  moveNodeComplete: (id, path, data) => dispatch(moveNodeCompleteAction(
    id,
    path,
    data,
    NodeSpinner,
    onError,
  )),
  pin: (id, path, data) => dispatch(pinAction(
    id,
    path,
    data,
    NodeSpinner,
    onError,
  )),
  unpin: (id, path, data) => dispatch(unpinAction(
    id,
    path,
    data,
    NodeSpinner,
    onError,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutTreeScreen);
