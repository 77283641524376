import React from 'react';
import PropTypes from 'prop-types';
import {
  Cell, Column, Table, RenderMode, RowHeaderCell, SelectionModes,
} from '@blueprintjs/table';
import { NonIdealState, Button } from '@blueprintjs/core';
import classNames from 'classnames';
import { valueByPath } from '../../utils/object';
import styles from './DataTable.module.css';

const DataCell = ({
  loading,
  data,
  path,
  valueRenderer,
  onDoubleClick,
}) => {
  const value = loading ? '' : valueByPath(data, path);
  if (onDoubleClick) {
    return (
      <Cell interactive loading={loading}>
        <>
          <div onDoubleClick={() => onDoubleClick({ item: data, path })}>
            {valueRenderer(value, data)}
          </div>
        </>
      </Cell>
    );
  }
  return (
    <Cell loading={loading}>
      {valueRenderer(value, data)}
    </Cell>
  );
};

DataCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  valueRenderer: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func,
};

DataCell.defaultProps = {
  onDoubleClick: undefined,
};

const getSortIcon = (sortDirection) => {
  if (sortDirection) {
    if (sortDirection === 'asc') {
      return 'sort-asc';
    }
    return 'sort-desc';
  }
  return 'sort';
};

const getSortableNameRenderer = ({ sortDirection, onSort }) => (name) => (
  // TODO: remove 'bp-table-text-no-measure' and columnHeaderMeasureFix
  // after blueprintjs autosize bug fixed
  <div className={styles.columnHeaderContainer}>
    <div className={classNames(styles.columnHeaderWrapper, 'bp-table-text-no-measure')}>
      <div className={styles.columnHeaderText}>
        {name}
      </div>
      <Button
        small
        minimal
        icon={getSortIcon(sortDirection)}
        className={classNames(styles.sortButton, 'bp3-table-reorder-handle-target')}
        onClick={onSort}
      />
    </div>
    <div className={styles.columnHeaderMeasureFix}>
      {name}
      {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
    </div>
  </div>
);

const DataColumn = ({
  loading, data, title, path, valueRenderer,
  sortable, sortDirection, onSort, onDoubleClick,
}) => {
  const isStringTitle = typeof title === 'string';
  const name = isStringTitle ? title : '';
  let nameRenderer;
  if (sortable) {
    nameRenderer = getSortableNameRenderer({ sortDirection, onSort });
  } else if (!isStringTitle) {
    nameRenderer = () => title;
  }

  return (
    <Column
      key={path.join()}
      name={name}
      cellRenderer={(rowIndex) => DataCell({
        loading,
        data: data[rowIndex],
        path,
        valueRenderer: valueRenderer || ((x) => x),
        onDoubleClick,
      })}
      nameRenderer={nameRenderer}
    />
  );
};

DataColumn.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  valueRenderer: PropTypes.func,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  onDoubleClick: PropTypes.func,
  sortable: PropTypes.bool,
};

DataColumn.defaultProps = {
  valueRenderer: (x) => x,
  sortable: false,
  onSort: undefined,
  onDoubleClick: undefined,
  sortDirection: undefined,
};

const getCellClipboardData = (data, columns) => (row, col) => {
  const { path } = columns[col];
  const value = valueByPath(data[row], path);
  return value;
};

const getContextMenuRenderer = (data, columns, ContextMenu) => {
  if (!ContextMenu) {
    return null;
  }
  const menuRenderer = (context) => {
    const target = context.getTarget();
    if (data && data.length && target && target.cols && target.rows) {
      const [row] = target.rows;
      const [col] = target.cols;
      const item = data[row];
      const { path } = columns[col];
      return <ContextMenu item={item} path={path} />;
    }
    return undefined;
  };
  return menuRenderer;
};

const DataTable = ({
  columns,
  data,
  loadingRowCount,
  loading,
  className,
  defaultRowHeight,
  defaultColumnWidth,
  enableRowHeader,
  contextMenu,
  onDoubleClick,
  itemsPerPage,
  currentPage,
  noDataText,
  selectionModes,
}) => {
  if (!loading && data.length === 0) {
    return <NonIdealState className={styles.noData} title={noDataText} icon="list" />;
  }

  return (
    <Table
      defaultRowHeight={defaultRowHeight}
      enableRowHeader={enableRowHeader}
      columnWidths={columns.map((c) => c.width || defaultColumnWidth)}
      numRows={loading ? loadingRowCount : data.length}
      renderMode={itemsPerPage > 100 ? RenderMode.BATCH_ON_UPDATE : RenderMode.NONE}
      selectionModes={selectionModes}
      className={className}
      getCellClipboardData={getCellClipboardData(data, columns)}
      bodyContextMenuRenderer={getContextMenuRenderer(data, columns, contextMenu)}
      rowHeaderCellRenderer={(rowIndex) => (
        <RowHeaderCell
          index={rowIndex}
          name={`${itemsPerPage * (currentPage - 1) + rowIndex + 1}`}
        />
      )}
    >
      {columns.map((column) => DataColumn({
        loading,
        data,
        onDoubleClick,
        ...column,
      }))}
    </Table>
  );
};

const columnPropType = PropTypes.shape({
  title: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.number,
});

DataTable.propTypes = {
  columns: PropTypes.arrayOf(columnPropType).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingRowCount: PropTypes.number,
  loading: PropTypes.bool,
  defaultColumnWidth: PropTypes.number,
  defaultRowHeight: PropTypes.number,
  className: PropTypes.string,
  enableRowHeader: PropTypes.bool,
  contextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  noDataText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  selectionModes: PropTypes.any,
};

DataTable.defaultProps = {
  currentPage: 1,
  itemsPerPage: 100,
  loadingRowCount: 100,
  loading: false,
  defaultRowHeight: undefined,
  defaultColumnWidth: 200,
  className: '',
  enableRowHeader: true,
  contextMenu: null,
  onDoubleClick: undefined,
  noDataText: 'Нет данных',
  selectionModes: SelectionModes.ALL,
};

export default DataTable;
