import React, { PureComponent } from 'react';
import { Select } from '@blueprintjs/select';
import {
  Button,
  MenuItem,
  Menu,
  Intent,
  Position,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';

export const getItems = (data) => data.map((x) => ({
  title: x.name,
  key: x.id,
  value: x.id,
}));

export const getRespItems = (resp) => {
  const data = resp ? resp.data : [];
  return data.map((x) => ({ title: x.name, key: x.id, value: x.id }));
};

export default class SimpleSelect extends PureComponent {
  constructor() {
    super();

    this.state = {
      search: '',
    };
  }

  onSearchChange(search) {
    this.setState({ search });
  }

  filterItem = (query, item) => {
    if (query.trim() === '') {
      return true;
    }
    if (!item.title) {
      return false;
    }
    return item.title.toString().match(new RegExp(this.escapeRegExpChars(query), 'i')) !== null;
  }

  escapeRegExpChars = (text) => text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')

  highlightSearch(text) {
    const { search } = this.state;
    let lastIndex = 0;
    const words = search
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map(this.escapeRegExpChars);

    if (words.length === 0) {
      return [text];
    }

    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const { length } = match[0];
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  }

  renderEmptier(active) {
    const { emptyText, onChange } = this.props;
    return (
      <MenuItem
        active={active}
        intent={active ? Intent.PRIMARY : undefined}
        key="emptier"
        text={emptyText}
        onClick={() => onChange(null)}
      />
    );
  }

  renderItem(item, { handleClick, modifiers }) {
    if (item.isEmptier) {
      return this.renderEmptier(modifiers.active);
    }
    return (
      <MenuItem
        active={modifiers.active}
        intent={modifiers.active ? Intent.PRIMARY : undefined}
        key={item.key.toString()}
        text={this.highlightSearch(item.title)}
        onClick={handleClick}
      />
    );
  }

  renderItemList = ({ items, itemsParentRef, renderItem }) => {
    let renderedItems = items.map(renderItem).filter((item) => item != null);
    if (renderedItems.length === 0) {
      renderedItems = (<MenuItem disabled text="Не найдено." />);
    }
    return (
      <Menu ulRef={itemsParentRef}>
        {renderedItems}
      </Menu>
    );
  }

  render() {
    const { search } = this.state;
    const {
      items,
      icon,
      emptyText,
      filterable,
      emptiable,
      disabled,
      value,
      onChange,
      menuAlign,
    } = this.props;

    const selectedItem = items.find((i) => i.value === value);
    let itemsToRender = items.filter((item) => ['number', 'string'].includes(typeof item.title));
    if (filterable && search) {
      itemsToRender = itemsToRender.filter((item) => this.filterItem(search, item));
    }
    if (emptiable && !search) {
      itemsToRender = [
        { isEmptier: true },
        ...itemsToRender,
      ];
    }

    return (
      <Select
        disabled={disabled}
        items={itemsToRender}
        itemRenderer={(item, opts) => this.renderItem(item, opts)}
        onItemSelect={(item) => onChange(item.value)}
        onQueryChange={(query) => this.onSearchChange(query)}
        itemPredicate={(query, item) => this.filterItem(query, item)}
        filterable={filterable}
        itemListRenderer={(params) => this.renderItemList(params)}
        popoverProps={{ position: menuAlign }}
      >
        <Button
          disabled={disabled}
          icon={icon}
          rightIcon="caret-down"
          text={selectedItem ? selectedItem.title : emptyText}
        />
      </Select>
    );
  }
}

SimpleSelect.propTypes = {
  icon: PropTypes.string,
  emptyText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.any,
    key: PropTypes.any,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  filterable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  emptiable: PropTypes.bool,
  disabled: PropTypes.bool,
  menuAlign: PropTypes.string,
};

SimpleSelect.defaultProps = {
  emptyText: 'Не выбрано',
  icon: null,
  filterable: false,
  emptiable: false,
  disabled: false,
  value: null,
  menuAlign: Position.BOTTOM_LEFT,
};
