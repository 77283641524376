import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as ACTIONS from '../actions';

const parseNode = (item, level, parents) => {
  const nodeData = {
    ...item,
    loading: false,
    level,
    parents,
  };
  const childNodes = item.children ? item.children.map((x) => parseNode(
    x,
    level + 1,
    [...parents, nodeData],
  )) : undefined;
  return ({
    id: item.id,
    label: item.name,
    icon: item.isLeaf ? 'document' : 'folder-close',
    hasCaret: !item.isLeaf,
    isExpanded: !!item.children,
    childNodes,
    nodeData,
  });
};

const INITIAL_STATE = Immutable({
  nodes: [],
  rootFetching: false,
  error: null,
});

const collectParentGroups = (nodes, path) => {
  const groups = [];
  let level = nodes;
  path.forEach((i) => {
    const node = level[i];
    groups.unshift(node.nodeData);
    level = node.childNodes;
  });
  return groups;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.LAYOUT_TREE_FETCH_START: {
      const { id, path, spinner } = action.payload;
      if (id) {
        const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
        nodePath.unshift('nodes');
        nodePath.pop();
        let node = state.getIn(nodePath);
        node = {
          ...node,
          isExpanded: true,
          childNodes: undefined,
          icon: spinner,
          nodeData: {
            ...node.nodeData,
            loading: true,
          },
        };
        return state.setIn(nodePath, node);
      }
      return state.merge({ rootFetching: true });
    }
    case ACTIONS.LAYOUT_TREE_FETCH_DONE: {
      const {
        id,
        path,
        nodes,
      } = action.payload;
      const parents = collectParentGroups(state.nodes, path);
      const parsedNodes = _.sortBy(nodes, ['num', 'isLeaf', 'name']).map((x) => parseNode(x, path.length, parents));
      let nextState = state.merge({
        rootFetching: false,
        error: null,
      });
      if (id) {
        const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
        nodePath.unshift('nodes');
        nodePath.pop();
        let node = nextState.getIn(nodePath);
        node = {
          ...node,
          icon: 'folder-open',
          childNodes: parsedNodes,
          nodeData: {
            ...node.nodeData,
            loading: false,
          },
        };
        nextState = nextState.setIn(nodePath, node);
      } else {
        nextState = nextState.merge({ nodes: parsedNodes });
      }
      return nextState;
    }
    case ACTIONS.LAYOUT_TREE_FETCH_ERROR:
      return state.merge({ rootFetching: false, error: action.error });
    case ACTIONS.LAYOUT_TREE_EXPAND: {
      const { path } = action.payload;
      const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
      nodePath.unshift('nodes');
      nodePath.pop();
      let node = state.getIn(nodePath);
      node = {
        ...node,
        icon: 'folder-open',
        isExpanded: true,
      };
      return state.setIn(nodePath, node);
    }
    case ACTIONS.LAYOUT_TREE_COLLAPSE: {
      const { path } = action.payload;
      const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
      nodePath.unshift('nodes');
      nodePath.pop();
      let node = state.getIn(nodePath);
      node = {
        ...node,
        icon: 'folder-close',
        isExpanded: false,
      };
      return state.setIn(nodePath, node);
    }
    case ACTIONS.LAYOUT_TREE_UPDATE_NODE: {
      const { path, data } = action.payload;
      const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
      nodePath.unshift('nodes');
      nodePath.pop();
      let node = state.getIn(nodePath);
      node = {
        ...node,
        nodeData: {
          ...data,
          loading: node.nodeData.loading,
          level: node.nodeData.level,
          parents: node.nodeData.parents,
        },
      };
      return state.setIn(nodePath, node);
    }
    case ACTIONS.LAYOUT_TREE_MOVE_NODE: {
      const {
        path,
        oldIndex,
        newIndex,
      } = action.payload;
      const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
      nodePath.unshift('nodes');
      nodePath.pop();
      nodePath.pop();
      const childNodes = state.getIn(nodePath).asMutable();
      const [node] = childNodes.splice(oldIndex, 1);
      childNodes.splice(newIndex, 0, node);
      return state.setIn(nodePath, childNodes);
    }
    case ACTIONS.LAYOUT_TREE_PRIORITIZE_START: {
      const {
        id,
        path,
        spinner,
        actionType,
      } = action.payload;
      const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
      nodePath.unshift('nodes');
      nodePath.pop();
      const oldIndex = nodePath.pop();
      let childNodes = [];
      if (actionType === 'pin') {
        childNodes = state.getIn(nodePath).asMutable();
        let [node] = childNodes.splice(oldIndex, 1);
        const newIndex = _.findIndex(childNodes, (x) => !x.nodeData.num);
        node = {
          ...node,
          icon: spinner,
          oldNum: node.nodeData.num,
          nodeData: {
            ...node.nodeData,
            num: -1, // временно на момент загрузки
            loading: true,
          },
        };
        childNodes.splice(newIndex, 0, node);
      } else if (actionType === 'unpin') {
        childNodes = state.getIn(nodePath).asMutable();
        let node = childNodes[oldIndex];
        node = {
          ...node,
          icon: spinner,
          oldNum: node.nodeData.num,
          nodeData: {
            ...node.nodeData,
            num: null,
            loading: true,
          },
        };
        childNodes[oldIndex] = node;
        childNodes = _.sortBy(childNodes, [
          (x) => x.nodeData.num,
          (x) => x.nodeData.isLeaf,
          (x) => x.nodeData.name,
        ]);
      } else {
        childNodes = state.getIn(nodePath).asMutable();
        const newIndex = _.findIndex(childNodes, (x) => x.id === id);
        let node = childNodes[newIndex];
        node = {
          ...node,
          icon: spinner,
          oldNum: node.nodeData.num,
          nodeData: {
            ...node.nodeData,
            loading: true,
          },
        };
        childNodes[newIndex] = node;
      }
      return state.setIn(nodePath, childNodes);
    }
    case ACTIONS.LAYOUT_TREE_PRIORITIZE_DONE: {
      const {
        id,
        path,
      } = action.payload;
      const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
      nodePath.unshift('nodes');
      nodePath.pop();
      nodePath.pop();
      const childNodes = state.getIn(nodePath).asMutable({ deep: true }).map((x, index) => {
        if (x.id === id) {
          let icon;
          if (x.nodeData.isLeaf) {
            icon = 'document';
          } else if (x.childNodes) {
            icon = 'folder-open';
          } else {
            icon = 'folder-close';
          }
          return {
            ...x,
            icon,
            oldNum: undefined,
            nodeData: {
              ...x.nodeData,
              num: x.nodeData.num ? index + 1 : null,
              loading: false,
            },
          };
        }
        if (x.nodeData.num) {
          return {
            ...x,
            nodeData: {
              ...x.nodeData,
              num: index + 1,
            },
          };
        }
        return x;
      });
      const sortedNodes = _.sortBy(childNodes, [
        (x) => x.nodeData.num,
        (x) => x.nodeData.isLeaf,
        (x) => x.nodeData.name,
      ]);
      return state.setIn(nodePath, sortedNodes);
    }
    case ACTIONS.LAYOUT_TREE_PRIORITIZE_ERROR: {
      const {
        id,
        path,
      } = action.payload;
      const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
      nodePath.unshift('nodes');
      nodePath.pop();
      nodePath.pop();
      const childNodes = state.getIn(nodePath).asMutable({ deep: true }).map((x) => {
        if (x.id === id) {
          let icon;
          if (x.nodeData.isLeaf) {
            icon = 'document';
          } else if (x.childNodes) {
            icon = 'folder-open';
          } else {
            icon = 'folder-close';
          }
          return {
            ...x,
            icon,
            oldNum: undefined,
            nodeData: {
              ...x.nodeData,
              num: x.oldNum,
              loading: false,
            },
          };
        }
        return x;
      });
      const sortedNodes = _.sortBy(childNodes, [
        (x) => x.nodeData.num,
        (x) => x.nodeData.isLeaf,
        (x) => x.nodeData.name,
      ]);
      return state.setIn(nodePath, sortedNodes);
    }
    default:
      return state;
  }
};

export default reducer;
