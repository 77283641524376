export const valueByPath = (object, path) => {
  if (!Array.isArray(path)) {
    throw new Error('path must be array');
  }

  if (!object) {
    return undefined;
  }

  return path.reduce((obj, key) => obj && obj[key], object);
};

export default 0;
