import React, { useEffect, useState } from 'react';

import {
  NonIdealState,
  Tag,
  Intent,
} from '@blueprintjs/core';

import { getUserList, activateUser, deactivateUser } from '../../api';
import { useRequest } from '../../api/useRequest';
import { useTableRequest } from '../../api/useTableRequest';

import { onError, onActivateSuccess, onDeactivateSuccess } from './utils';
import ActionBar from './ActionBar';
import getContextMenu from './ContextMenu';
import UserViewDialog from './UserViewDialog';
import UserEditDialog from './UserEditDialog';
import ToggleBlockedAlert from './ToggleBlockedAlert';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import SearchInput from '../../widgets/Filters/SearchInput';
import DataTable from '../../widgets/DataTable';
import { getColumns } from '../../widgets/DataTable/helpers';
import styles from './index.module.scss';

const BREADCRUMB_ITEMS = [
  { text: 'Пользователи' },
];

const valueRenderer = (x) => <div className={styles.userCell}>{x}</div>;

const blockedRenderer = (x) => {
  const content = x ? <Tag icon="lock" minimal round intent={Intent.DANGER}>Заблокирован</Tag> : null;
  return <div className={styles.userCell}>{content}</div>;
};

const FIELDS = [
  {
    title: 'Логин',
    field: 'login',
    sortable: true,
    width: 150,
    valueRenderer,
  },
  {
    title: 'Фамилия',
    field: 'lastname',
    sortable: true,
    width: 180,
    valueRenderer,
  },
  {
    title: 'Имя',
    field: 'firstname',
    sortable: true,
    width: 180,
    valueRenderer,
  },
  {
    title: 'Отчество',
    field: 'secondname',
    sortable: true,
    width: 180,
    valueRenderer,
  },
  {
    title: 'Роль',
    field: 'role_name',
    path: ['role', 'name'],
    sortable: false,
    width: 150,
    valueRenderer,
  },
  {
    title: 'Заблокирован',
    field: 'blocked',
    sortable: false,
    width: 150,
    valueRenderer: blockedRenderer,
  },
];


const UserListScreen = () => {
  const [{
    fetching,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetch, setFilters, setPagination, setSort] = useTableRequest(getUserList);
  const [{ fetching: activating }, doActivateUser] = useRequest(activateUser, {
    onError,
    onSuccess: onActivateSuccess(() => fetch({ filters, pagination, sort })),
  });
  const [{ fetching: deactivating }, doDeactivateUser] = useRequest(deactivateUser, {
    onError,
    onSuccess: onDeactivateSuccess(() => fetch({ filters, pagination, sort })),
  });

  const [curUser, setCurUser] = useState({});
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onView = (user) => {
    setCurUser(user);
    setIsViewOpen(true);
    setIsEditOpen(false);
  };
  const onViewClose = () => {
    setIsViewOpen(false);
    setIsEditOpen(false);
  };

  const onEdit = (user) => {
    setCurUser(user);
    setIsViewOpen(false);
    setIsEditOpen(true);
  };
  const onEditClose = () => {
    setIsViewOpen(false);
    setIsEditOpen(false);
  };
  const onToggleLock = (user) => {
    setIsAlertOpen(true);
    setCurUser(user);
  };
  useEffect(() => { fetch({ filters, pagination, sort }); }, [fetch, filters, pagination, sort]);
  return (
    <Page>
      <BreadcrumbNav items={BREADCRUMB_ITEMS} />
      <SearchInput
        name="Поиск..."
        value={filters.search}
        onChange={(search) => setFilters({ ...filters, search })}
      />
      <ActionBar
        onCreate={() => onEdit({})}
        totals={totals}
        pagination={pagination}
        setPagination={setPagination}
      />
      {
        error
          ? <NonIdealState title="Ошибка" description={error.message} icon="error" />
          : (
            <DataTable
              className={styles.table}
              defaultRowHeight={30}
              columns={getColumns(FIELDS, sort, setSort)}
              data={data}
              loading={fetching || activating || deactivating}
              loadingRowCount={pagination.itemsPerPage}
              currentPage={pagination.currentPage}
              itemsPerPage={pagination.itemsPerPage}
              contextMenu={getContextMenu({
                onView,
                onEdit,
                onToggleLock,
              })}
              onDoubleClick={({ item: user }) => onView(user)}
            />
          )
      }
      <UserViewDialog
        user={curUser}
        setUser={setCurUser}
        isOpen={isViewOpen}
        onClose={onViewClose}
        onEdit={onEdit}
        onActiveToggled={() => fetch({ filters, pagination, sort })}
      />
      <UserEditDialog
        user={curUser}
        setUser={setCurUser}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onSaved={() => {
          onEditClose();
          fetch({ filters, pagination, sort });
        }}
      />
      <ToggleBlockedAlert
        user={curUser}
        isOpen={isAlertOpen}
        onCancel={() => setIsAlertOpen(false)}
        onConfirm={() => {
          if (curUser) {
            if (curUser.blocked) {
              doActivateUser(curUser.id);
            } else {
              doDeactivateUser(curUser.id);
            }
          }
          setIsAlertOpen(false);
        }}
      />
    </Page>
  );
};

export default UserListScreen;
