import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  H3,
  Button,
  Card,
  Elevation,
  FormGroup,
  InputGroup,
  Intent,
  Position,
  TextArea,
} from '@blueprintjs/core';
import _ from 'lodash';
import SimpleSelect, { getItems } from '../../widgets/SimpleSelect';
import styles from './GeneralStep.module.scss';


const GeneralStep = ({
  template,
  updateTemplate,
  types,
  onNext,
}) => {
  const typeItems = getItems(types);
  const history = useHistory();
  const onClose = () => history.push('/templates');
  const invalid = (!template.name || !template.typeId);
  return (
    <Card elevation={Elevation.TWO} className={styles.card}>
      <H3>
        {template.name}
        &nbsp;
      </H3>
      <FormGroup
        inline
        label="Название:"
        className={styles.detailsRow}
        helperText={!template.name ? 'Обязательно для заполнения' : null}
        intent={!template.name ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          fill
          intent={!template.name ? Intent.DANGER : Intent.NONE}
          value={template.name}
          onChange={(e) => updateTemplate({ ...template, name: e.target.value })}
        />
      </FormGroup>
      <FormGroup
        inline
        label="Тип шаблона:"
        className={styles.detailsRow}
        helperText={!template.typeId ? 'Обязательно для заполнения' : null}
        intent={!template.typeId ? Intent.DANGER : Intent.NONE}
      >
        <SimpleSelect
          emptiable
          items={typeItems}
          value={template.typeId}
          onChange={(id) => {
            const tmplType = _.find(types, ['id', id]) || {};
            updateTemplate({
              ...template,
              typeId: id,
              typeName: tmplType.name,
              typePriority: tmplType.priority,
            });
          }}
          menuAlign={Position.BOTTOM_LEFT}
        />
      </FormGroup>
      <FormGroup
        inline
        label="Описание:"
        className={styles.detailsRow}
      >
        <TextArea
          fill
          value={template.description || ''}
          onChange={(e) => updateTemplate({ ...template, description: e.target.value })}
        />
      </FormGroup>
      <div className={styles.buttonRow}>
        <Button icon="caret-left" onClick={onClose}>К списку</Button>
        <Button
          disabled={invalid}
          rightIcon="caret-right"
          onClick={onNext}
          intent={Intent.PRIMARY}
        >
          Далее
        </Button>
      </div>
    </Card>
  );
};

GeneralStep.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    typeId: PropTypes.number,
    typeName: PropTypes.string,
    typePriority: PropTypes.number,
  }).isRequired,
  updateTemplate: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    priority: PropTypes.number,
  })).isRequired,
  onNext: PropTypes.func.isRequired,
};

export default GeneralStep;
