import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, Breadcrumbs } from '@blueprintjs/core';

const BreadcrumbRenderer = ({ text, href }) => (
  href ? (
    <Link to={href}>
      <Breadcrumb>{text}</Breadcrumb>
    </Link>
  ) : (
    <Breadcrumb current>{text}</Breadcrumb>
  )
);
BreadcrumbRenderer.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
};
BreadcrumbRenderer.defaultProps = {
  text: '',
  href: '',
};

const BreadcrumbNav = ({ items }) => (
  <Breadcrumbs
    items={items}
    breadcrumbRenderer={BreadcrumbRenderer}
  />
);

BreadcrumbNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  })).isRequired,
};

export default BreadcrumbNav;
