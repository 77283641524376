import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Spinner, NonIdealState, Button } from '@blueprintjs/core';
import Page from '../../widgets/Page';
import { logoutAction } from '../../state/actionCreators/auth';


const LogoutScreen = ({
  isLoggedIn,
  isLoggingOut,
  logoutError,
  logout,
}) => {
  useEffect(() => { logout(); }, [logout]);

  return (
    <Page>
      {isLoggedIn === false && <Redirect to="/login" />}
      {isLoggingOut && <Spinner />}
      {logoutError && (
        <NonIdealState
          title="Возникла ошибка"
          description={logoutError.message}
          icon="error"
        >
          <Button text="Повторить" onClick={logout} />
        </NonIdealState>
      )}
    </Page>
  );
};

LogoutScreen.propTypes = {
  logout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  isLoggingOut: PropTypes.bool.isRequired,
  logoutError: PropTypes.instanceOf(Error),
};

LogoutScreen.defaultProps = {
  isLoggedIn: null,
  logoutError: null,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
});

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.loggedIn,
  isLoggingOut: state.auth.loggingOut,
  logoutError: state.auth.logoutError,
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutScreen);
