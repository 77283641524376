/**
 * Хук для вызова АПИ с пашинацией и фильтрацией
 * по мотивам https://github.com/react-rekindle/use-request
 *
 * @example
 * const getTableData = () => axios.get('/api/table-data');
 * const [{
 *   fetching,
 *   data,
 *   error,
 *   filters,
 *   pagination,
 *   sort,
 *   totals,
 * }, fetch, setFilters, setPagination, setSort] = useTableRequest(getTableData);
 * setFilters({ search: 'qwe' });
 * setPagination({ currentPage: 2, itemsPerPage: 20 });
 * setSort({ field: 'id', direction: 'asc' });
 * fetch({ filters, pagination, sort });
 */
import { useReducer, useCallback } from 'react';
import { buildQueryParams } from './utils';

const REQUEST_START = 'REQUEST_START';
const REQUEST_DONE = 'REQUEST_DONE';
const REQUEST_ERROR = 'REQUEST_ERROR';
const SET_FILTERS = 'SET_FILTERS';
const SET_PAGINATION = 'SET_PAGINATION';
const SET_SORT = 'SET_SORT';

const defaultInitialState = {
  fetching: false,
  data: [],
  error: null,
  filters: {},
  sort: undefined,
  pagination: {
    itemsPerPage: 100,
    currentPage: 1,
  },
  totals: {
    items: 0,
    pages: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        data: [],
        filters: action.payload,
        pagination: {
          ...state.pagination,
          currentPage: 1,
        },
        totals: {
          items: 0,
          pages: 0,
        },
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case REQUEST_START:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case REQUEST_DONE:
    {
      const { data } = action.payload;
      const { itemsPerPage } = state.pagination;
      const items = action.payload.rowcount;
      const pages = Math.max(1, Math.ceil(items / itemsPerPage));
      const totals = {
        items,
        pages,
      };
      return {
        ...state,
        fetching: false,
        error: null,
        data,
        totals,
      };
    }
    case REQUEST_ERROR:
      return { ...state, fetching: false, error: action.error };
    default:
      throw new Error('error');
  }
};

const tableRequest = async (
  fetcher,
  dispatch,
  filters,
  pagination,
  sort,
) => {
  try {
    dispatch({ type: REQUEST_START, payload: {} });
    const params = buildQueryParams({ filters, pagination, sort });
    const result = await fetcher(params);
    dispatch({ type: REQUEST_DONE, payload: result });
    return result;
  } catch (error) {
    dispatch({ type: REQUEST_ERROR, error });
    return undefined;
  }
};

const setFilters = (dispatch) => (filters) => dispatch({
  type: SET_FILTERS,
  payload: filters,
});

const setPagination = (dispatch) => (pagination) => dispatch({
  type: SET_PAGINATION,
  payload: pagination,
});

const setSort = (dispatch) => (sort) => dispatch({
  type: SET_SORT,
  payload: sort,
});

export const useTableRequest = (
  fetcher,
  {
    initialState,
  } = {},
) => {
  const initialIState = {
    ...defaultInitialState,
    ...initialState,
  };

  const [state, dispatch] = useReducer(reducer, initialIState);
  const requestCallback = ({ filters, pagination, sort } = {}) => tableRequest(
    fetcher,
    dispatch,
    filters,
    pagination,
    sort,
  );
  const memoizedRequestCallback = useCallback(requestCallback, []);
  const memoizedSetFilters = useCallback(setFilters(dispatch), []);
  const memoizedSetPagination = useCallback(setPagination(dispatch), []);
  const memoizedSetSort = useCallback(setSort(dispatch), []);
  return [
    state,
    memoizedRequestCallback,
    memoizedSetFilters,
    memoizedSetPagination,
    memoizedSetSort,
  ];
};

export default useTableRequest;
