import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  Spinner,
  Elevation,
  NonIdealState,
  Toaster,
  Position,
  Intent,
} from '@blueprintjs/core';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useRequest } from '../../api/useRequest';
import { getPlanogramTmplTypeList } from '../../api';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import Steps from '../../widgets/Steps';
import GeneralStep from './GeneralStep';
import EquipmentStep from './EquipmentStep';
import LayoutStep from './LayoutStep';
import RouteLeaveGuard from './RouteLeaveGuard';
import {
  newTemplateAction,
  fetchTemplateAction,
  updateTemplateAction,
  saveTemplateAction,
} from '../../state/actionCreators/tmplWizard';
import { isComplete } from '../../state/reducers/tmplWizard/utils';
import { selectHasUnsavedChanges } from '../../state/reducers/tmplWizard/template';
import styles from './index.module.scss';

const getBreadcrumbItems = (isDraft) => [
  { text: 'Шаблоны планограмм', href: '/templates' },
  { text: isDraft ? 'Создание шаблона планограммы' : 'Изменение шаблона планограммы' },
];

const steps = [
  { title: 'Шаг 1', subtitle: 'Основние поля' },
  { title: 'Шаг 2', subtitle: 'Выбор оборудования' },
  { title: 'Шаг 3', subtitle: 'Привязка групп выкладки' },
];

const toaster = Toaster.create({ position: Position.BOTTOM });

const onSaveError = (error) => toaster.show({
  intent: Intent.DANGER,
  message: error.message,
  icon: 'error',
});

const PlanogramTmplWizardScreen = ({
  template,
  loading,
  error,
  hasUnsavedChanges,
  setNewTemplate,
  fetchTemplate,
  updateTemplate,
  saveTemplate,
}) => {
  const { id } = useParams();
  const isNew = id === 'new';
  const { draftStep, draft: isDraft } = template;
  const [activeIndex, setActiveIndex] = useState(draftStep);
  const history = useHistory();
  useEffect(() => { setActiveIndex(draftStep); }, [draftStep]);
  useEffect(() => {
    if (isNew) {
      setNewTemplate();
    } else {
      fetchTemplate(id);
    }
  }, [id, isNew, setNewTemplate, fetchTemplate]);

  const [{ data: types }, fetchTypes] = useRequest(getPlanogramTmplTypeList);
  useEffect(() => { fetchTypes(); }, [fetchTypes]);
  const step1Invalid = (!template.name || !template.typeId);
  const step2Invalid = (
    template.equipments.length === 0
    || template.equipments.filter((x) => !x.title).length > 0
  );
  const step3Invalid = !isComplete(template);
  const isInvalid = step1Invalid || step2Invalid || step3Invalid;

  const onSave = () => saveTemplate({
    ...template,
    draft: template.draft || isInvalid,
    draftStep: activeIndex,
  }, null, onSaveError);

  return (
    <Page flexible>
      <RouteLeaveGuard
        when={hasUnsavedChanges}
        navigate={(path) => history.push(path)}
        onSave={onSave}
      />
      <div className={styles.stepsWrapper}>
        <BreadcrumbNav
          items={getBreadcrumbItems(isDraft)}
          className={styles.breadcrumbs}
        />
        <Steps
          className={styles.step}
          steps={steps.map((s, index) => {
            let disabled = false;
            if (index > draftStep) {
              if (index === 1) {
                disabled = step1Invalid;
              } else if (index === 2) {
                disabled = step2Invalid;
              }
            }
            return ({ ...s, disabled });
          })}
          activeIndex={activeIndex}
          onStepClick={(step, index) => {
            saveTemplate({
              ...template,
              draftStep: index,
            }, () => setActiveIndex(index), onSaveError);
          }}
        />
      </div>
      <DndProvider backend={HTML5Backend}>
        { loading ? <Card elevation={Elevation.TWO}><Spinner /></Card> : null }
        { error ? (
          <Card elevation={Elevation.TWO}>
            <NonIdealState title="Ошибка" description={error.message} icon="error" />
          </Card>
        ) : null}
        { !loading && !error && activeIndex === 0 ? (
          <GeneralStep
            template={template}
            updateTemplate={updateTemplate}
            types={types ? types.data : []}
            onNext={() => {
              saveTemplate({
                ...template,
                draft: true,
                draftStep: 1,
              }, () => setActiveIndex(1), onSaveError);
            }}
          />
        ) : null}
        { !loading && !error && activeIndex === 1 ? (
          <EquipmentStep
            onSave={onSave}
            onNext={() => saveTemplate({
              ...template,
              draft: true,
              draftStep: 2,
            }, () => setActiveIndex(2), onSaveError)}
          />
        ) : null}
        { !loading && !error && activeIndex === 2 ? (
          <LayoutStep
            template={template}
            onSave={onSave}
            onFinish={() => saveTemplate({
              ...template,
              draftStep: 0,
              draft: false,
            }, () => {
              const message = 'Шаблон сохранен';
              history.push(`/templates/${template.id}`);
              toaster.show({
                intent: Intent.SUCCESS,
                message,
                icon: 'tick',
              });
            }, onSaveError)}
          />
        ) : null}
      </DndProvider>
    </Page>
  );
};

PlanogramTmplWizardScreen.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    typeId: PropTypes.number,
    equipments: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })),
    draft: PropTypes.bool,
    draftStep: PropTypes.number,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  hasUnsavedChanges: PropTypes.bool.isRequired,
  setNewTemplate: PropTypes.func.isRequired,
  fetchTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
};

PlanogramTmplWizardScreen.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  template: state.tmplWizard.template.data,
  loading: state.tmplWizard.template.fetching || state.tmplWizard.template.saving,
  error: state.tmplWizard.template.error,
  hasUnsavedChanges: selectHasUnsavedChanges(state),
});

const mapDispatchToProps = (dispatch) => ({
  setNewTemplate: () => dispatch(newTemplateAction()),
  fetchTemplate: (id) => dispatch(fetchTemplateAction(id)),
  updateTemplate: (update) => dispatch(updateTemplateAction(update)),
  saveTemplate: (template, onSuccess, onError) => dispatch(saveTemplateAction(
    template,
    onSuccess,
    onError,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramTmplWizardScreen);
