import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Popover,
} from '@blueprintjs/core';
import ChromePicker from 'react-color/lib/Chrome';
import styles from './index.module.scss';

const ColorPicker = ({ value, onChange, width }) => (
  <Popover>
    <ButtonGroup>
      <Button
        className={styles.button}
        style={{ width: width ? `${width - 30}px` : 'auto' }}
        icon={<div className={styles.color} style={{ backgroundColor: value }} />}
        text={value ? value.toUpperCase() : ''}
        rightIcon="caret-down"
      />
      <Button
        icon="cross"
        className={styles.clearButton}
        onClick={(e) => {
          onChange(null);
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      />
    </ButtonGroup>
    <ChromePicker
      color={value || '#fff'}
      onChange={(val) => onChange(val.hex)}
      disableAlpha
    />
  </Popover>
);

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
};

ColorPicker.defaultProps = {
  value: undefined,
  width: undefined,
};

export default ColorPicker;
