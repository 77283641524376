import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  Button,
  Intent,
  Tag,
} from '@blueprintjs/core';
import { useDrop } from 'react-dnd';
import _ from 'lodash';
import ItemTypes from './ItemTypes';
import {
  bindLayoutAction,
  unbindLayoutAction,
} from '../../../state/actionCreators/tmplWizard';
import { intRange } from '../../../utils/numbers';
import { getLayoutTree, getNoLayouts } from '../../../state/reducers/tmplWizard/utils';
import styles from './Equipment.module.scss';


const collectCell = (monitor) => ({
  isOver: !!monitor.isOver(),
});

const collect = (monitor) => ({
  isOver: !!monitor.isOver({ shallow: true }),
});

const Cell = ({
  index,
  locationId,
  equipment,
  bindLayout,
  isSelected,
  groupType,
}) => {
  const onDropCell = useCallback((dropItem) => {
    bindLayout({
      tmplEquipRefId: equipment.tmplEquipRefId,
      locationId,
      cells: [index],
      group: dropItem.nodeData,
      parents: dropItem.nodeData.parents,
    });
  }, [bindLayout, index, locationId, equipment.tmplEquipRefId]);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TREE_ITEM,
    drop: onDropCell,
    collect: collectCell,
  });

  const classes = [styles.cell, 'selection-target'];
  if (isOver) {
    classes.push(styles.over);
  }
  if (isSelected) {
    classes.push(styles.selected);
  }
  if (groupType === 'dummy') {
    classes.push(styles.dummy);
  }
  const className = classNames(...classes);

  return (
    <div
      ref={drop}
      className={className}
      data-location-id={locationId}
      data-tmpl-equip-ref-id={equipment.tmplEquipRefId}
      data-cell={index}
    >
      {index}
    </div>
  );
};

Cell.propTypes = {
  index: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
  }).isRequired,
  bindLayout: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  groupType: PropTypes.oneOf(['product', 'dummy']).isRequired,
};

const NoLayout = ({
  layout,
  bindLayout,
  equipment,
  selectedCells,
}) => (
  <div
    className={styles.noLayout}
    style={{
      flexGrow: layout.cells.length,
      flexShrink: layout.cells.length,
      flexBasis: `${20 * layout.cells.length}px`,
    }}
  >
    {layout.cells.map((i) => (
      <Cell
        key={i}
        index={i}
        locationId={layout.locationId}
        equipment={equipment}
        bindLayout={bindLayout}
        isSelected={selectedCells.filter((c) => c.cell === i).length === 1}
        groupType={layout.groupType}
      />
    ))}
  </div>
);
NoLayout.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    groupType: PropTypes.oneOf(['product', 'dummy']),
    cells: PropTypes.arrayOf(PropTypes.number),
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  bindLayout: PropTypes.func.isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
  }).isRequired,
  selectedCells: PropTypes.arrayOf(PropTypes.shape({
    cell: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    locationId: PropTypes.number,
  })).isRequired,
};

const DropableNoLayout = ({
  layout,
  bindLayout,
  equipment,
  selectedCells,
}) => {
  const onDrop = useCallback((dropItem, monitor) => {
    const didDrop = monitor.didDrop();
    if (didDrop) {
      // dropped to child
      return;
    }
    bindLayout({
      tmplEquipRefId: equipment.tmplEquipRefId,
      locationId: layout.locationId,
      cells: layout.cells,
      group: dropItem.nodeData,
      parents: dropItem.nodeData.parents,
    });
  }, [bindLayout, layout, equipment.tmplEquipRefId]);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TREE_ITEM,
    drop: onDrop,
    collect,
  });

  const className = isOver ? classNames(
    styles.dropableNoLayout,
    styles.over,
  ) : styles.dropableNoLayout;

  return (
    <div
      ref={drop}
      className={className}
      style={{
        flexGrow: layout.cells.length,
        flexShrink: layout.cells.length,
        flexBasis: `${20 * layout.cells.length}px`,
      }}
    >
      {layout.cells.map((i) => (
        <Cell
          key={i}
          index={i}
          locationId={layout.locationId}
          equipment={equipment}
          bindLayout={bindLayout}
          isSelected={selectedCells.filter((c) => c.cell === i).length === 1}
          groupType={layout.groupType}
        />
      ))}
    </div>
  );
};
DropableNoLayout.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    groupType: PropTypes.oneOf(['product', 'dummy']),
    cells: PropTypes.arrayOf(PropTypes.number),
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  bindLayout: PropTypes.func.isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
  }).isRequired,
  selectedCells: PropTypes.arrayOf(PropTypes.shape({
    cell: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    locationId: PropTypes.number,
  })).isRequired,
};

const Layout = ({
  layout,
  bindLayout,
  unbindLayout,
  equipment,
  selectedCells,
}) => {
  const onDrop = useCallback((dropItem, monitor) => {
    const didDrop = monitor.didDrop();
    if (didDrop) {
      // dropped to child
      return;
    }
    bindLayout({
      tmplEquipRefId: layout.tmplEquipRefId,
      locationId: layout.locationId,
      cells: layout.cells,
      group: dropItem.nodeData,
      parents: dropItem.nodeData.parents,
    });
  }, [bindLayout, layout]);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TREE_ITEM,
    drop: onDrop,
    collect,
  });

  const className = isOver ? classNames(styles.layout, styles.over) : styles.layout;
  const layoutTree = layout.children || [];
  const emptyLayouts = getNoLayouts(
    layout.locationId,
    layoutTree,
    layout.cells,
    layout.groupType,
  );
  const childItems = _.sortBy([...layoutTree, ...emptyLayouts], (i) => i.cells[0]);

  return (
    <div
      ref={drop}
      className={className}
      style={{
        flexGrow: layout.cells.length,
        flexShrink: layout.cells.length,
        flexBasis: `${20 * layout.cells.length}px`,
        backgroundColor: layout.groupColor || undefined,
      }}
    >
      <div className={styles.layoutTitle}>
        <div />
        <div title={layout.groupName} className={styles.titleWrapper}>
          {layout.groupName}
        </div>
        <Button
          className={styles.clearButton}
          title="Очистить"
          onClick={() => unbindLayout(layout)}
          icon="cross"
          intent={Intent.DANGER}
        />
      </div>
      <div title={layout.groupName} className={styles.layoutContent}>
        {childItems.map((item, index) => {
          /* eslint-disable react/no-array-index-key */
          if (item.groupId) {
            return (
              <Layout
                key={`${equipment.tmplEquipRefId}-${item.locationId}-${index}`}
                layout={item}
                bindLayout={bindLayout}
                unbindLayout={unbindLayout}
                equipment={equipment}
                selectedCells={selectedCells}
              />
            );
          }
          return (
            <NoLayout
              key={`no-${equipment.tmplEquipRefId}-${item.locationId}-${index}`}
              layout={item}
              equipment={equipment}
              selectedCells={selectedCells}
              bindLayout={bindLayout}
            />
          );
          /* eslint-enable react/no-array-index-key */
        })}
      </div>
    </div>
  );
};
Layout.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    groupType: PropTypes.oneOf(['product', 'dummy']),
    cells: PropTypes.arrayOf(PropTypes.number),
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  bindLayout: PropTypes.func.isRequired,
  unbindLayout: PropTypes.func.isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
  }).isRequired,
  selectedCells: PropTypes.arrayOf(PropTypes.shape({
    cell: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    locationId: PropTypes.number,
  })).isRequired,
};

// считаем высоту полки по пикселям flex в ie11
const getShelfHeight = (maxDepth) => {
  const minHeight = 42 + 22 * (maxDepth + 1);
  return minHeight;
};

const Shelf = ({
  location,
  equipment,
  layouts,
  selectedCells,
  bindLayout,
  unbindLayout,
}) => {
  const { layoutTree, maxDepth } = useMemo(() => getLayoutTree(layouts), [layouts]);
  const emptyLayouts = getNoLayouts(
    location.locationId,
    layoutTree,
    intRange(1, location.maxfacex),
    'product',
  );
  const childItems = _.sortBy([...layoutTree, ...emptyLayouts], (i) => i.cells[0]);
  const height = getShelfHeight(maxDepth);

  return (
    <div
      className={styles.shelf}
      style={{
        height: `${height}px`,
        minHeight: `${height}px`,
      }}
    >
      <div className={styles.priorityContainer}>
        <Tag
          className={styles.priorityTag}
          intent={Intent.PRIMARY}
          title={`Приоритет ${location.priority}`}
          minimal
        >
          {location.priority}
        </Tag>
      </div>
      { childItems.map((item, index) => {
        /* eslint-disable react/no-array-index-key */
        if (item.groupId) {
          return (
            <Layout
              key={`${item.tmplEquipRefId}-${item.locationId}-${index}`}
              layout={item}
              equipment={equipment}
              selectedCells={selectedCells}
              bindLayout={bindLayout}
              unbindLayout={unbindLayout}
            />
          );
        }
        return (
          <DropableNoLayout
            key={`no-${equipment.tmplEquipRefId}-${item.locationId}-${index}`}
            layout={item}
            equipment={equipment}
            selectedCells={selectedCells}
            bindLayout={bindLayout}
          />
        );
        /* eslint-enable react/no-array-index-key */
      }) }
    </div>
  );
};

Shelf.propTypes = {
  location: PropTypes.shape({
    locationId: PropTypes.number,
    maxfacex: PropTypes.number,
    priority: PropTypes.number,
  }).isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    height: PropTypes.number,
    shelfCount: PropTypes.number,
  }).isRequired,
  layouts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    cells: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  selectedCells: PropTypes.arrayOf(PropTypes.shape({
    cell: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    locationId: PropTypes.number,
  })).isRequired,
  bindLayout: PropTypes.func.isRequired,
  unbindLayout: PropTypes.func.isRequired,
};


const Equipment = ({
  index,
  equipment,
  layouts,
  selectedCells,
  bindLayout,
  unbindLayout,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TREE_ITEM,
    drop: (dropItem, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        // dropped to child
        return;
      }
      equipment.children.forEach((l) => {
        bindLayout({
          tmplEquipRefId: equipment.tmplEquipRefId,
          locationId: l.locationId,
          cells: intRange(1, l.maxfacex),
          group: dropItem.nodeData,
          parents: dropItem.nodeData.parents,
        });
      });
    },
    collect,
  });
  const hasLayouts = layouts.length;

  const className = isOver ? classNames(styles.equipment, styles.over) : styles.equipment;

  return (
    <div className={styles.equipmentWrapper}>
      <Tag
        round
        minimal
        intent={Intent.PRIMARY}
        className={styles.priority}
      >
        Приоритет
        {' '}
        {index + 1}
      </Tag>
      <div
        ref={drop}
        className={className}
        style={{
          minWidth: `${0.3 * equipment.width}px`,
          width: `${0.3 * equipment.width}px`,
        }}
      >
        <div key="title" className={styles.equipmentTitle}>
          <div />
          <div title={equipment.title} className={styles.titleWrapper}>
            {equipment.title}
          </div>
          { hasLayouts ? (
            <Button
              className={styles.clearButton}
              title="Очистить"
              onClick={() => {
                const equipLayouts = layouts.filter(
                  (layout) => layout.tmplEquipRefId === equipment.tmplEquipRefId,
                );
                equipLayouts.forEach((l) => unbindLayout(l));
              }}
              icon="cross"
              intent={Intent.DANGER}
            />
          ) : <div />}
        </div>
        <div className={styles.equipmentBody}>
          {equipment.children.map((l) => (
            <Shelf
              key={l.locationId}
              location={l}
              equipment={{
                id: equipment.id,
                tmplEquipRefId: equipment.tmplEquipRefId,
                height: equipment.height,
                shelfCount: equipment.children.length,
              }}
              layouts={layouts.filter((layout) => layout.locationId === l.locationId)}
              selectedCells={selectedCells.filter((c) => c.locationId === l.locationId)}
              bindLayout={bindLayout}
              unbindLayout={unbindLayout}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

Equipment.propTypes = {
  index: PropTypes.number.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    locationId: PropTypes.number,
    groupId: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    groupName: PropTypes.string,
    groupColor: PropTypes.string,
    groupIsLeaf: PropTypes.bool,
    groupLevel: PropTypes.number,
    groupParentId: PropTypes.number,
    cells: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      locationId: PropTypes.number,
      maxfacex: PropTypes.number,
    })),
  }).isRequired,
  selectedCells: PropTypes.arrayOf(PropTypes.shape({
    cell: PropTypes.number,
    tmplEquipRefId: PropTypes.number,
    locationId: PropTypes.number,
  })).isRequired,
  bindLayout: PropTypes.func.isRequired,
  unbindLayout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  bindLayout: ({
    tmplEquipRefId,
    locationId,
    cells,
    group,
    parents,
  }) => dispatch(bindLayoutAction({
    tmplEquipRefId,
    locationId,
    cells,
    group,
    parents,
  })),
  unbindLayout: (layout) => dispatch(unbindLayoutAction(layout)),
});

export default connect(null, mapDispatchToProps)(Equipment);
