import LoginScreen from '../containers/LoginScreen';
import LogoutScreen from '../containers/LogoutScreen';
import PlanogramTmplListScreen from '../containers/PlanogramTmplListScreen';
import PlanogramTmplViewScreen from '../containers/PlanogramTmplViewScreen';
import PlanogramTmplWizardScreen from '../containers/PlanogramTmplWizardScreen';
import PlanogramScreen from '../containers/PlanogramScreen';
import EquipmentListScreen from '../containers/EquipmentListScreen';
import EquipmentViewScreen from '../containers/EquipmentViewScreen';
import EquipmentEditScreen from '../containers/EquipmentEditScreen';
import WarehouseListScreen from '../containers/WarehouseListScreen';
import WarehouseViewScreen from '../containers/WarehouseViewScreen';
import UserListScreen from '../containers/UserListScreen';
import LayoutTreeScreen from '../containers/LayoutTreeScreen';
import AddTmplWsRefScreen from '../containers/AddTmplWsRefScreen';


export const canVisit = (user, route) => {
  if (user && route.requiredRight) {
    const can = user.rights.indexOf(route.requiredRight) !== -1;
    return can;
  }
  return true;
};

export default [
  {
    // Вход
    path: '/login',
    component: LoginScreen,
  },
  {
    // Выход
    path: '/logout',
    name: 'Выход',
    icon: 'log-out',
    component: LogoutScreen,
  },
  {
    path: '/',
    exact: true,
    redirect: '/templates',
  },
  {
    path: '/templates',
    name: 'Шаблоны',
    icon: 'helper-management',
    exact: true,
    component: PlanogramTmplListScreen,
  },
  {
    path: '/templates/:id',
    exact: true,
    component: PlanogramTmplViewScreen,
  },
  {
    path: '/templates/:id/refs/add',
    exact: true,
    component: AddTmplWsRefScreen,
    requiredRight: 'planogram_edit',
  },
  {
    path: '/templates/:id/edit',
    exact: true,
    component: PlanogramTmplWizardScreen,
    requiredRight: 'planogram_edit',
  },
  {
    path: '/warehouses/:wsId/planogram/:tmplWsId/preview',
    exact: true,
    component: PlanogramScreen,
  },
  {
    path: '/warehouses/:wsId/planogram',
    exact: true,
    component: PlanogramScreen,
  },
  {
    path: '/equipments',
    name: 'Оборудование',
    icon: 'cube',
    exact: true,
    component: EquipmentListScreen,
  },
  {
    path: '/equipments/:id',
    exact: true,
    component: EquipmentViewScreen,
  },
  {
    path: '/equipments/:id/edit',
    exact: true,
    component: EquipmentEditScreen,
    requiredRight: 'planogram_edit',
  },
  {
    path: '/equipments/:id/copy',
    exact: true,
    component: EquipmentEditScreen,
    requiredRight: 'planogram_edit',
  },
  {
    path: '/warehouses',
    name: 'Магазины',
    icon: 'shop',
    exact: true,
    component: WarehouseListScreen,
  },
  {
    path: '/warehouses/:id',
    exact: true,
    component: WarehouseViewScreen,
  },
  {
    path: '/users',
    name: 'Пользователи',
    icon: 'person',
    exact: true,
    component: UserListScreen,
    requiredRight: 'user_edit',
  },
  {
    path: '/layout-tree',
    name: 'Товарные группы',
    icon: 'diagram-tree',
    exact: true,
    component: LayoutTreeScreen,
  },
];
