import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import _ from 'lodash';
import ItemTypes from './ItemTypes';
import styles from './LayoutCard.module.scss';

const afterSelect = (
  selectedTargets,
  boxRect,
  scrollOffset,
  selectArea,
) => {
  if (!selectedTargets.length) {
    return;
  }
  const bounds = {
    left: 9999999,
    right: 0,
    top: 9999999,
    bottom: 0,
    width: 0,
    height: 0,
  };
  const cells = selectedTargets.map((el) => {
    const rect = el.getBoundingClientRect();
    bounds.left = Math.min(rect.left, bounds.left);
    bounds.right = Math.max(rect.right, bounds.right);
    bounds.top = Math.min(rect.top, bounds.top);
    bounds.bottom = Math.max(rect.bottom, bounds.bottom);

    return {
      cell: Number(el.getAttribute('data-cell')),
      tmplEquipRefId: Number(el.getAttribute('data-tmpl-equip-ref-id')),
      locationId: Number(el.getAttribute('data-location-id')),
    };
  });
  const PADDING = 2;
  bounds.width = bounds.right - bounds.left + PADDING + PADDING;
  bounds.height = bounds.bottom - bounds.top + PADDING + PADDING;
  bounds.left -= boxRect.left - scrollOffset.scrollLeft + PADDING;
  bounds.top -= boxRect.top - scrollOffset.scrollTop + PADDING;
  delete bounds.right;
  delete bounds.bottom;
  const area = {
    cells,
    bounds,
  };
  selectArea(area);
};

const collect = (monitor) => ({
  isOver: !!monitor.isOver({ shallow: true }),
});

const SelectionArea = ({ area, bindLayout, clearSelection }) => {
  const onDrop = useCallback((dropItem, monitor) => {
    const didDrop = monitor.didDrop();
    if (didDrop) {
      // dropped to child
      return;
    }
    const grouped = _.groupBy(area.cells, (c) => `${c.tmplEquipRefId}_${c.locationId}`);
    _.mapValues(grouped, (g) => {
      const cells = _.sortBy(_.map(g, (c) => c.cell));
      bindLayout({
        tmplEquipRefId: g[0].tmplEquipRefId,
        locationId: g[0].locationId,
        cells,
        group: dropItem.nodeData,
        parents: dropItem.nodeData.parents,
      });
    });
    clearSelection();
  }, [bindLayout, clearSelection, area.cells]);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TREE_ITEM,
    drop: onDrop,
    collect,
  });

  return (
    <div
      ref={drop}
      className={isOver ? classNames(styles.selectionArea, styles.over) : styles.selectionArea}
      style={{
        left: area.bounds.left,
        top: area.bounds.top,
        width: area.bounds.width,
        height: area.bounds.height,
      }}
    />
  );
};

SelectionArea.propTypes = {
  area: PropTypes.shape({
    cells: PropTypes.arrayOf(PropTypes.shape({
      cell: PropTypes.number,
      tmplEquipRefId: PropTypes.number,
      locationId: PropTypes.number,
    })),
    bounds: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  bindLayout: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
};

export { SelectionArea, afterSelect };
