import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Elevation,
  Spinner,
  NonIdealState,
  Intent,
} from '@blueprintjs/core';
import Page from '../../widgets/Page';
import BreadcrumbNav from '../../widgets/BreadcrumbNav';
import { getWarehouse } from '../../api';
import { useRequest } from '../../api/useRequest';
import TemplateTable from './TemplateTable';
import styles from './index.module.scss';

const getBreadcrumbItems = (warehouse) => [
  { text: 'Магазины', href: '/warehouses' },
  { text: warehouse && warehouse.name },
];

const WarehouseViewScreen = () => {
  const [{
    fetching,
    data: warehouse,
    error,
  }, fetch] = useRequest(getWarehouse);
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => { fetch(id); }, [fetch, id]);

  if (fetching || (!warehouse && !error)) {
    return <Spinner />;
  }
  if (error) {
    return <NonIdealState title="Ошибка" description={error.message} icon="error" />;
  }
  return (
    <Page flexible>
      <BreadcrumbNav items={getBreadcrumbItems(warehouse)} />
      <Card elevation={Elevation.TWO} className={styles.card}>
        <div className={styles.buttonRow}>
          <Button
            className={styles.button}
            intent={Intent.PRIMARY}
            onClick={() => history.push(`/warehouses/${id}/planogram`)}
          >
            Планограмма
          </Button>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Название:</div>
          <div className={styles.detailsValue}>{warehouse.name}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Код:</div>
          <div className={styles.detailsValue}>{warehouse.code}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Адрес:</div>
          <div className={styles.detailsValue}>{warehouse.address}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Филиал:</div>
          <div className={styles.detailsValue}>{warehouse.filialName}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>Статус:</div>
          <div className={styles.detailsValue}>{warehouse.status}</div>
        </div>
        <TemplateTable name={warehouse.name} />
      </Card>
    </Page>
  );
};

export default WarehouseViewScreen;
