import React from 'react';
import { useDragLayer } from 'react-dnd';
import ItemTypes from './ItemTypes';
import DragPreview from './DragPreview';
import styles from './DragLayer.module.scss';

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const renderItem = (itemType, item) => {
  switch (itemType) {
    case ItemTypes.EQUIP_ROW:
      return <DragPreview equipment={item.equipment} />;
    default:
      return null;
  }
};

const DragLayer = () => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  if (!isDragging) {
    return null;
  }
  return (
    <div className={styles.layer}>
      <div
        style={getItemStyles(initialOffset, currentOffset)}
      >
        {renderItem(itemType, item)}
      </div>
    </div>
  );
};

export default DragLayer;
