import _ from 'lodash';
import {
  getLayoutTreeChildren,
  getLayoutTree,
  prioritizeLayoutTree,
} from '../../api';
import { buildQueryParams } from '../../api/utils';
import * as ACTIONS from '../actions';

export const fetchLayoutTreeAction = (id, path, spinner) => async (dispatch) => {
  dispatch({ type: ACTIONS.LAYOUT_TREE_FETCH_START, payload: { id, path, spinner } });
  try {
    const nodes = await getLayoutTreeChildren(id);
    dispatch({
      type: ACTIONS.LAYOUT_TREE_FETCH_DONE,
      payload: {
        id,
        path,
        nodes,
      },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.LAYOUT_TREE_FETCH_ERROR, error });
  }
};

export const searchLayoutTreeAction = (search) => async (dispatch) => {
  const path = [];
  dispatch({ type: ACTIONS.LAYOUT_TREE_FETCH_START, payload: { path } });
  try {
    const params = buildQueryParams({ filters: { nameLike: search } });
    const nodes = await getLayoutTree(params);
    dispatch({
      type: ACTIONS.LAYOUT_TREE_FETCH_DONE,
      payload: { nodes, path },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.LAYOUT_TREE_FETCH_ERROR, error });
  }
};

export const expandAction = (id, path) => ({
  type: ACTIONS.LAYOUT_TREE_EXPAND,
  payload: { id, path },
});

export const collapseAction = (id, path) => ({
  type: ACTIONS.LAYOUT_TREE_COLLAPSE,
  payload: { id, path },
});

export const updateNodeAction = (id, path, data) => ({
  type: ACTIONS.LAYOUT_TREE_UPDATE_NODE,
  payload: { id, path, data },
});

export const moveNodeAction = (id, path, oldIndex, newIndex) => ({
  type: ACTIONS.LAYOUT_TREE_MOVE_NODE,
  payload: {
    id,
    path,
    oldIndex,
    newIndex,
  },
});

export const moveNodeCompleteAction = (
  id,
  path,
  data,
  spinner,
  onError,
) => async (dispatch, getState) => {
  const { parentId } = data;
  const { nodes } = getState().layoutTree;
  const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
  nodePath.pop(); // путь до ноды
  nodePath.pop(); // путь до childNodes родителя
  const childNodes = nodePath.length ? nodes.getIn(nodePath) : nodes;
  const groupIds = childNodes.filter((x) => !!x.nodeData.num).map((x) => x.id);
  dispatch({
    type: ACTIONS.LAYOUT_TREE_PRIORITIZE_START,
    payload: {
      id,
      path,
      spinner,
      actionType: 'move',
    },
  });

  try {
    await prioritizeLayoutTree(parentId || null, groupIds);
    dispatch({
      type: ACTIONS.LAYOUT_TREE_PRIORITIZE_DONE,
      payload: {
        id,
        path,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.LAYOUT_TREE_PRIORITIZE_ERROR,
      payload: {
        id,
        path,
      },
      error,
    });
    if (onError) {
      onError(error);
    }
  }
};

export const pinAction = (id, path, data, spinner, onError) => async (dispatch, getState) => {
  const { parentId } = data;
  const { nodes } = getState().layoutTree;
  const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
  nodePath.pop(); // путь до ноды
  nodePath.pop(); // путь до childNodes родителя
  const childNodes = nodePath.length ? nodes.getIn(nodePath) : nodes;
  const groupIds = childNodes.filter((x) => !!x.nodeData.num).map((x) => x.id).asMutable();
  groupIds.push(id);
  dispatch({
    type: ACTIONS.LAYOUT_TREE_PRIORITIZE_START,
    payload: {
      id,
      path,
      spinner,
      actionType: 'pin',
    },
  });

  try {
    await prioritizeLayoutTree(parentId || null, groupIds);
    dispatch({
      type: ACTIONS.LAYOUT_TREE_PRIORITIZE_DONE,
      payload: {
        id,
        path,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.LAYOUT_TREE_PRIORITIZE_ERROR,
      payload: {
        id,
        path,
      },
      error,
    });
    if (onError) {
      onError(error);
    }
  }
};

export const unpinAction = (id, path, data, spinner, onError) => async (dispatch, getState) => {
  const { parentId } = data;
  const { nodes } = getState().layoutTree;
  const nodePath = _.flatMap(path, (x) => [x, 'childNodes']);
  nodePath.pop(); // путь до ноды
  nodePath.pop(); // путь до childNodes родителя
  const childNodes = nodePath.length ? nodes.getIn(nodePath) : nodes;
  const groupIds = childNodes.filter((x) => !!x.nodeData.num && x.id !== id).map((x) => x.id);
  dispatch({
    type: ACTIONS.LAYOUT_TREE_PRIORITIZE_START,
    payload: {
      id,
      path,
      spinner,
      actionType: 'unpin',
    },
  });

  try {
    await prioritizeLayoutTree(parentId || null, groupIds);
    dispatch({
      type: ACTIONS.LAYOUT_TREE_PRIORITIZE_DONE,
      payload: {
        id,
        path,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.LAYOUT_TREE_PRIORITIZE_ERROR,
      payload: {
        id,
        path,
      },
      error,
    });
    if (onError) {
      onError(error);
    }
  }
};
