import React from 'react';
import PropTypes from 'prop-types';
import styles from './Filters.module.css';
import SimpleSelect from '../SimpleSelect';

const ComboFilter = ({
  name,
  value,
  onChange,
  items,
  filterable,
  emptiable,
}) => (
  <div className={styles.filtersGroup}>
    <div className={styles.filtersLabel}>
      {name}
      {':'}
    </div>
    <SimpleSelect
      items={items}
      filterable={filterable}
      emptiable={emptiable}
      emptyText="Все"
      value={value}
      onChange={onChange}
    />
  </div>
);

ComboFilter.propTypes = {
  filterable: PropTypes.bool,
  emptiable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
    title: PropTypes.string,
  })).isRequired,
};

ComboFilter.defaultProps = {
  value: undefined,
  filterable: false,
  emptiable: false,
};

export default ComboFilter;
